export class Shipping3plS3pl {

  constructor(
    public jobLoadId: number,
    public customLoad: boolean,
    public craneId: number,
    public job: string,
    public customWeight: number,
    public load: string,
    public castLocation: string,
    public width: number,
    public length: number,
    public weight: number,
    public timeToSet: number,
    public craneMoveDelay: number,
    public deliveryDate: Date,
    public deliveryTime: Date | null,
    public panelCount: number,
    public craneName: string,
  ) {  }
}
