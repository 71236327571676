import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-spinner-loader',
  templateUrl: './spinner-loader.component.html',
  styleUrl: './spinner-loader.component.css'
})
export class SpinnerLoaderComponent implements OnInit, OnChanges{
  @Input() isLoading: boolean = false;

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {

  }
}
