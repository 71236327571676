export class Ship3plBOLShipInstructions {

  constructor(
    public jobBolId: number,
    public job: string,
    public address1: string,
    public city: string,
    public state: string,
    public zip: string,
    public country: string,
    public specialInstructions: string,
  ) {  }
}
