import { Inject, Injectable } from '@angular/core';
import { CraneCrew } from '../common-data/crane-crew.model';
import { Observable } from 'rxjs';
import { ErrorResponse } from '../error-handling/error-response.model';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const options = { params: new HttpParams(), HttpHeaders: new HttpHeaders({'Content-Type': 'application/json'}) };

@Injectable({
  providedIn: 'root'
})
export class AdminYardCraneCrewService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
    this.baseUrl = environment.backend.baseURL;
  }

  updateCraneCrew(craneCrew: CraneCrew): Observable<ErrorResponse> {
    try {
      return this.http.post<ErrorResponse>(`${this.baseUrl}/admin/cranecrews`, craneCrew, options);
    } catch (error) {
      console.log(error);
      return new Observable<any>();
    }
  }
}
