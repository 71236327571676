import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-alert-modal',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './alert-modal.component.html',
  styleUrl: './alert-modal.component.css'
})
export class AlertModalComponent {
  title?: string;
  closeBtnName?: string;
  list: string[] = [];
  okAction!: string;

  @Output() confirmAction = new EventEmitter<any>();

  constructor(public bsModalRef: BsModalRef) { }

  okClicked(): void {
    this.confirmAction.emit(this.okAction);
  }
}
