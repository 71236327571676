export class Ship3plBOLLoadNumberInfo {

  constructor(
    public jobLoadId: number,
    public crane: number,
    public job: string,
    public loadNum: string,
    public deliveryDate: Date,
    public loadDescriptor: string,
    public carrierName: string,
    public trailer: string,
    public trailerSize: number,
    public loadingType: string,
  ) {  }
}
