import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-date-selection-modal',
  standalone: true,
  imports: [CommonModule, FormsModule, BsDatepickerModule],
  templateUrl: './date-selection-modal.component.html',
  styleUrl: './date-selection-modal.component.css'
})
export class DateSelectionModalComponent {
  dateMessage!: number;
  currentShipDate!: Date;
  minDate: Date = new Date();
;

  @Output() dateSelected = new EventEmitter<any>();

  isInitialized: boolean = false;
  newShipDate!: Date;
  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
    this.newShipDate = this.currentShipDate;
    this.isInitialized = true;
  }

  okClicked(): void {
    this.dateSelected.emit(this.currentShipDate);
  }
}
