<!-- Begin Custom Items List -->
<div class="row mt-2">
  <h3>CUSTOM ITEM OPTIONS</h3>
  <div class="col-lg-4">
    <table class="table table-bordered">
      <thead class="table-striped">
        <tr>
          <td colspan="12">
            <div class="container">
            <div class="row">
              <div class="col mt-2">
                  <button type="button" class="btn btn-success btn-sm" (click)="addNewRow()"
                    [disabled]="isAddButtonDisabled">
                    <i class="fa-solid fa-square-plus"></i>
                    Add Item
                  </button>
                </div>
                <div class="col col-lg-8">
                  <app-spinner-loader [isLoading]="isLoading"></app-spinner-loader>
                </div>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <th scope="col" (click)="setOrder('customItemDesc')">
            Description <span *ngIf="order === 'customItemDesc'" [hidden]="reverse"><i
                class="fa-solid fa-sort-down"></i></span>
            <span *ngIf="order === 'customItemDesc'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
            <span *ngIf="order !== 'customItemDesc'"><i class="fa-solid fa-sort"></i></span>
          </th>
          <th>Is Active?</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="isAddNewRow">
          <td>
            <div class="row">

              <div [ngClass]="isAddNewRow ? 'col col-lg-9' : 'col col-lg-10'">
                <div *ngIf="errorMessage && isAddNewRow" class="alert alert-danger p-1" role="alert">
                  <i class="fa-solid fa-triangle-exclamation"></i>
                  {{errorMessage}}
                </div>
                <div>
                  <input [ngClass]="isAddNewRow ? 'form-control' : 'border-0'" type="text"
                    [(ngModel)]="enteredItemDescription">
                </div>
              </div>
              <div class="col col-lg-1" *ngIf="isAddNewRow">
                <button style='background-color: transparent; border: none' (click)="saveNewCustomItem()">
                  <i class="fa-solid fa-check" style="color:#198754" title="Save Changes"></i>
                </button>
              </div>
              <div class="col col-lg-1" *ngIf="isAddNewRow">
                <button style='background-color: transparent; border: none' (click)="cancelNewCustomItem()">
                  <i class="fa-regular fa-circle-xmark" style="color:red" title="Cancel Changes"></i>
                </button>
              </div>
            </div>
          </td>
          <td class="col-lg-2">
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" [(ngModel)]="enteredItemIsActive">
              <label class="form-check-label" for="enteredItemIsActive">Active</label>
            </div>
          </td>
        </tr>
        <tr *ngFor="let custom of customItems | orderBy: order:reverse:true">
          <td>
            <div class="row">
              <div [ngClass]="custom.isEditable ? 'col col-lg-9' : 'col col-lg-10'">
                <div *ngIf="errorMessage && custom.isEditable" class="alert alert-danger p-1" role="alert">
                  <i class="fa-solid fa-triangle-exclamation"></i>
                  {{errorMessage}}
                </div>
                <div>
                  <input [ngClass]="custom.isEditable ? 'form-control' : 'border-0'" type="text"
                    [(ngModel)]="custom.customItemDesc" [value]="custom.customItemDesc" [readonly]="!custom.isEditable">
                </div>
              </div>
              <div class="col col-lg-1" *ngIf="!custom.isEditable">
                <button style='background-color: transparent; border: none' (click)="editCustomItem(custom)">
                  <i class="fa-solid fa-pencil" style="color:#198754" title="Edit"></i>
                </button>
              </div>
              <div class="col col-lg-1" *ngIf="custom.isEditable">
                <button style='background-color: transparent; border: none' (click)="saveCustomItem(custom)">
                  <i class="fa-solid fa-check" style="color:#198754" title="Save Changes"></i>
                </button>
              </div>
              <div class="col col-lg-1" *ngIf="custom.isEditable">
                <button style='background-color: transparent; border: none' (click)="cancelCustomItem(custom)">
                  <i class="fa-regular fa-circle-xmark" style="color:red" title="Cancel Changes"></i>
                </button>
              </div>
            </div>
          </td>
          <td class="col-lg-2">
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" [(ngModel)]="custom.isActive"
                (ngModelChange)="onChecked(custom, $event)">
              <label class="form-check-label" for="custom.CustomItemID">Active</label>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<!-- End Custom Items List -->
