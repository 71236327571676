import { BehaviorSubject, Observable, concatMap, map, of, tap } from 'rxjs';
import { AdminHolidayScheduleService } from './admin-holiday-schedule.service';
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HolidaySchedule } from './admin-holiday-schedule.model';
import { ToastService, toastTypes } from '../toast/toast.service';
import { ErrorResponse } from '../error-handling/error-response.model';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Company } from '../controls/company-drop-down/company-model';


@Component({
  selector: 'app-admin-holiday-schedule',
  templateUrl: './admin-holiday-schedule.component.html',
  styleUrl: './admin-holiday-schedule.component.css'
})

export class AdminHolidayScheduleComponent implements OnInit, OnDestroy {
  public form!: FormGroup;
  adminHolidayScheduleService: AdminHolidayScheduleService = inject(AdminHolidayScheduleService);
  schedules$!: Observable<any>;
  companies$!: Observable<any>;
  companies!: Company[];
  modelSchedule = new HolidaySchedule('', 0, '', undefined);

  constructor(private router: Router, private formBuilder: FormBuilder, private toast: ToastService) { }

  ngOnInit() {
    try {
      this.loadAdminHolidaySchedules();
    } catch (error) {
      console.log(error)
    }
  }

  ngOnDestroy() {

  }
  onSubmit(): void {
      this.addEditHolidaySchedule();
  }

  private loadAdminHolidaySchedules(){

      this.form = this.formBuilder.group({
        holidayDate: '',
        holidayPlant: '',
        holidayDescription: ''
      });

      this.adminHolidayScheduleService.getCompanies().subscribe({
        next: (result) => {
          this.companies = result;

          this.schedules$ = this.adminHolidayScheduleService.getHolidaySchedules()
          .pipe(
            map((schedules: any) => {
              this.companies$ = schedules[0].companies;
              this.companies = schedules[0].companies;
              return schedules;
            })
          );

        },
        error: (error) => {

          console.error(error);
        }
      });
      // this.adminHolidayScheduleService.getCompanies().subscribe((result) => {

      //   this.companies.push<Company>(result);

      //   result.forEach((company) => {
      //     this.companies.push(company);
      //   })


      //   this.schedules$ = this.adminHolidayScheduleService.getHolidaySchedules()
      //     .pipe(
      //       map((schedules: any) => {
      //         this.companies$ = schedules[0].companies;
      //         this.companies = schedules[0].companies;
      //         return schedules;
      //       })
      //     );

      // }, error => console.error(error));

  }
  private addEditHolidaySchedule() {
    this.adminHolidayScheduleService.addUpdateHolidaySchedule(this.modelSchedule).subscribe((response: ErrorResponse) => {
      this.toast.initiate({
        title: response.name,
        content: response.message,
        type: response.statusCode == 200 || response.statusCode == 201 ? toastTypes.success : toastTypes.error,
      });

      this.refreshComponent();

    }, (error: HttpErrorResponse) => {
      console.log(error)
      this.toast.initiate({
        title: error.error.Name,
        content: error.error.message,
        type: toastTypes.error,
      });
    });
  }

  public async deleteHoliday(scheduleHolidayId: number) {

    this.adminHolidayScheduleService.deleteHolidaySchedule(scheduleHolidayId).subscribe((response: ErrorResponse) => {
      this.toast.initiate({
        title: response.name,
        content: response.message,
        type: response.statusCode == 200 || response.statusCode == 201 ? toastTypes.success : toastTypes.error,
      });

      this.refreshComponent();

    }, (error: HttpErrorResponse) => {
      this.toast.initiate({
        title: error.error.Name,
        content: error.error.message,
        type: toastTypes.error,
      });
    });

  }

  public editHolidaySchedule(holidaySchedule: HolidaySchedule) {
    this.modelSchedule = holidaySchedule;
  }

  private refreshComponent(){
    this.loadAdminHolidaySchedules();
  }

}
