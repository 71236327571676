import { Component, Input, OnChanges, OnInit, SimpleChanges, inject } from '@angular/core';
import { ShippingDailySummary } from './shipping-daily-summary.model';
import { ShippingDailySummaryService } from './shipping-daily-summary.service';

@Component({
  selector: 'app-shipping-daily-summary',
  templateUrl: './shipping-daily-summary.component.html',
  styleUrl: './shipping-daily-summary.component.css'
})
export class ShippingDailySummaryComponent implements OnInit, OnChanges {
  @Input() CompanyIds: number[] = [];
  @Input() ExpectedPickupDate!: string | null;
  shippingDailySummaryService: ShippingDailySummaryService = inject(ShippingDailySummaryService);
  dailySummaries: ShippingDailySummary[] = [];

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {

    if (this.ExpectedPickupDate != null) {
      if (this.CompanyIds.length > 0 && this.ExpectedPickupDate!.length > 0) {
        try {
          let expectedPickupDate: string = new Date(this.ExpectedPickupDate!).toLocaleString('en-US', {
            month: '2-digit', day: '2-digit', year: 'numeric'
          });

          this.shippingDailySummaryService.getDailySummary(this.CompanyIds, expectedPickupDate).subscribe(result => {
            this.dailySummaries = result;
          }, error => console.error(error));
        } catch (error) {
          console.log(error)
        }
      }
    }
  }
}
