<div class="mmodal-dialog-center">
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{ title }}</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="modal-body">
      <ul *ngIf="list.length">
        <li *ngFor="let item of list">{{ item }}</li>
      </ul>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" data-bs-dismiss="modal" (click)="cancelClicked(); bsModalRef.hide();">Cancel</button>
    <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="okClicked(); bsModalRef.hide();">OK</button>
  </div>
</div>

