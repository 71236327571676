import { ModalOptions } from 'ngx-bootstrap/modal';
import { Component, OnInit, OnDestroy, Input, ViewChild, HostListener } from '@angular/core';
import SignaturePad from 'signature_pad';

@Component({
  selector: 'app-write-signature-pad',
  templateUrl: './write-signature-pad.component.html',
  styleUrl: './write-signature-pad.component.css'
})

export class WriteSignaturePadComponent implements OnInit, OnDestroy{
  title = '';
  signPad: any;
  @ViewChild('signPadCanvas', {static: false}) signaturePadElement:any;
  @HostListener('window:resize', ['$event'])
  @Input() SignImage: any;

  constructor() {}

  ngOnDestroy(): void {

  }
  ngOnInit(): void {

  }

    // drawComplete(event: MouseEvent | Touch) {
  //   // will be notified of szimek/signature_pad's onEnd event
  //   console.log('Completed drawing', event);
  //   console.log(this.signaturePad?.toDataURL());
  // }

  // drawStart(event: MouseEvent | Touch) {
  //   // will be notified of szimek/signature_pad's onBegin event
  //   console.log('Start drawing', event);
  // }

onResize() {
  //handle the resizing of signature pad canvas on resizing screen
  this.signPad.queryPad()._canvas.width = this.signPad?.nativeElement.offsetWidth;
}

handleSignatureCanvasSize(canvas: HTMLCanvasElement) {
  //handle the resizing of signature pad canvas on resizing screen
  const tempWidth = String(canvas?.parentElement?.clientWidth);
  const widthCanvas = tempWidth.concat("px");
  canvas?.setAttribute("width", widthCanvas);

  this.signPad = new SignaturePad(canvas!);
}

  /*It's work in devices*/
  startSignPadDrawing(event: Event) {
    console.log(event);
  }
  /*It's work in devices*/
  movedFinger(event: Event) {
  }
  /*Undo last step from the signature*/
  undoSign() {
    const data = this.signPad.toData();
    if (data) {
      data.pop(); // remove the last step
      this.signPad.fromData(data);
    }
  }
  /*Clean whole the signature*/
  clearSignPad() {
    this.signPad.clear();
    const base64ImageData = this.signPad.toDataURL();
    this.SignImage = null;
  }
  /*Here you can save the signature as a Image*/
  saveSignPad() {
    const base64ImageData = this.signPad.toDataURL();
    // Check if the signature pad is empty and set to null.
    if (this.signPad.isEmpty()) {
      this.SignImage = null;
      return;
    }

    this.SignImage = base64ImageData.replace(/^data:image\/(png|jpg);base64,/, "");
  }
}
