<!-- Begin Crane Crews List -->
<div class="row mt-2">
  <h3>YARD CRANE CREW TITLES</h3>
  <div class="col-lg-3">
    <table class="table table-bordered">
      <thead class="table-striped">
        <tr>
          <td colspan="12">
            <div class="container">
            <div class="row">
              <div class="col col-lg-6 mt-2">
                  <button type="button" class="btn btn-success btn-sm" (click)="addNewRow()"
                    [disabled]="isAddButtonDisabled">
                    <i class="fa-solid fa-square-plus"></i>
                    Add Crew
                  </button>
                </div>
                <div class="col col-lg-8">
                  <app-spinner-loader [isLoading]="isLoading"></app-spinner-loader>
                </div>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <th scope="col" (click)="setOrder('description')">
            Description <span *ngIf="order === 'description'" [hidden]="reverse"><i
                class="fa-solid fa-sort-down"></i></span>
            <span *ngIf="order === 'description'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
            <span *ngIf="order !== 'description'"><i class="fa-solid fa-sort"></i></span>
          </th>
          <th>Is Active?</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="isAddNewRow">
          <td>
            <div class="row">
              <div [ngClass]="isAddNewRow ? 'col col-lg-9' : 'col col-lg-4'">
                <div *ngIf="errorMessage && isAddNewRow" class="alert alert-danger p-1" role="alert">
                  <i class="fa-solid fa-triangle-exclamation"></i>
                  {{errorMessage}}
                </div>
                <div>
                  <input [ngClass]="isAddNewRow ? 'form-control' : 'border-0'" type="text"
                    [(ngModel)]="enteredCraneCrewDescription">
                </div>
              </div>
              <div class="col col-lg-1" *ngIf="isAddNewRow">
                <button style='background-color: transparent; border: none' (click)="saveNewCraneCrew()">
                  <i class="fa-solid fa-check" style="color:#198754" title="Save Changes"></i>
                </button>
              </div>
              <div class="col col-lg-1" *ngIf="isAddNewRow">
                <button style='background-color: transparent; border: none' (click)="cancelNewCraneCrew()">
                  <i class="fa-regular fa-circle-xmark" style="color:red" title="Cancel Changes"></i>
                </button>
              </div>
            </div>
          </td>
          <td class="col-lg-2">
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" [(ngModel)]="enteredCraneCrewIsActive">
              <label class="form-check-label" for="enteredCraneCrewIsActive">Active</label>
            </div>
          </td>
        </tr>
        <tr *ngFor="let craneCrew of craneCrews | orderBy: order:reverse:true">
          <td>
            <div class="row">
              <div [ngClass]="craneCrew.isEditable ? 'col col-lg-9' : 'col col-lg-9'">
                <div *ngIf="errorMessage && craneCrew.isEditable" class="alert alert-danger p-1" role="alert">
                  <i class="fa-solid fa-triangle-exclamation"></i>
                  {{errorMessage}}
                </div>
                <div class="col col-lg-9">
                  <input [ngClass]="craneCrew.isEditable ? 'form-control' : 'border-0'" type="text"
                    [(ngModel)]="craneCrew.description" [value]="craneCrew.description" [readonly]="!craneCrew.isEditable">
                </div>
              </div>
              <div class="col col-lg-1" *ngIf="!craneCrew.isEditable">
                <button style='background-color: transparent; border: none' (click)="editCraneCrew(craneCrew)">
                  <i class="fa-solid fa-pencil" style="color:#198754" title="Edit"></i>
                </button>
              </div>
              <div class="col col-lg-1" *ngIf="craneCrew.isEditable">
                <button style='background-color: transparent; border: none' (click)="saveCraneCrew(craneCrew)">
                  <i class="fa-solid fa-check" style="color:#198754" title="Save Changes"></i>
                </button>
              </div>
              <div class="col col-lg-1" *ngIf="craneCrew.isEditable">
                <button style='background-color: transparent; border: none' (click)="cancelCraneCrew(craneCrew)">
                  <i class="fa-regular fa-circle-xmark" style="color:red" title="Cancel Changes"></i>
                </button>
              </div>
            </div>
          </td>
          <td class="col-lg-2">
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" [(ngModel)]="craneCrew.isActive"
                (ngModelChange)="onChecked(craneCrew, $event)">
              <label class="form-check-label" for="craneCrew.isActive">Active</label>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<!-- End Crane Crews List -->

