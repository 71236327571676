import { first, forEach } from 'lodash';
import { FieldPlanDetailSplitLoadModalComponent } from './field-plan-detail-split-load-modal/field-plan-detail-split-load-modal.component';
import { Company } from './../../controls/company-drop-down/company-model';
import { AfterViewInit, ChangeDetectorRef, Component, ContentChild, ElementRef, EventEmitter, Inject, Input, LOCALE_ID, OnInit, Output, QueryList, TemplateRef, ViewChild, ViewChildren, ViewContainerRef, inject } from '@angular/core';
import { FieldPlanSummaryDetail, FieldPlanSummaryInfo, ProjectDateExceptions } from '../fieldPlanSearchResult.model';
import { FieldPlanDataService } from '../field-plan.data.service';
import { ErrorResponse } from '../../error-handling/error-response.model';
import { ToastService, toastTypes } from '../../toast/toast.service';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { formatDate } from 'ngx-bootstrap/chronos';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { FieldPlanMinToSetUpdate } from './field-plan-mintoset-update.model';
import { AlertModalComponent } from '../../controls/alert-modal/alert-modal.component';
import { FieldPlanDetailSortable, SortedLoad, SortedPanel } from '../field-plan-detail-sortable.model';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Observable, asapScheduler } from 'rxjs';
import { Subscription } from 'rxjs/internal/Subscription';
import { filter, fromEvent, take } from 'rxjs';
import {
  CdkDragDrop,
  CdkDrag,
  CdkDropList,
  moveItemInArray,
  transferArrayItem,
  CdkDragEnter,
  CdkDragExit,
  CdkDragStart,
  CdkDragEnd,
  DragRef
} from '@angular/cdk/drag-drop';
import { ConfirmModalComponent } from '../../controls/confirm-modal/confirm-modal.component';
import { CraneMoveModalComponent } from '../crane-move-modal/crane-move-modal.component';
import { FieldPlanCraneMove } from './field-plan-move-crane.model';
import { DateSelectionModalComponent } from '../date-selection-modal/date-selection-modal.component';
import { AuthService } from '../../authentication/auth.service';
import { DatePipe } from '@angular/common';
import { SpinnerLoaderComponent } from 'src/app/controls/spinner-loader/spinner-loader.component';
import { FieldPlanLoadingModalComponent } from './field-plan-loading-modal/field-plan-loading-modal.component';
import { AdminHolidayScheduleService } from '../../admin-holiday-schedule/admin-holiday-schedule.service';
import { HolidaySchedule } from '../../admin-holiday-schedule/admin-holiday-schedule.model';
import { FieldPlanDetailShippingModalComponent } from '../field-plan-detail-shipping-modal/field-plan-detail-shipping-modal.component';
import { FieldPlanEmailType } from './field-plan-email-type-enum';
import { User } from 'src/app/authentication/user-model';

@Component({
  selector: 'app-field-plan-detail',
  templateUrl: './field-plan-detail.component.html',
  styleUrl: './field-plan-detail.component.css'
})

export class FieldPlanDetailComponent implements OnInit, AfterViewInit {
  isMobilized: any;

  @ViewChild('panelMenu')
  panelMenu!: TemplateRef<any>;

  overlayRef!: OverlayRef | null;
  sub: Subscription = new Subscription;
  rightClickItem: any;

  @Input()
  get userPrefs(): any {
    return this._userPrefs;
  }
  set userPrefs(userPrefs: any) {
    if (userPrefs === '' || userPrefs === undefined) {
      this._userPrefs = [];
    }
    else {
      this._userPrefs = userPrefs;
      this.userPrefArray = this._userPrefs?.split('|', 20);
    }

  }
  private _userPrefs: any;
  fieldPlanDataService: FieldPlanDataService = inject(FieldPlanDataService);

  @Input() fieldPlanSummary!: FieldPlanSummaryInfo;
  @Input() panelTypes!: any;
  @Input() firstShipDate!: Date;
  @Input() craneId!: number;
  @Input() craneCount!: number;
  @Input() jobNumber!: number;
  @Input() sortableType!: string;
  @Input() fullyMobilized!: boolean;
  @Input() partiallyMobilized!: boolean;

  @Input() maxWeight!: number;

  @Output() triggerPageReload = new EventEmitter<any>();
  @Output() showSendAlertModal = new EventEmitter<any>();
  @Output() emailType = new EventEmitter<any>();

  bsModalRef?: BsModalRef;
  userPrefArray: string[] = [];

  isEditable: boolean = true;
  editableDate: boolean = false;
  isFriday: boolean = true;
  isShipping: boolean = false;
  canCreateDay: boolean = false;
  canReverseSort: boolean = false;

  oldShipDate: Date = new Date();

  mmddyyShipDate: string = '';
  minDate: Date = new Date();


  showMark: boolean = true;
  mtsModel: FieldPlanMinToSetUpdate = { jobNumber: this.jobNumber, selectedPanels: [], minutesToSet: null };

  alertMsg: string = '';
  mtsConfirmMsg: string = '';
  selectedPanels: any = [];
  manuallySelectedPanelIds: string[] = [];
  manuallySelectedLoadIds: string[] = [];

  sortedPanels: SortedPanel[] = [];
  sortedLoads: SortedLoad[] = [];

  fieldPlanSortable: FieldPlanDetailSortable = { sortedLoads: this.sortedLoads, sortedPanels: this.selectedPanels }
  fieldPlanSummaryItems: FieldPlanSummaryDetail[] = [];

  totalPanels: number = 0;
  totalLoads: number = 0;
  totalTime: number = 0;
  totalTimeString: string = '';
  selectedType: any;

  inputTotalPanels: number = 0;

  holidayData: ProjectDateExceptions[] = [];
  isModalShown: boolean = true;

  uniqueLoads: string[] = [];

  //Added for Multi-drop functionality
  events = [];
  draggedMarkNumbers: string[] = [];
  selectedFieldPlanSummaryItems: any[] = [];
  selectedFieldPlanLoads: any[] = [];
  draggedLoadNumbers: number[] = [];
  draggedToLoadNum: string = '';

  holidaySchedules: HolidaySchedule[] = [];
  holidayCompanies: Company[] = [];
  shipDateChangeMessage: string = '';
  isDroppedInSummaryRow: boolean = false;
  hasDragStarted: boolean = false;
  static lastRowSelected: FieldPlanSummaryDetail;
  static lastLoadSelected: FieldPlanSummaryDetail;
  placeholderHeight: number = 0;

  @ContentChild(TemplateRef, { static: false }) templateRef: any;

  constructor(@Inject(LOCALE_ID) private locale: string, private toast: ToastService, private modalService: BsModalService, public overlay: Overlay,
    private authService: AuthService,
    private datePipe: DatePipe,
    public viewContainerRef: ViewContainerRef,
    private elem: ElementRef,
    private cdRef: ChangeDetectorRef,
    private adminHolidayScheduleService: AdminHolidayScheduleService) {
  }

  ngOnInit(): void {
    this.userPrefArray = this.userPrefs?.split('|', 20);
    this.oldShipDate = this.fieldPlanSummary.shipDate;
    this.isMobilized = this.fieldPlanSummary.mobilization != null;
    this.canCreateDay = this.sortableType !== "1";
    this.canReverseSort = this.sortableType === "1";
    this.mmddyyShipDate = this.datePipe.transform(this.fieldPlanSummary.shipDate, 'MM/dd/yyyy') ?? '';
    this.minDate = new Date(this.firstShipDate);

    this.editableDate = (this.firstShipDate !== this.fieldPlanSummary.shipDate && this.craneId === 1) || this.craneId !== 1;
    this.fieldPlanSummary.summaryData.forEach(data => {
      if (data.detailRow) {
        this.totalPanels += 1;
      }

      if (data.summaryRow) {
        this.totalLoads += 1;
      }

      if (data.summaryRow) {
        this.totalTime += data.timeToSet;
        this.totalTime + data.craneMoveDelay;

        if (data.checkOutTimestamp !== null) {
          data.hasStatus = true;
          data.statusImage = '/assets/images/LongTruck.png';
          data.statusText = 'Load checked out';
        }
        else if ((data.missingPanels === 0 && data.missingItems === 0) &&
          (data.checkInTimestamp !== null || data.loadedTimestamp !== null)) {
          data.hasStatus = true;
          data.statusImage = '/assets/images/LoadedTruck.png';
          data.statusText = 'Loading complete';
        }
        else if (data.loadedTimestamp !== null) {
          data.hasStatus = true;
          data.statusImage = '/assets/images/MiJack.png';
          data.statusText = 'Panels on trailer';
        }
        else if (data.checkInTimestamp !== null) {
          data.hasStatus = true;
          data.statusImage = '/assets/images/MiJack.png';
          data.statusText = 'Ready to be loaded';
        }

        if (data.mobilizationNum == null && data.checkInTimestamp == null) {
          //console.log('data.mobilizationNum', data.mobilizationNum)
          data.isMobilized = true;
        }
      }

      this.isEditable = this.determineIsEditable();
      this.isShipping = (this.isShipping || data.hasStatus);
    });

    this.sortedPanels = [];
    this.sortedLoads = [];

    if (this.sortableType === "1") {
      this.sortedPanels = this.sortPanels(this.fieldPlanSummary.summaryData);
    }
    else if (this.sortableType === "2") {
      this.sortedPanels = this.sortPanels(this.fieldPlanSummary.summaryData);
      this.sortedLoads = this.sortLoads(this.fieldPlanSummary.summaryData);
    }

    this.getTotalTimeString();

    this.inputTotalPanels = this.totalPanels;
    this.fieldPlanSortable.sortedLoads = this.sortedLoads;
    this.fieldPlanSortable.sortedPanels = this.sortedPanels;

    // this.fieldPlanDataService.setCraneDelay(this.jobNumber, this.craneId, selectedLoad, craneDelay.craneMoveDelay).subscribe({
    //   next: (response: ErrorResponse) => {
    //     if (response.statusCode === HttpStatusCode.Ok || response.statusCode === HttpStatusCode.Created) {
    //       this.triggerPageReload.emit(true);
    //     }
    //     else {
    //       this.toast.initiate({
    //         title: 'Error',
    //         content: response.message,
    //         type: toastTypes.error,
    //       });
    //     }
    //   },
    //   error: (error: HttpErrorResponse) => {
    //     this.toast.initiate({
    //       title: error.error.name,
    //       content: error.error.message,
    //       type: toastTypes.error,
    //     });
    //   }
    // });
  }

  private determineIsEditable(): boolean {

    let isProjectManager = this.authService.hasRole('ProjectManager');
    let isYardManager = this.authService.hasRole('YardManagement');
    let isAdministrator = this.authService.hasRole('Administration');

    return isAdministrator ||
      (isProjectManager && this.fieldPlanSummary.mobilization == null) ||
      (isYardManager && this.partiallyMobilized);
  }

  @ViewChildren(CdkDropList)
  private dlq!: QueryList<CdkDropList>;

  public dls: CdkDropList[] = [];

  dragEnded($event: CdkDragEnd) {
    //We make sure the user is not dropping a dragged element into the Summary Row.
    // let summaryRow: any = $event.event;
    // let parentElement: string = summaryRow.toElement.parentElement.innerText.indexOf("LD Summary") == 0 ? summaryRow.toElement.parentElement.innerText.toUpperCase() : ' ';
    // this.isDroppedInSummaryRow = summaryRow.toElement.innerHTML.toUpperCase() == 'LD SUMMARY' || parentElement.includes('LD SUMMARY');
  }

  summaryDrop(event: CdkDragDrop<FieldPlanSummaryDetail[]>) {
    this.clearSelection();
    this.alertMsg = `Target load is not able to be edited.`;
    this.openAlertModal(this.alertMsg);

    return;
  }

  mouseUp(event: any) {
    if (!this.hasDragStarted) { return; }

    this.clearSelection();
    this.isDroppedInSummaryRow = true;
    this.alertMsg = `Target load is not able to be edited.`;
    this.openAlertModal(this.alertMsg);
    return;

  }

  mouseDown(event: any, rowSelected: FieldPlanSummaryDetail) {

    if (this.manuallySelectedPanelIds.length > 1) { return; }

    if (event.button == 0 && event.ctrlKey == false && event.shiftKey == false && this.draggedMarkNumbers.length == 1) {
      this.draggedMarkNumbers = [];
      this.draggedMarkNumbers.push(rowSelected.markNum);
      localStorage.setItem('draggedMarkNumbers', JSON.stringify(this.draggedMarkNumbers));
    }
  }


  hasSelectedRow(): boolean {
    if (this.hasDragStarted) { return true; }

    return false;
  }

  drop(event: CdkDragDrop<FieldPlanSummaryDetail[]>) {

    if (event.container.data == event.previousContainer.data && event.previousIndex == event.currentIndex && !this.isDroppedInSummaryRow) {
      this.triggerPageReload.emit(true);
      return;
    }

    if (this.isDroppedInSummaryRow) {
      this.isDroppedInSummaryRow = false;
      this.hasDragStarted = false;
      return;
    }

    let isReadyToBeLoaded: boolean = event.container.data.some(x => x.checkInTimestamp !== null);

    if (!this.canDroponTarget(event.container.data)) return;

    //isDroppedInSummaryRow is set in the dragEnded event
    // if (this.isDroppedInSummaryRow || isReadyToBeLoaded) {
    //   this.alertMsg = `Target load is not able to be edited.`;
    //   this.openAlertModal(this.alertMsg);
    //   this.isDroppedInSummaryRow = false;
    //   return;
    // }

    //prevContainer holds the data container that is being moved
    // eventContainer  holds the data container where the data is moving
    // event.item is the datarow being moved
    //check if locations match between containers
    let nextLoad: boolean = false;
    let targetMarkNum: string;

    console.log('drop', event);
    if (JSON.parse(localStorage.getItem("draggedMarkNumbers")!) == null) {
      this.draggedMarkNumbers.push(event.item.data.markNum);
      localStorage.setItem('draggedMarkNumbers', JSON.stringify(this.draggedMarkNumbers));
    }
    else if (this.draggedMarkNumbers.length == 1) {
      this.draggedMarkNumbers = [];
      this.draggedMarkNumbers.push(event.item.data.markNum);
      localStorage.setItem('draggedMarkNumbers', JSON.stringify(this.draggedMarkNumbers));
    }

    // if current index is 0 then set the target mark to null so it puts it in the first position
    // otherwise it should use the target marknum to place the row

    targetMarkNum = event.currentIndex === 0 ? '' : event.container.data[event.currentIndex].markNum;
    let draggedMarkNum = event.item.data.markNum;
    let targetShipDate = this.fieldPlanSummary.shipDate;
    const targetCraneNumber = this.craneId;
    const currentLoc: string = event.item.data.castLoc;
    const currentLoad: number = event.item.data.loadNum;
    const targetLoc: string = event.container.data[event.currentIndex].castLoc;
    const targetLoad: number = event.container.data[event.currentIndex].loadNum;
    let isVeryFirstPanel: boolean = false;

    if (targetLoc !== currentLoc) {
      this.alertMsg = `Cannot combine panels from seperate locations.`;
      const okAction = 'resortLoad';
      this.openAlertModal(this.alertMsg, okAction);
      this.bsModalRef?.content.confirmAction.subscribe((action: string) => {
        if (action === okAction) {
          this.triggerPageReload.emit(true);
        }
      });
      return;
    }

    if (targetLoad !== currentLoad) {

      let totalWeight: number = 0;

      event.container.data.forEach(panel => { if (panel.detailRow) { totalWeight += +panel.weight; } });

      event.previousContainer.data.forEach((x: FieldPlanSummaryDetail, i) => {
        if (localStorage?.getItem('draggedMarkNumbers')?.includes(x.markNum)) {
          if (x.detailRow) { totalWeight += +x.weight; }
        }
      });

      if (totalWeight > this.maxWeight) {
        const okAction = 'resortLoad';
        this.alertMsg = `Combined weight: ${totalWeight} exceeds max weight: ${this.maxWeight}`;
        this.openAlertModal(this.alertMsg, okAction);

        this.bsModalRef?.content.confirmAction.subscribe((action: string) => {
          if (action === okAction) {
            this.triggerPageReload.emit(true);
          }
        });
        return;
      }
    }
    // else if(targetLoad == currentLoad){
    //   console.log('targetLoad == currentLoad', targetLoad, currentLoad, event.item, event.container.data[event.currentIndex])
    //   this.alertMsg = `Target load is not able to be edited.`;
    //   this.openAlertModal(this.alertMsg);
    //   return;
    // }

    if (this.hasAlreadyShipped(event.container.data[0])) return;

    let storedMarkNumbers: FieldPlanSummaryDetail[] = [];
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );

      if (localStorage?.getItem('draggedMarkNumbers')?.length == 1) { return; }


      //Add to container
      event.previousContainer.data.forEach((x: FieldPlanSummaryDetail, i) => {
        if (localStorage?.getItem('draggedMarkNumbers')?.includes(x.markNum)) {
          if (event.item.data.markNum != x.markNum) {
            storedMarkNumbers.push(x);
          }
        }
      });

      if (event.currentIndex < event.previousIndex) {
        event.previousContainer.data.splice(event.previousIndex + 1, 1);

        event.container.data.splice(event.currentIndex + 1, 0, ...storedMarkNumbers);

        moveItemInArray(
          event.container.data,
          event.previousIndex + 1,
          event.previousIndex
        );
      }
      else if (event.previousIndex > event.currentIndex) {
        event.previousContainer.data.splice(event.previousIndex - 1, 1);

        event.container.data.splice(event.currentIndex - 1, 0, ...storedMarkNumbers);

        moveItemInArray(
          event.container.data,
          event.previousIndex - 1,
          event.previousIndex
        );
      }
    } else {

      // //Add to container
      event.previousContainer.data.forEach((x: FieldPlanSummaryDetail, i) => {
        if (localStorage?.getItem('draggedMarkNumbers')?.includes(x.markNum)) {
          if (event.item.data.markNum != x.markNum) {
            storedMarkNumbers.push(x);
          }
        }
      });


      event.container.data.splice(event.currentIndex, 0, ...storedMarkNumbers);

      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }

    this.sleep(250).then(() => {

      //Remove from previous container
      storedMarkNumbers.forEach((x: FieldPlanSummaryDetail) => {
        if (event.previousContainer === event.container) {
          let removeIndex = event.container.data.indexOf(x);
        }
        else {
          let removeIndex = event.previousContainer.data.indexOf(x);
          event.previousContainer.data.splice(removeIndex, 1);
        }
      });

      this.sleep(250).then(() => {

        //Figuring out if this is the first MarkNum of the day.
        let allDropListsByShipDate = document.querySelectorAll<HTMLElement>(`[id^='app-field-plan-detail-${targetShipDate}']`)!;
        let currentDropList: HTMLElement = targetCraneNumber == 1 ? allDropListsByShipDate[0] : allDropListsByShipDate[1];
        let firstDropRow = currentDropList.querySelector('.cdk-drag.row.gx-1.ldSummaryGutter')!;
        // console.log(currentDropList.querySelector('.cdk-drag.row.gx-1.ldSummaryGutter')!.getAttribute('id'))
        let firstDropRowId = firstDropRow.getAttribute('id')!;
        let firstDropRowMarkNumIndex = firstDropRowId.indexOf("_") + 1;
        let firstDropRowTargetMarkNum = firstDropRowId.substring(firstDropRowMarkNumIndex, firstDropRowId.length);

        if (JSON.parse(localStorage?.getItem('draggedMarkNumbers')!).includes(firstDropRowTargetMarkNum)) {
          //console.log('firstPanel', firstDropRowTargetMarkNum, event.container.data, event.previousContainer.data, JSON.parse(localStorage?.getItem('draggedMarkNumbers')!), event.currentIndex, targetLoad, currentLoad);
          isVeryFirstPanel = true;
          targetMarkNum = '';
        }
        else {
          // console.log('NOT firstPanel', JSON.parse(localStorage?.getItem('draggedMarkNumbers')!));
          let elements: any[] = Array.from(document.querySelectorAll('.cdk-drag.row.gx-1'));
          let draggedNumberIndex = elements[elements.findIndex(s => s.id == `row_${draggedMarkNum}`) - 1];
          let rowId = draggedNumberIndex.getAttribute('id');
          let markNumIndex = rowId.indexOf("_") + 1;
          targetMarkNum = rowId.substring(markNumIndex, rowId.length);
          isVeryFirstPanel = false;
        }

        //console.log('isVeryFirstPanel', isVeryFirstPanel, event.currentIndex)

        if (event.currentIndex == 0 && isVeryFirstPanel) {
          //console.log('event.currentIndex == 0 && isVeryFirstPanel')
          nextLoad = false;
        }
        else if (targetLoad > currentLoad && event.currentIndex > 0) {
          //console.log('targetLoad > currentLoad && event.currentIndex > 0')
          nextLoad = false;
        }
        else if (targetLoad > currentLoad) {
          //console.log('targetLoad > currentLoad')
          nextLoad = true;
        }
        else if (event.currentIndex == 0) {
          //console.log('event.currentIndex == 0')
          nextLoad = true;
        }
        else {
          //console.log('else')
          nextLoad = false;
        }

        this.draggedMarkNumbers = JSON.parse(localStorage?.getItem('draggedMarkNumbers')!);

        // let foundInSummaryRow = event.previousContainer.data.some(({ markNum }) => markNum ? this.draggedMarkNumbers.includes(markNum) : false);

        // if(this.isDroppedInSummaryRow && event.previousContainer.data[0].markNum == null)
        // {
        //   this.alertMsg = `Target load is not able to be edited.`;
        //   this.openAlertModal(this.alertMsg);
        //   return;
        // }
        console.log('SendDB', this.draggedMarkNumbers, targetMarkNum, nextLoad, targetShipDate, targetCraneNumber);
        if (this.isMobilized) {
          this.resortMobilizedPanels(this.draggedMarkNumbers, targetMarkNum, nextLoad, targetShipDate, targetCraneNumber);
          return;
        }
        this.resortPanels(this.draggedMarkNumbers, targetMarkNum, nextLoad, targetShipDate, targetCraneNumber)
      });

    });


    return;

  }

  sleep(ms: number | undefined) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }



  async dropLoad(event: CdkDragDrop<FieldPlanSummaryDetail[]>) {

    if (document.querySelectorAll(".selected").length == 0) {
      this.selectLoad(event, event.previousContainer.data);
    }

    if (event.container.data == event.previousContainer.data && event.previousIndex == event.currentIndex && !this.isDroppedInSummaryRow) {
      this.triggerPageReload.emit(true);
      return;
    }


    this.draggedToLoadNum = event.currentIndex === 0 ? '' : event.container.data[event.currentIndex].loadNum.toString();
    console.log(this.draggedToLoadNum)
    //this.draggedToLoadNum = event.container.data[event.currentIndex].loadNum.toString();

    const targetShipDate = event.container.data[0].expShipDate;
    const targetCraneNumber = event.container.data[0].crane;

    let storedMarkNumbers: FieldPlanSummaryDetail[] = [];
    let hasPreviousDraggedToLoadNum: boolean = false;
    let islastOfDay: boolean = false;

    if (event.currentIndex > event.previousIndex) {
      //console.log('Last, Move Up/Down', this.draggedLoadNumbers, event.previousContainer.data, event.container.data);
      event.container.data.splice(event.container.data.length + 1, 0, ...this.selectedFieldPlanLoads);

      //event.container.data.splice(event.container.data.length + 1, 0, ...event.previousContainer.data);
      this.sleep(250).then(() => {

      });
      //console.log('drag selected', document.querySelector('.cdk-drag.selected')?.getAttribute('id'));
    }
    else if (event.currentIndex == event.previousIndex) {
      //console.log('Move First', this.draggedLoadNumbers, event.previousContainer.data, event.container.data);
      event.container.data.splice(0, 0, ...this.selectedFieldPlanLoads);
      //event.container.data.splice(0, 0, ...event.previousContainer.data);

      //This is for the condition where the moving load gets attached to the load below it and we need the target Load Num from the one above.
      let allCranesDetail = document.querySelectorAll<HTMLElement>("[id^='app-field-plan-detail-" + `${targetShipDate}` + "']");
      let currentDropList1: HTMLElement = targetCraneNumber == 2 ? allCranesDetail[1] : allCranesDetail[0];
      let allDropLists = currentDropList1.querySelectorAll('.cdk-drop-list')!;

      allDropLists.forEach((s, i) => {
        if (s.className.includes('cdk-drop-list-dragging')) {
          let draggingIndex = i - 1;
          if (draggingIndex >= 0) {
            let targetElement: Element = allDropLists[draggingIndex].childNodes[0] as Element;
            this.draggedToLoadNum = targetElement.id.substring(5);
          }
        }
      })

      this.sleep(250).then(() => {

      });

    }

    //console.log('drag selected final', document.querySelector('.cdk-drag.selected')?.getAttribute('id'), document.querySelector('.cdk-drag.selected.cdk-drag-dragging')?.getAttribute('id'));

    this.sleep(250).then(() => {
      // console.log('this.draggedToLoadNum.length', this.draggedToLoadNum.length);
      this.draggedLoadNumbers.forEach(loadNum => {
        document.getElementById(`load_${loadNum}`)?.remove();
      })

      event.container.element.nativeElement.classList.add("selected");
    });

    this.sleep(1000).then(() => {

      if (this.draggedToLoadNum.length == 0) {

        if (event.container.data !== null) {
          let mapped: FieldPlanSummaryDetail[] = [];

          this.fieldPlanSortable.sortedLoads.forEach(x => {
            x.fieldPlanSummaryDetail?.forEach(d => {
              if (!mapped.includes(d) && !d.summaryRow) {
                mapped.push(d);
              }
            })
          })

          let copySortedLoads = this.sortByAnotherArray(mapped, event.container.data);

          //console.log('copySortedLoads', mapped, event.container.data, copySortedLoads);
          let targetNumIndex = copySortedLoads.findIndex(f => f.loadNum == this.draggedLoadNumbers[0]) - 1;
          this.draggedToLoadNum = targetNumIndex == -1 ? '' : copySortedLoads[targetNumIndex].loadNum.toString();
        }
      }


      if (this.draggedToLoadNum.length == 0) {


        //Working for last of the day
        let containerIdIndex = islastOfDay ? event.container.id.lastIndexOf("-") - 1 : event.container.id.lastIndexOf("-") + 1;
        let currentContainerId: number = hasPreviousDraggedToLoadNum == true ? Number(event.container.id.substring(containerIdIndex)) - 1 : Number(event.container.id.substring(containerIdIndex));
        let currentDropList: HTMLElement = document.getElementById(`cdk-drop-list-${currentContainerId}`)!;

        if (currentDropList !== null && hasPreviousDraggedToLoadNum) {
          // Get all the child nodes of the parent element
          const childNodes = currentDropList.childNodes;
          let loadNumId;
          // Iterate over the child nodes
          for (let i = 0; i < childNodes.length; i++) {
            const childNode = childNodes[i];

            // Check if the child node is an element node
            if (childNode.nodeType === Node.ELEMENT_NODE) {
              const element = childNode as Element;

              // Get the ID of the element
              loadNumId = element.id;

              // Do something with the ID
              console.log("Child node ID:", loadNumId);
              if (loadNumId.includes('load_')) {
                break;
              }
            }
          }

          //let loadNumId = currentDropList.querySelector('.cdk-drop-list.selected')?.getAttribute('id'); // currentDropList.querySelector('.cdk-drop-list.selected')?.getAttribute('id');
          console.log(loadNumId, loadNumId!.lastIndexOf("_"))
          let loadNumIdIndex = islastOfDay ? loadNumId!.lastIndexOf("_") - 1 : loadNumId!.lastIndexOf("_") + 1;
          this.draggedToLoadNum = loadNumId!.substring(loadNumIdIndex);

          if (this.draggedLoadNumbers[0].toString() == this.draggedToLoadNum) {
            this.draggedLoadNumbers = [];
            this.draggedLoadNumbers.push(event.container.data[event.container.data.length - 1].loadNum);
          }
        }
        else {
          //FIRST ONE OF THE DAY
          this.draggedToLoadNum = '';
        }
      }

      if (islastOfDay) {
        //this.draggedLoadNumbers = [];
        //this.draggedLoadNumbers.push(event.container.data[event.container.data.length - 1].loadNum)
        this.draggedToLoadNum = event.container.data[0].loadNum.toString();
      }

      //This is for checking moving from a non-mobilized day to a mobilzed day.
      let hasMobilizationNum = event.container.data.some(s => s.mobilizationNum !== null);

      console.log('SendDB', this.draggedLoadNumbers, this.draggedToLoadNum, targetShipDate, targetCraneNumber);
      if (this.isMobilized || hasMobilizationNum) {
        this.resortMobilizedLoads(this.draggedLoadNumbers, this.draggedToLoadNum, targetShipDate, targetCraneNumber);
        return;
      }

      this.resortLoad(this.draggedLoadNumbers, this.draggedToLoadNum, targetShipDate, targetCraneNumber);
    });

  }

  sortByAnotherArray<T>(arr: T[], order: T[]): T[] {
    const orderMap = new Map(order.map((item, index) => [item, index]));

    return arr.sort((a, b) => {
      const indexA = orderMap.get(a);
      const indexB = orderMap.get(b);

      if (indexA === undefined) return 1; // Place items not in the order array at the end
      if (indexB === undefined) return -1;

      return indexA - indexB;
    });
  }

  async dropLoad2(event: CdkDragDrop<FieldPlanSummaryDetail[]>) {

    if (event.container.data == event.previousContainer.data && event.previousIndex == event.currentIndex && !this.isDroppedInSummaryRow) {
      this.triggerPageReload.emit(true);
      return;
    }

    if (this.draggedLoadNumbers.length > 1) {
      this.draggedLoadNumbers.forEach(draggedLoadNumber => {
        let draggedLoadNumbersCount: number = 1;

        event.item.data.forEach((id: FieldPlanSummaryDetail) => {
          if (draggedLoadNumbersCount < this.draggedLoadNumbers.length) {

            let hasLoadData: boolean = id.loadNum != draggedLoadNumber;
            if (hasLoadData) {
              let loadSelector = `load_${draggedLoadNumber}`;
              let selectedLoad = this.fieldPlanSortable.sortedLoads.filter(f => f.loadNum == draggedLoadNumber);
              event.item.data.push(...selectedLoad[0].fieldPlanSummaryDetail!);
              document.getElementById(loadSelector)?.remove();
            }
            draggedLoadNumbersCount++;
          }
        })
      })
    }

    this.sleep(250).then(() => {
      if (JSON.parse(localStorage.getItem("draggedLoadNumbers")!) == null || this.draggedLoadNumbers.length == 0) {
        this.draggedLoadNumbers.push(event.container.data[0].loadNum);
        localStorage.setItem('draggedLoadNumbers', JSON.stringify(this.draggedLoadNumbers));
      }
    });

    this.draggedToLoadNum = event.currentIndex === 0 ? '' : event.container.data[event.currentIndex].loadNum.toString();
    const targetShipDate = event.container.data[0].expShipDate;
    const targetCraneNumber = event.container.data[0].crane;

    let storedMarkNumbers: FieldPlanSummaryDetail[] = [];
    let hasPreviousDraggedToLoadNum: boolean = false;
    let islastOfDay: boolean = false;

    if (event.previousContainer === event.container) {

      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );

      if (localStorage?.getItem('draggedLoadNumbers')?.length == 1) { return; }

      //Add to container
      event.previousContainer.data.forEach((x: FieldPlanSummaryDetail, i) => {
        if (localStorage?.getItem('draggedLoadNumbers')?.includes(x.markNum)) {
          if (event.item.data.markNum != x.markNum) {
            storedMarkNumbers.push(x);
          }
        }
      });

      if (event.currentIndex < event.previousIndex) {
        event.previousContainer.data.splice(event.previousIndex + 1, 1);

        event.container.data.splice(event.currentIndex + 1, 0, ...storedMarkNumbers);

        moveItemInArray(
          event.container.data,
          event.previousIndex + 1,
          event.previousIndex
        );
      }
      else if (event.previousIndex > event.currentIndex) {
        event.previousContainer.data.splice(event.previousIndex - 1, 1);

        event.container.data.splice(event.currentIndex - 1, 0, ...storedMarkNumbers);

        moveItemInArray(
          event.container.data,
          event.previousIndex - 1,
          event.previousIndex
        );
      }
    } else {

      let allCranesDetail = document.querySelectorAll<HTMLElement>("[id^='app-field-plan-detail-" + `${targetShipDate}` + "']");

      //Do I have the first of the day or the last of the day?
      let currentDropList1: HTMLElement = targetCraneNumber == 2 ? allCranesDetail[1] : document.getElementById(`app-field-plan-detail-${targetShipDate}`)!;
      let allDropLists = currentDropList1.querySelectorAll('.cdk-drop-list')!;

      allDropLists.forEach((d, i) => {
        if (d.className == 'cdk-drop-list cdk-drop-list-dragging') {
          if (i == 0) {
            //First of the day
            hasPreviousDraggedToLoadNum = false;
            this.draggedToLoadNum = '';

            //Add to container
            event.previousContainer.data.forEach((x: FieldPlanSummaryDetail, i) => {
              if (x.loadNum) {
                let markNUmberExists: boolean = storedMarkNumbers.includes(x);
                if (!markNUmberExists) {
                  storedMarkNumbers.push(x);
                }
              }
            });

            event.container.data.splice(0, 0, ...storedMarkNumbers);
            //  for (var storedNumber in storedMarkNumbers) {
            //   transferArrayItem(
            //     event.previousContainer.data,
            //     event.container.data,
            //     event.previousIndex,
            //     event.currentIndex
            //   );
            //  }

            //Need to remove directly from the DOM since removing from event.previousContainer.data doesn't work in this scenario.
            let loadNumId = document.querySelector('.cdk-drag.selected.cdk-drag-dragging')?.getAttribute('id');
            document.getElementById(loadNumId!)?.remove();

          }
          else {
            hasPreviousDraggedToLoadNum = true;
            islastOfDay = allDropLists[allDropLists.length - 1].className == 'cdk-drop-list cdk-drop-list-dragging';

            //Add to container
            event.previousContainer.data.forEach((x: FieldPlanSummaryDetail, i) => {
              storedMarkNumbers.push(x);
            });

            event.container.data.splice(0, 0, ...storedMarkNumbers);
            if (islastOfDay) {
              for (var storedNumber in storedMarkNumbers) {
                transferArrayItem(
                  event.previousContainer.data,
                  event.container.data,
                  event.previousIndex,
                  event.container.data.length + 1
                );
              }
            }


            // transferArrayItem(
            //   event.previousContainer.data,
            //   event.container.data,
            //   event.previousIndex,
            //   event.container.data.length + 1
            // );
          }
        }
      })
    }

    event.container.element.nativeElement.classList.add("selected");

    this.sleep(250).then(() => {

      //Remove from previous container
      storedMarkNumbers.forEach((x: FieldPlanSummaryDetail) => {
        if (event.previousContainer === event.container) {
          let removeIndex = event.container.data.indexOf(x);
        }
        else if (islastOfDay) {
          let removeIndex = event.container.data.indexOf(x);
          event.container.data.splice(removeIndex, 1);
        }
        else {
          let removeIndex = event.previousContainer.data.indexOf(x);
          event.previousContainer.data.splice(removeIndex, 1);
        }
      });

    });

    this.sleep(1000).then(() => {

      //Working for last of the day
      let containerIdIndex = islastOfDay ? event.container.id.lastIndexOf("-") - 1 : event.container.id.lastIndexOf("-") + 1;
      let currentContainerId: number = hasPreviousDraggedToLoadNum == true ? Number(event.container.id.substring(containerIdIndex)) - 1 : Number(event.container.id.substring(containerIdIndex));
      let currentDropList: HTMLElement = document.getElementById(`cdk-drop-list-${currentContainerId}`)!;

      if (currentDropList !== null && hasPreviousDraggedToLoadNum) {
        let loadNumId = currentDropList.querySelector('.cdk-drag')?.getAttribute('id');
        let loadNumIdIndex = islastOfDay ? loadNumId!.lastIndexOf("_") - 1 : loadNumId!.lastIndexOf("_") + 1;
        this.draggedToLoadNum = loadNumId!.substring(loadNumIdIndex);

        if (this.draggedLoadNumbers[0].toString() == this.draggedToLoadNum) {
          this.draggedLoadNumbers = [];
          this.draggedLoadNumbers.push(event.container.data[event.container.data.length - 1].loadNum);
        }
      }
      else {
        //FIRST ONE OF THE DAY
        this.draggedToLoadNum = '';
      }

      if (islastOfDay) {
        this.draggedLoadNumbers = [];
        this.draggedLoadNumbers.push(event.container.data[event.container.data.length - 1].loadNum)
        this.draggedToLoadNum = event.container.data[0].loadNum.toString();
      }

      //This is for checking moving from a non-mobilized day to a mobilzed day.
      let hasMobilizationNum = event.container.data.some(s => s.mobilizationNum !== null);

      this.draggedLoadNumbers = JSON.parse(localStorage.getItem("draggedLoadNumbers")!);

      console.log('SendDB', this.draggedLoadNumbers, this.draggedToLoadNum, targetShipDate, targetCraneNumber);
      if (this.isMobilized || hasMobilizationNum) {
        this.resortMobilizedLoads(this.draggedLoadNumbers, this.draggedToLoadNum, targetShipDate, targetCraneNumber);
        return;
      }

      this.resortLoad(this.draggedLoadNumbers, this.draggedToLoadNum, targetShipDate, targetCraneNumber);
    });
  }

  hasAlreadyShipped(data: FieldPlanSummaryDetail): boolean {
    let hasShipped = false;

    if (data.color === 'rgb(0, 0, 255)' || data.color === '#0000FF') {
      hasShipped = true;
      this.alertMsg = `Cannot alter panels that have status of "Shipped`;
      this.openAlertModal(this.alertMsg);
      return hasShipped;
    }

    return hasShipped;
  }

  canDroponTarget(data: FieldPlanSummaryDetail[]): boolean {
    let canDrop = true;
    let row = data.find(x => x.summaryRow === true);
    if (row?.hasStatus === true) {
      canDrop = false;
      this.alertMsg = `'Target load is not able to be edited.`;
      this.openAlertModal(this.alertMsg);
    }

    return canDrop;
  }

  isArray(item: any): boolean {
    return Array.isArray(item);
  }

  ngAfterViewInit() {
    let ldls: CdkDropList[] = [];

    this.dlq.forEach((dl) => {
      //console.log('found DropList ' + dl.id);
      ldls.push(dl);
    });

    ldls = ldls.reverse();

    asapScheduler.schedule(() => {
      this.dls = ldls;
    });

    // this.adminHolidayScheduleService.getHolidaySchedules().subscribe({
    //   next: (schedules: any) => {
    //     console.log('schedules', schedules)
    //     this.holidaySchedules.push(...schedules);
    //     this.holidayCompanies.push(...schedules[0].companies);
    //     console.log('this.holidaySchedules', this.holidaySchedules, this.holidayCompanies)
    //     //this.calculateDaysDifference();
    //     console.log(new Date())
    //   },
    //   error: (error) => {
    //     console.error(error);
    //   }
    // });
  }

  sortPanels(summaryData: FieldPlanSummaryDetail[]): SortedPanel[] {
    let sortedPanels: SortedPanel[] = [];
    let detailSummaryRows: FieldPlanSummaryDetail[] = [];

    summaryData.forEach(data => {

      detailSummaryRows.push(data);

      if (data.summaryRow) {
        let newsortableRow: SortedPanel = { markNum: data.markNum, fieldPlanSummaryDetail: detailSummaryRows }
        sortedPanels.push(newsortableRow);
        detailSummaryRows = [];
      }

    });

    this.closeLoadingModal();
    return sortedPanels;
  }

  sortLoads(summaryData: FieldPlanSummaryDetail[]): SortedLoad[] {
    let sortedLoads: SortedLoad[] = [];
    let detailSummaryRows: FieldPlanSummaryDetail[] = [];
    let distinctLoads = Array.from(new Set(summaryData.map((item: FieldPlanSummaryDetail) => item.loadNum)));

    distinctLoads.forEach(load => {
      let newLoad: SortedLoad = { loadNum: load, fieldPlanSummaryDetail: [], summaryRow: null };
      let loadDetails = summaryData.filter(sd => sd.loadNum === load);
      loadDetails.forEach(data => {
        detailSummaryRows.push(data);

        if (data.summaryRow) {
          newLoad.fieldPlanSummaryDetail = loadDetails;
          newLoad.summaryRow = data;
          detailSummaryRows = [];
        }
      });
      sortedLoads.push(newLoad);
    });

    return sortedLoads;
  }

  checkEditablePanel(item: FieldPlanSummaryDetail): boolean {
    let allowEdit = false;
    if (this.sortableType === "1") {
      allowEdit = (item.loadedTimestamp !== null && item.checkInTimestamp !== null && !item.checkOutTimestamp !== null) ? false : true;
    }
    else if (this.sortableType === "2") {
      allowEdit = (item.loadedTimestamp !== null && item.checkInTimestamp !== null && item.checkOutTimestamp !== null) ? false : true;
    }

    return allowEdit;
  }


  openModal(template: TemplateRef<void>, showShipdateChangeMessage: boolean) {
    this.shipDateChangeMessage = showShipdateChangeMessage ? 'Are you sure you want to change the ship date?' : '';

    this.bsModalRef = this.modalService.show(template, Object.assign({}, { class: 'modal-lg modal-dialog-centered' }));
  }

  checkShipDateChange(newShipDate: Date): boolean {
    // if the component is loading... don't trigger change
    if (newShipDate === undefined || this.oldShipDate === newShipDate) {
      return false;
    }

    let proceedWithChange: boolean = false;

    // Make sure we aren't initializing the value
    if (this.oldShipDate == null)
      return false;

    var newDate = formatDate(new Date(newShipDate), 'YYYY/MM/DDTHH:mm:ssZ');
    var oldDate = formatDate(new Date(this.oldShipDate), 'YYYY/MM/DDTHH:mm:ssZ');

    if (newDate !== oldDate) {
      proceedWithChange = true;
    }
    return proceedWithChange;
  }

  shipDateChanged() {
    if (this.isMobilized) {
      this.showSendAlertModal.emit(true);
    }

    this.fieldPlanDataService.changedShipDate(this.jobNumber, this.craneId, this.oldShipDate, new Date(this.mmddyyShipDate)).subscribe(
      {
        next: (response: ErrorResponse) => {
          if (!this.isMobilized) {
            this.triggerPageReload.emit(true);
          }

        },
        error: (error: HttpErrorResponse) => {
          this.toast.initiate({
            title: error.error.name,
            content: error.error.message,
            type: toastTypes.error,
          });
        }
      });
  }

  resetShipDate() {
    this.fieldPlanSummary.shipDate = this.oldShipDate;
    this.mmddyyShipDate = this.datePipe.transform(this.oldShipDate, 'MM/dd/yyyy') ?? '';
  }

  resetMinutesToSet() {
    this.mtsModel.minutesToSet = null;
    this.mtsModel.selectedPanels = [];
  }

  fabconFridayChange(): void {
    const addedDates: Date[] = [];
    const removedDates: Date[] = [];
    if (this.fieldPlanSummary.fabconFriday) {
      addedDates.push(this.fieldPlanSummary.shipDate);
    }
    else {
      removedDates.push(this.fieldPlanSummary.shipDate);
    }

    this.fieldPlanDataService.changeFabconFriday(this.jobNumber, addedDates, removedDates).subscribe({
      next: (response: ErrorResponse) => {
        if (response.statusCode === HttpStatusCode.Ok || response.statusCode === HttpStatusCode.Created) {
          this.triggerPageReload.emit(true);
        }
      },
      error: (error: HttpErrorResponse) => {
        this.toast.initiate({
          title: error.error.name,
          content: error.error.message,
          type: toastTypes.error,
        });
      }
    });
  }

  checkIsFriday(shipDate: Date) {
    const sDate = new Date(shipDate);
    return sDate.getDay() === 5;

    //   || this.isTomorrowHoliday(detailItem.shipDate);
  }

  minutesToSetChanged(hasEmailAlert: boolean) {
    if (hasEmailAlert) {
      this.emailType.emit(FieldPlanEmailType.sendFieldPlanModificationEmail);
      this.showSendAlertModal.emit(true);
    }

    this.fieldPlanDataService.applyFieldPlanPanelChanges(this.jobNumber, this.mtsModel.minutesToSet as number, this.mtsModel.selectedPanels).subscribe({
      next: (response: ErrorResponse) => {
        if ((response.statusCode === HttpStatusCode.Ok || response.statusCode === HttpStatusCode.Created) && !hasEmailAlert) {
          this.triggerPageReload.emit(true);
        }
      },
      error: (error: HttpErrorResponse) => {
        this.toast.initiate({
          title: error.error.name,
          content: error.error.message,
          type: toastTypes.error,
        });
      }
    });
  }

  applyTotalPanel(event: any) {
    if (this.inputTotalPanels === this.totalPanels) {
      this.alertMsg = `Current panel count already matches the desired count. No changes detected.`;
      this.openAlertModal(this.alertMsg);
      return;
    }

    this.fieldPlanDataService.reassignPanels(this.jobNumber, this.craneId, this.oldShipDate, this.inputTotalPanels).subscribe({
      next: (response: ErrorResponse) => {
        if (response.statusCode === HttpStatusCode.Ok || response.statusCode === HttpStatusCode.Created) {
          this.triggerPageReload.emit(true);
        }
        else {
          this.toast.initiate({
            title: 'Error',
            content: response.message,
            type: toastTypes.error,
          });
        }
      },
      error: (error: HttpErrorResponse) => {
        this.toast.initiate({
          title: error.error.name,
          content: error.error.message,
          type: toastTypes.error,
        });
      },
      complete: () => { this.close(); }
    });

    //this.close();
  }

  selectRow(rowSelected: FieldPlanSummaryDetail, event: any) { //multiSelect: boolean

    if (this.sortableType === "1") {
      this.selectByPanel(rowSelected, event.ctrlKey || event.shiftKey);
    }
    else {
      this.selectByLoad(rowSelected, event.ctrlKey || event.shiftKey);
    }
  }

  selectByPanel(rowSelected: FieldPlanSummaryDetail, multiSelect: boolean) { //multiSelect: boolean
    const index = this.manuallySelectedPanelIds.indexOf(rowSelected.markNum, 0);

    if (!multiSelect) {
      this.removeAllRowsSelected();
      this.removeAllLoadsSelected();

      this.manuallySelectedPanelIds = [];
      this.manuallySelectedLoadIds = [];
      if (index === -1) {
        this.manuallySelectedPanelIds = [];
        this.addRowSelected(rowSelected.markNum);
        this.addLoadSelected(rowSelected.loadNum.toString());
      }
    }
    else {
      if (index === -1) {
        this.addRowSelected(rowSelected.markNum);
        this.addLoadSelected(rowSelected.loadNum.toString());
      }
      else {
        this.manuallySelectedPanelIds.splice(index, 1);
        this.removeRowSelected(rowSelected.markNum);
        this.removeLoadSelected(rowSelected.loadNum.toString());
      }
    }
  }

  selectByLoad(rowSelected: FieldPlanSummaryDetail, multiSelect: boolean) {
    const index = this.manuallySelectedLoadIds.indexOf(rowSelected.loadNum.toString(), 0);
    if (!multiSelect) {
      this.removeAllRowsSelected();
      this.removeAllLoadsSelected();
      this.manuallySelectedLoadIds = [];
      if (index === -1) {
        this.manuallySelectedPanelIds = [];
        this.addRowSelected(rowSelected.markNum);
        this.addLoadSelected(rowSelected.loadNum.toString())
      }
    }
    else {
      if (index === -1) {
        this.addRowSelected(rowSelected.markNum);
      }
      else {
        this.manuallySelectedPanelIds.splice(index, 1);
        this.removeRowSelected(rowSelected.markNum);
      }
    }
  }

  addRowSelected(rowId: string) {
    this.manuallySelectedPanelIds.push(rowId);
    let rowSelector = `row_${rowId}`
    if (this.sortableType === '1') {
      document.getElementById(rowSelector)?.classList.add("selected");
    }
  }

  removeAllRowsSelected() {
    this.manuallySelectedPanelIds.forEach(sp => {
      this.removeRowSelected(sp);
    })
  }

  removeRowSelected(rowId: string) {
    this.manuallySelectedPanelIds.forEach(sp => {
      let rowSelector = `row_${rowId}`;
      document.getElementById(rowSelector)?.classList.remove("selected");
    })
  }

  addLoadSelected(loadNum: string) {
    this.manuallySelectedLoadIds.push(loadNum);
    let loadSelector = `load_${loadNum}`

    //if (this.sortableType === '2') {
    document.getElementById(loadSelector)?.classList.add("selected");
    //}
  }

  removeAllLoadsSelected() {
    this.manuallySelectedLoadIds.forEach(sp => {
      this.removeLoadSelected(sp);
    })
  }

  removeLoadSelected(loadNum: string) {
    this.manuallySelectedLoadIds.forEach(sp => {
      let loadSelector = `load_${loadNum}`
      document.getElementById(loadSelector)?.classList.remove("selected");
    })
  }

  private calculateDaysDifference(holidayDate: string): number {
    const firstDate = new Date(holidayDate) // 10th May, 2022
    const secondDate = new Date() // today, 14th May, 2022

    const firstDateInMs = firstDate.getTime()
    const secondDateInMs = secondDate.getTime()

    const differenceBtwDates = secondDateInMs - firstDateInMs

    const aDayInMs = 24 * 60 * 60 * 1000

    const daysDiff = Math.round(differenceBtwDates / aDayInMs)

    //console.log(daysDiff)

    return daysDiff;
  }
  private isTomorrowHoliday(checkDate: Date): boolean {
    var tomorrow = checkDate.setDate(checkDate.getDate() + 1);
    var companies = Array.from(new Set(this.holidayCompanies.map((item: Company) => item.companyId))); //this.holidayCompanies.map(companyId);

    for (var index = 0; index < this.holidaySchedules.length; index++) {
      var holidayEvent = this.holidaySchedules[index];
      if (companies.includes(holidayEvent.companyId)) {
        //if (holidayEvent.Company.toLowerCase() == 'all' || _.includes(companies, holidayEvent.Company)) {
        // var holiday = moment(holidayEvent.Holiday);

        //let calculateDaysDifference = this.calculateDaysDifference(checkDate.getDate().toString());
        // if (tomorrow.diff(holiday, 'days') == 0)
        //   return true;
        // if(calculateDaysDifference == 1){
        //   return true;
        // }
      }
    }

    return false;
  }

  private getCompanies(): Array<string> {
    let result = new Array<string>();

    for (var summaryIndex = 0; summaryIndex < this.fieldPlanSummaryItems.length; summaryIndex++) {
      var summary = this.fieldPlanSummaryItems[summaryIndex];
      if (!_.includes(result, summary.castLoc)) {
        result.push(summary.castLoc);
      }
    }

    return result;
  }

  private getTotalTimeString() {
    const hours = '00' + Math.floor(this.totalTime / 60);
    const mins = '00' + this.totalTime % 60;

    this.totalTimeString = `${hours.slice(-2)} HH ${mins.slice(-2)} MM`;
  }

  public confirmMinutesToSendMessage(): boolean {
    this.alertMsg = '';
    this.mtsConfirmMsg = '';

    if (this.manuallySelectedPanelIds.length > 0) {
      this.mtsModel.selectedPanels = this.manuallySelectedPanelIds;
      this.mtsConfirmMsg = `Apply min to set to ${this.mtsModel.selectedPanels.length} selected panels?`;
    }
    else if (this.selectedType !== undefined) {
      this.mtsModel.selectedPanels = this.determinePanels(this.selectedType);
      this.mtsConfirmMsg = `Apply min to set to  ${this.mtsModel.selectedPanels?.length} ${this.selectedType} panels?`;

      if (this.mtsModel.minutesToSet === null) {
        this.alertMsg = 'No modified value for Min to Set provided.';
        this.openAlertModal(this.alertMsg);
        return false;
      }

      if (this.mtsModel.selectedPanels.length === 0) {
        this.alertMsg = `No panels found on this date matching selected panel type  ${this.selectedType}`;
        this.openAlertModal(this.alertMsg);
        return false;
      }
    }
    else {
      this.alertMsg = `No Panels selected to update`;
      this.openAlertModal(this.alertMsg);
      return false;
    }

    return true;
  }


  public confirmPanelsSelected(msgText: string): boolean {
    this.alertMsg = '';
    this.mtsConfirmMsg = '';

    if (this.manuallySelectedPanelIds.length > 0 || this.manuallySelectedLoadIds.length > 0) {
      return true;
    }
    else {
      this.alertMsg = `No Panels selected to ${msgText}`;
      this.openAlertModal(this.alertMsg);
      return false;
    }
  }

  public confirmSwitchCraneSelected(msgText: string): boolean {
    this.alertMsg = '';
    this.mtsConfirmMsg = '';

    if (this.manuallySelectedPanelIds.length === 0 && this.manuallySelectedLoadIds.length == 0) {
      this.alertMsg = `No Panels selected to ${msgText}`;
      this.openAlertModal(this.alertMsg);
      return false;
    }
    else if (this.craneCount === 1) {
      this.alertMsg = `Only one crane configured for project`;
      this.openAlertModal(this.alertMsg);
      return false;
    }
    else {
      return true;
    }
  }


  findLoad(markNum: string): number {
    const selectedRow = this.fieldPlanSummary.summaryData.find(x => x.markNum === markNum);

    return selectedRow !== undefined ? selectedRow.loadNum : 0;
  }

  determinePanels(panelType: string): any[] {
    let result = new Array();

    for (var panelIndex = 0; panelIndex < this.fieldPlanSummary.summaryData.length; panelIndex++) {
      let panel = this.fieldPlanSummary.summaryData[panelIndex];

      if (panel.panelType == panelType) {
        result.push(panel.markNum);
      }
    }

    return result;
  }

  reverseSort() {
    this.manuallySelectedPanelIds = JSON.parse(localStorage.getItem("draggedMarkNumbers")!);

    const confirmMessage = `Reverse Order ${this.manuallySelectedPanelIds.length} panels`;
    const modalTitle = 'Confirm';
    const closeBtnName = 'Cancel';
    const okAction = 'reverseSortOrder';

    this.openConfirmModal(confirmMessage, modalTitle, closeBtnName, okAction);

    this.bsModalRef?.content.confirmAction.subscribe((action: string) => {
      if (action === okAction) {
        this.fieldPlanDataService.reverseOrderFieldPlanPanels(this.jobNumber, this.craneId, this.manuallySelectedPanelIds).subscribe({
          next: (response: ErrorResponse) => {
            if (response.statusCode === HttpStatusCode.Ok || response.statusCode === HttpStatusCode.Created) {
              this.triggerPageReload.emit(true);
            }
            else {
              this.toast.initiate({
                title: 'Error',
                content: response.message,
                type: toastTypes.error,
              });
            }
          },
          error: (error: HttpErrorResponse) => {
            this.toast.initiate({
              title: error.error.name,
              content: error.error.message,
              type: toastTypes.error,
            });
          }
        });

        this.close();
      }
    });
  }

  resortMobilizedPanels(markNumbers: string[], targetMarkNum: string, nextLoad: boolean, targetDate: Date, targetCraneNumber: number) {
    const okAction = 'moveMobilizedPanel';
    const okActionAndSend = 'moveSendMobilizedPanel';
    const initialState: ModalOptions = {
      initialState: {
        okAction: okAction,
        okActionAndSend: okActionAndSend,
        message: `Do you want to move panel ${markNumbers} to the new sequence?`,
      },
      backdrop: 'static',
      keyboard: false
    };
    this.bsModalRef = this.modalService.show(FieldPlanDetailShippingModalComponent, initialState);
    this.bsModalRef?.setClass('modal-lg modal-dialog-centered');

    this.bsModalRef?.content.confirmAction.subscribe((action: string) => {
      if (action === okAction) {
        this.openLoadingModal();
        this.fieldPlanDataService.resortPanels(this.jobNumber, targetCraneNumber, targetDate, nextLoad, markNumbers, targetMarkNum).subscribe({
          next: (response: ErrorResponse) => {
            if (response.statusCode === HttpStatusCode.Ok || response.statusCode === HttpStatusCode.Created) {
              this.clearSelection();
              this.triggerPageReload.emit(true);
            }
          },
          error: (error: HttpErrorResponse) => {
            this.clearSelection();
            this.closeLoadingModal();
            this.toast.initiate({
              title: error.error.name,
              content: error.error.message,
              type: toastTypes.error,
            });
          },
          complete: () => console.log("resortPanels complete")
        });
      }
      else if (action === okActionAndSend) {
        this.emailType.emit(FieldPlanEmailType.sendFieldPlanModificationEmail);
        this.showSendAlertModal.emit(true);

        this.fieldPlanDataService.resortPanels(this.jobNumber, targetCraneNumber, targetDate, nextLoad, markNumbers, targetMarkNum).subscribe({
          next: (response: ErrorResponse) => {
            this.clearSelection();
          },
          error: (error: HttpErrorResponse) => {
            this.toast.initiate({
              title: error.error.name,
              content: error.error.message,
              type: toastTypes.error,
            });
          },
          complete: () => console.log("resortPanels complete")
        });

      }
      else {
        this.clearSelection();
        this.triggerPageReload.emit(true);
      }
    });
  }

  resortPanels(markNumbers: string[], targetMarkNum: string, nextLoad: boolean, targetDate: Date, targetCraneNumber: number) {

    console.log('resortPanels')
    const confirmMessage = `Do you want to move panel ${markNumbers.toString()} to the new sequence ?`;
    const modalTitle = 'Confirm';
    const closeBtnName = 'Cancel';
    const okAction = 'resortLoad';

    this.openConfirmModal(confirmMessage, modalTitle, closeBtnName, okAction);

    this.bsModalRef?.content.confirmAction.subscribe((action: string) => {
      if (action === okAction) {
        this.openLoadingModal();
        this.fieldPlanDataService.resortPanels(this.jobNumber, targetCraneNumber, targetDate, nextLoad, markNumbers, targetMarkNum).subscribe({
          next: (response: ErrorResponse) => {
            if (response.statusCode === HttpStatusCode.Ok || response.statusCode === HttpStatusCode.Created) {
              this.clearSelection();
              this.triggerPageReload.emit(true);
            }
          },
          error: (error: HttpErrorResponse) => {
            this.clearSelection();
            this.closeLoadingModal();
            this.toast.initiate({
              title: error.error.name,
              content: error.error.message,
              type: toastTypes.error,
            });
          },
          complete: () => console.log("findAllRepos observable complete")
        });
        this.close();
      }
      else {
        this.clearSelection();
        this.triggerPageReload.emit(true);
      }
    });

    this.close();
  }

  resortMobilizedLoads(loadNumbers: number[], prevLoadNum: string, targetDate: Date, targetCraneNumber: number) {
    const okAction = 'moveMobilizedLoad';
    const okActionAndSend = 'moveSendMobilizedLoad';
    const initialState: ModalOptions = {
      initialState: {
        okAction: okAction,
        okActionAndSend: okActionAndSend,
        message: `Do you want to move load ${loadNumbers} to the new sequence?`,
      },
      backdrop: 'static',
      keyboard: false
    };
    this.bsModalRef = this.modalService.show(FieldPlanDetailShippingModalComponent, initialState);
    this.bsModalRef?.setClass('modal-lg modal-dialog-centered');

    this.bsModalRef?.content.confirmAction.subscribe((action: string) => {
      if (action === okAction) {
        this.openLoadingModal();
        this.fieldPlanDataService.resortLoads(this.jobNumber, targetCraneNumber, targetDate, loadNumbers, prevLoadNum).subscribe({
          next: (response: ErrorResponse) => {
            if (response.statusCode === HttpStatusCode.Ok || response.statusCode === HttpStatusCode.Created) {
              this.triggerPageReload.emit(true);
            }
          },
          error: (error: HttpErrorResponse) => {
            this.closeLoadingModal();
            this.toast.initiate({
              title: error.error.name,
              content: error.error.message,
              type: toastTypes.error,
            });
          },
          complete: () => console.log("resortLoads complete")
        });
      }
      else if (action === okActionAndSend) {
        this.emailType.emit(FieldPlanEmailType.sendFieldPlanModificationEmail);
        this.showSendAlertModal.emit(true);

        this.fieldPlanDataService.resortLoads(this.jobNumber, targetCraneNumber, targetDate, loadNumbers, prevLoadNum).subscribe({
          next: (response: ErrorResponse) => {
          },
          error: (error: HttpErrorResponse) => {
            this.toast.initiate({
              title: error.error.name,
              content: error.error.message,
              type: toastTypes.error,
            });
          },
          complete: () => console.log("resortLoads complete")
        });

      }
      else {
        this.triggerPageReload.emit(true);
      }
    });
  }

  resortLoad(loadNumbers: number[], prevLoadNum: string, targetDate: Date, targetCraneNumber: number) {
    const confirmMessage = `Do you want to move load ${loadNumbers} to the new sequence?`;
    const modalTitle = 'Confirm';
    const closeBtnName = 'Cancel';
    const okAction = 'resortLoad';

    this.openConfirmModal(confirmMessage, modalTitle, closeBtnName, okAction);

    this.bsModalRef?.content.confirmAction.subscribe((action: string) => {
      if (action === okAction) {
        this.openLoadingModal();
        this.fieldPlanDataService.resortLoads(this.jobNumber, targetCraneNumber, targetDate, loadNumbers, prevLoadNum).subscribe({
          next: (response: ErrorResponse) => {
            if (response.statusCode === HttpStatusCode.Ok || response.statusCode === HttpStatusCode.Created) {
              this.triggerPageReload.emit(true);
            }
          },
          error: (error: HttpErrorResponse) => {
            this.closeLoadingModal();
            this.toast.initiate({
              title: error.error.name,
              content: error.error.message,
              type: toastTypes.error,
            });
          }
        });

        this.close();
      }
      else {
        this.triggerPageReload.emit(true);
      }
    });

    this.close();
  }

  splitLoad(template: TemplateRef<void>) {
    this.uniqueLoads = JSON.parse(localStorage.getItem("draggedLoadNumbers")!) //this.getUniqueArrayValues(this.manuallySelectedLoadIds);

    const confirmMessage = `Split load number ${this.uniqueLoads} into separate loads`;
    const modalTitle = 'Confirmation';
    const closeBtnName = 'Cancel';
    const okAction = 'splitLoads';

    if (this.isMobilized) {
      const initialState: ModalOptions = {
        initialState: {
          okAction: okAction,
          message: `Split load number ${this.uniqueLoads.toString()} into separate loads`
        }
      };
      this.bsModalRef = this.modalService.show(FieldPlanDetailSplitLoadModalComponent, initialState);
      this.bsModalRef?.setClass('modal-lg modal-dialog-centered');
    }
    else if (!this.isMobilized) {
      this.openConfirmModal(confirmMessage, modalTitle, closeBtnName, okAction);
    }

    this.bsModalRef?.content.confirmAction.subscribe((action: string) => {

      if (action === okAction) {
        this.emailType.emit(FieldPlanEmailType.sendFieldPlanModificationEmail);

        // this.showSendAlertModal.emit(true);

        this.fieldPlanDataService.splitLoads(this.jobNumber, this.craneId, this.uniqueLoads).subscribe({
          next: (response: ErrorResponse) => {
            this.triggerPageReload.emit(true);
          },
          error: (error: HttpErrorResponse) => {
            this.toast.initiate({
              title: error.error.name,
              content: error.error.message,
              type: toastTypes.error,
            });
          }
        });

        this.clearSelection();
        this.close();
      }
      else if (action === "cancel") {
        this.clearSelection();
      }
    });

    this.close();
  }

  getUniqueArrayValues(arr: string[]) {
    const removeDups = (arr: string[]): string[] => {
      return [...new Set(arr)];
    }

    return removeDups(this.manuallySelectedLoadIds);
  }

  createDay() {

    let mobilized = this.isMobilized || !this.isEditable;

    const selectedMark: string = (this.manuallySelectedPanelIds)[this.manuallySelectedPanelIds.length - 1];
    const selectedLoad: number = this.findLoad(selectedMark);

    if (selectedLoad === 0)
      return;

    const dateMessage = `Please select a new date for load: ${selectedLoad}`;
    var formattedShipDate = formatDate(new Date(this.fieldPlanSummary.shipDate), 'MM-DD-YYYY');
    this.openDateSelectionModal(dateMessage, new Date(formattedShipDate), this.minDate);

    this.bsModalRef?.content.dateSelected.subscribe((currentShipDate: any) => {
      if (currentShipDate !== undefined) {
        if (this.oldShipDate === currentShipDate) {
          this.alertMsg = `Date already exists in field plan - please manually move loads to this date`;
          this.openAlertModal(this.alertMsg);
          return;
        }

        this.fieldPlanDataService.createDay(this.jobNumber, this.craneId, currentShipDate, selectedLoad).subscribe({
          next: (response: ErrorResponse) => {
            if (response.statusCode === HttpStatusCode.Ok || response.statusCode === HttpStatusCode.Created) {
              this.triggerPageReload.emit(true);
            }
            else {
              this.toast.initiate({
                title: 'Error',
                content: response.message,
                type: toastTypes.error,
              });
            }
          },
          error: (error: HttpErrorResponse) => {
            this.toast.initiate({
              title: error.error.name,
              content: error.error.message,
              type: toastTypes.error,
            });
          }
        });
        this.close();
      }
    });
  }

  createMobilization() {

    const confirmMessage = `create mobilization effective: ${this.fieldPlanSummary.shipDate} through  ${this.manuallySelectedPanelIds[this.manuallySelectedPanelIds.length - 1]}`;
    const modalTitle = 'Confirm';
    const closeBtnName = 'Cancel';
    const okAction = 'createMobilization';

    this.openConfirmModal(confirmMessage, modalTitle, closeBtnName, okAction);

    this.bsModalRef?.content.confirmAction.subscribe((action: string) => {
      if (action === okAction) {
        //TODO add real user name
        this.fieldPlanDataService.createMobilization(this.jobNumber, this.craneId, this.oldShipDate, this.manuallySelectedPanelIds[0], this.authService.currentUserSig()?.userName!).subscribe({
          next: (response: ErrorResponse) => {
            if (response.statusCode === HttpStatusCode.Ok || response.statusCode === HttpStatusCode.Created) {
              this.triggerPageReload.emit(true);
            }
          },
          error: (error: HttpErrorResponse) => {
            this.toast.initiate({
              title: error.error.name,
              content: error.error.message,
              type: toastTypes.error,
            });
          }
        });

        this.close();
      }
    });
    this.close();
  }

  switchCrane() {

    this.manuallySelectedPanelIds = JSON.parse(localStorage.getItem("draggedMarkNumbers")!);

    let panelCount: number = this.manuallySelectedPanelIds?.length > 0 ? this.manuallySelectedPanelIds.length : this.draggedLoadNumbers.length;

    let dateMessage: string = `Please select target date for ${panelCount} selected`;
    if (this.manuallySelectedPanelIds?.length > 0) {
      dateMessage += ' panels';
    }
    else if (this.manuallySelectedLoadIds?.length > 0) {
      dateMessage += ' loads';
    }

    var formattedShipDate = formatDate(new Date(this.fieldPlanSummary.shipDate), 'MM-DD-YYYY');
    this.openDateSelectionModal(dateMessage, new Date(formattedShipDate), this.minDate);

    this.bsModalRef?.content.dateSelected.subscribe((currentShipDate: any) => {
      if (currentShipDate !== undefined) {
        let selectedPanels: string[] = [];

        if (this.manuallySelectedPanelIds?.length > 0) {
          selectedPanels = this.manuallySelectedPanelIds;
        }
        else if (this.manuallySelectedLoadIds?.length > 0) {
          selectedPanels = this.manuallySelectedLoadIds;
          // this.manuallySelectedLoadIds.forEach(x => {
          //   console.log('LoadIds', x);
          //   selectedPanels.push(x.toString());

          // } );
        }

        this.fieldPlanDataService.switchCrane(this.jobNumber, this.craneId, currentShipDate, selectedPanels).subscribe({
          next: (response: ErrorResponse) => {
            if (response.statusCode === HttpStatusCode.Ok || response.statusCode === HttpStatusCode.Created) {
              this.triggerPageReload.emit(true);
            }
            else {
              this.toast.initiate({
                title: 'Error',
                content: response.message,
                type: toastTypes.error,
              });
            }
          },
          error: (error: HttpErrorResponse) => {
            this.toast.initiate({
              title: error.error.name,
              content: error.error.message,
              type: toastTypes.error,
            });
          }
        });
        this.close();
      }
    });
  }

  moveCrane() {
    const selectedMark: string = (this.manuallySelectedPanelIds)[this.manuallySelectedPanelIds.length - 1];
    const selectedLoad: number = this.findLoad(selectedMark);

    if (selectedLoad === 0)
      return;

    this.openCraneMoveModal(this.jobNumber, this.craneId, selectedLoad);

    this.bsModalRef?.content.moveCrane.subscribe((craneDelay: FieldPlanCraneMove) => {
      if (craneDelay !== undefined) {
        this.fieldPlanDataService.setCraneDelay(this.jobNumber, this.craneId, selectedLoad, craneDelay.craneMoveDelay).subscribe({
          next: (response: ErrorResponse) => {
            if (response.statusCode === HttpStatusCode.Ok || response.statusCode === HttpStatusCode.Created) {
              this.triggerPageReload.emit(true);
            }
            else {
              this.toast.initiate({
                title: 'Error',
                content: response.message,
                type: toastTypes.error,
              });
            }
          },
          error: (error: HttpErrorResponse) => {
            this.toast.initiate({
              title: error.error.name,
              content: error.error.message,
              type: toastTypes.error,
            });
          }
        });

        this.close();
      }
    });
  }

  openLoadingModal() {
    this.bsModalRef = this.modalService.show(FieldPlanLoadingModalComponent, Object.assign({}, { class: 'modal-lg modal-dialog-centered' }));
  }

  closeLoadingModal() {
    this.modalService.hide();
  }

  openAlertModal(alertMessage: string, okAction: string = '') {
    const initialState: ModalOptions = {
      initialState: {
        list: [alertMessage],
        title: 'Alert',
        okAction: okAction,
        backdrop: 'static',
      },
      backdrop: 'static',
      keyboard: false
    };
    this.bsModalRef = this.modalService.show(AlertModalComponent, initialState);
    this.bsModalRef?.setClass('modal-dialog-centered');
    this.bsModalRef.content.closeBtnName = 'OK';
  }

  openConfirmModal(confirmMessage: string, modalTitle: string, closeBtnName: string, okAction: string) {
    const initialState: ModalOptions = {
      initialState: {
        list: [confirmMessage],
        title: modalTitle,
        okAction: okAction,
        closeBtnName: closeBtnName
      },
      backdrop: 'static',
      keyboard: false
    };
    this.bsModalRef = this.modalService.show(ConfirmModalComponent, initialState);
    this.bsModalRef?.setClass('modal-dialog-centered');
    this.bsModalRef.content.closeBtnName = 'Cancel';
  }

  openDateSelectionModal(dateMessage: string, shipDate: Date, minDate: Date) {
    const initialState: ModalOptions = {
      initialState: {
        dateMessage: dateMessage,
        currentShipDate: shipDate,
        minDate: minDate
      }
    };
    this.bsModalRef = this.modalService.show(DateSelectionModalComponent, initialState);
    this.bsModalRef.content.closeBtnName = 'Cancel';
  }

  openCraneMoveModal(jobNumber: number, craneNumber: number, loadNumber: number) {
    const initialState: ModalOptions = {
      initialState: {
        jobNumber: jobNumber,
        craneNumber: craneNumber,
        loadNumber: loadNumber
      }
    };
    this.bsModalRef = this.modalService.show(CraneMoveModalComponent, initialState);
    this.bsModalRef.content.closeBtnName = 'Cancel';
  }


  // context menu open
  open({ x, y }: MouseEvent, item: any) {
    this.close();

    if (isSummaryDetail(item)) {
      if (!this.checkEditablePanel(item)) return;
    }
    else {
      if (item.mobilization || !this.isEditable) {
        return;
      }
    }

    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        }
      ]);

    let x1 = x;
    let y1 = y;
    this.rightClickItem = item;

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayRef.attach(new TemplatePortal(this.panelMenu, this.viewContainerRef, {
      $implicit: item
    }));

    this.sub = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget);
        }),
        take(1)
      ).subscribe(() => this.close())
  }

  close() {
    this.sub && this.sub.unsubscribe();
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  //Added for Multi-drop functionality
  public dragging: DragRef | undefined;
  public selections: FieldPlanSummaryDetail[] = [];
  @Output() selectionTestChanged = new EventEmitter<any[]>();

  dragStarted(event: CdkDragStart, index: number): void {
    this.hasDragStarted = true;
    console.log('dragStarted', event, index)

    const draggedElement = event.source.element.nativeElement;
    this.placeholderHeight = draggedElement.offsetHeight;
    console.log('dragStarted', this.placeholderHeight)

    //let summaryRow: any = event.event;
    // let parentElement: string = summaryRow.toElement.parentElement.innerText.indexOf("LD Summary") == 0 ? summaryRow.toElement.parentElement.innerText.toUpperCase() : ' ';
    //this.isDroppedInSummaryRow = summaryRow.toElement.innerHTML.toUpperCase() == 'LD SUMMARY'; // || parentElement.includes('LD SUMMARY');

    //if(this.isDroppedInSummaryRow){
    //  this.isDroppedInSummaryRow = true;
    //}
    //console.log('dragStarted--draggedMarkNumbers', this.draggedMarkNumbers)
    //localStorage.setItem('draggedMarkNumbers', JSON.stringify(this.draggedMarkNumbers));
  }

  dragLoadEnded(): void {

  }
  clearEvents(): void {
    this.events = [];
  }

  isSelected(item: FieldPlanSummaryDetail): boolean {

    if (this.isPanelDragDisabled(item)) {
      return false;
    }

    if (item.checkInTimestamp !== null) {
      return false;
    }

    return this.draggedMarkNumbers.indexOf(item.markNum) >= 0;
  }

  isLoadSelected(fieldPlanSummaryDetail: FieldPlanSummaryDetail[] | null): boolean {

    if (fieldPlanSummaryDetail !== null && fieldPlanSummaryDetail[0] !== undefined) {
      //console.log('isLoadSelected', fieldPlanSummaryDetail, fieldPlanSummaryDetail[0])
      return this.draggedLoadNumbers.indexOf(fieldPlanSummaryDetail[0].loadNum) >= 0;
    }
    else {
      return false;
    }

  }

  select(event: any, index: number, item: FieldPlanSummaryDetail) {

    if (FieldPlanDetailComponent.lastRowSelected !== undefined && item) {
      if (FieldPlanDetailComponent.lastRowSelected.expShipDate != item.expShipDate) {
        document.querySelectorAll(".selected").forEach((item) => item.classList.remove("selected"));
      }
    }

    if (event.ctrlKey) {
      this.selectedFieldPlanSummaryItems.unshift(item);
      this.draggedMarkNumbers.push(item.markNum);
      this.manuallySelectedPanelIds.push(item.markNum);
      if (!this.draggedLoadNumbers.includes(item.loadNum)) {
        this.draggedLoadNumbers.push(item.loadNum);
      }
    }
    else if (event.shiftKey) {
      if (item !== null) {
        this.draggedMarkNumbers.push(item.markNum);
        if (!this.draggedLoadNumbers.includes(item.loadNum)) {
          this.draggedLoadNumbers.push(item.loadNum);
        }


        let markSelector = `row_${item.markNum}`

        document.getElementById(markSelector)?.classList.add("selected");

        let lastIndex = 0;
        let firstIndex = 999;

        if (this.draggedMarkNumbers.length >= 2) {

          let elements: any[] = Array.from(document.querySelectorAll("[id^='row_']")); //'[id*="Mobile"]' "[id^='statusMessage_']"
          //let beginIndex = elements.findIndex(s => s.id == `row_${this.draggedMarkNumbers[0]}`);
          //let endIndex = elements.findIndex(s => s.id == `row_${this.draggedMarkNumbers[1]}`);

          this.draggedMarkNumbers.forEach(ln => {
            let ix = elements.findIndex(s => s.id === `row_${ln}` && s.classList.contains("cdk-drag") &&  s.classList.contains("selected"));
            if (ix < firstIndex) {
              firstIndex = ix; ''
            }

            if (ix > lastIndex) {
              lastIndex = ix
            };
          });

          let endIndex = lastIndex;
          let beginIndex = firstIndex;


          const arr = Array.from({ length: endIndex - beginIndex + 1 }, (_, i) => beginIndex + i);
          //this.addLoadSelected(arr[0]);

          arr.forEach((rowIndex, i) => {
            let addMarkNum: string = elements[rowIndex].id.substring(4);
            this.draggedMarkNumbers[i++] = addMarkNum;
          })
        }
      }
    }
    else {
      this.selectedFieldPlanSummaryItems = [];
      this.draggedMarkNumbers = [];
      this.manuallySelectedPanelIds = [];
      this.selectedFieldPlanSummaryItems.unshift(item);
      this.draggedMarkNumbers.push(item.markNum);
      if (!this.draggedLoadNumbers.includes(item.loadNum)) {
        this.draggedLoadNumbers.push(item.loadNum);
      }

      let elements: HTMLCollectionOf<Element> = document.getElementsByClassName("selected");
      elements[0]?.classList.remove("selected");
    }

    FieldPlanDetailComponent.lastRowSelected = item;
    localStorage.setItem('draggedMarkNumbers', JSON.stringify(this.draggedMarkNumbers));
    localStorage.setItem('draggedLoadNumbers', JSON.stringify(this.draggedLoadNumbers));
  }

  selectLoad(event: any, fieldPlanSummaryDetail: FieldPlanSummaryDetail[] | null) {

    //console.log('selectLoad', this.fieldPlanSortable, fieldPlanSummaryDetail, FieldPlanDetailComponent.lastLoadSelected);

    if (FieldPlanDetailComponent.lastLoadSelected !== undefined && fieldPlanSummaryDetail) {
      if (FieldPlanDetailComponent.lastLoadSelected.expShipDate != fieldPlanSummaryDetail[0].expShipDate) {
        if (fieldPlanSummaryDetail) {
          document.querySelectorAll(".selected").forEach((item) => item.classList.remove("selected"));
        }
      }
    }

    this.manuallySelectedPanelIds = [];

    if (event.ctrlKey) {
      if (fieldPlanSummaryDetail !== null) {
        this.draggedLoadNumbers.push(fieldPlanSummaryDetail[0].loadNum);
      }
    }
    else if (event.shiftKey) {
      if (fieldPlanSummaryDetail !== null) {
        this.draggedLoadNumbers.push(fieldPlanSummaryDetail[0].loadNum);

        let loadSelector = `load_${fieldPlanSummaryDetail[0].loadNum}`
        document.getElementById(loadSelector)?.classList.add("selected");

        let elements: any[] = Array.from(document.querySelectorAll("[id^='load_']"));
        //let beginIndex = elements.findIndex(s => s.id == `load_${this.draggedLoadNumbers[0]}` && s.className == "cdk-drag selected");
        //if (beginIndex == -1) { beginIndex = elements.findIndex(s => s.id == `load_${this.draggedLoadNumbers[0]}`); }

        let lastIndex = 0;
        let firstIndex = 999;
        if (this.draggedLoadNumbers.length >= 2) {

          this.draggedLoadNumbers.forEach(ln => {
            let ix = elements.findIndex(s => s.id == `load_${ln}` && s.className == "cdk-drag selected");
            if (ix < firstIndex) {
              firstIndex = ix; ''
            }

            if (ix > lastIndex) {
              lastIndex = ix
            };
          });

          let endIndex = lastIndex;
          let beginIndex = firstIndex;

          //let endIndex = elements.findIndex(s => s.id == `load_${this.draggedLoadNumbers[lastIndex]}` && s.className == "cdk-drag selected");

          const arr = Array.from({ length: endIndex - beginIndex + 1 }, (_, i) => beginIndex + i);

          arr.forEach((rowIndex, i) => {
            let addLoadNum: number = Number(elements[rowIndex].id.substring(5));
            this.draggedLoadNumbers[i] = addLoadNum;
          })
        }
      }
    }
    else {

      if (JSON.parse(localStorage.getItem("draggedLoadNumbers")!) != null) {
        let previousLoadNumber: string[] = JSON.parse(localStorage.getItem("draggedLoadNumbers")!);
        let previousLoadSelector = `load_${previousLoadNumber[0]}`;
        document.getElementById(previousLoadSelector)?.classList.remove("selected");
      }

      this.draggedLoadNumbers = [];
      if (fieldPlanSummaryDetail !== null) {
        let loadSelector = `load_${fieldPlanSummaryDetail[0].loadNum}`;
        document.getElementById(loadSelector)?.classList.add("selected");
        this.draggedLoadNumbers.push(fieldPlanSummaryDetail[0].loadNum);
      }
    }

    //Panels for switch crane
    this.manuallySelectedLoadIds = [];
    this.fieldPlanSortable.sortedLoads.forEach(x => {
      x.fieldPlanSummaryDetail?.forEach(s => {


        if (s.markNum != null && this.draggedLoadNumbers.includes(s.loadNum)) {
          this.manuallySelectedLoadIds.push(s.markNum);
          this.manuallySelectedPanelIds.push(s.markNum);

          if (!this.selectedFieldPlanLoads.includes(s)) {
            this.selectedFieldPlanLoads.push(s);
            //console.log('panelssss', s)
          }
        }
        else if (s.markNum == null && this.draggedLoadNumbers.includes(s.loadNum) && s.summaryRow) {
          //console.log('panelssss summary', s)
          if (!this.selectedFieldPlanLoads.includes(s)) {
            this.selectedFieldPlanLoads.push(s);
          }
        }
        else if (!this.draggedLoadNumbers.includes(s.loadNum)) {
          //console.log('draggedNum', s.loadNum);
        }
      })
    })

    this.draggedLoadNumbers.forEach((x) => {
      this.placeholderHeight += document.getElementById(`load_${x}`)!.offsetHeight;
    })
    // let draggedIndex: number = this.fieldPlanSortable.sortedLoads.findIndex(f => f.loadNum == this.selectedFieldPlanLoads[0].loadNum) - 1;
    // this.draggedToLoadNum = this.fieldPlanSortable.sortedLoads[draggedIndex].loadNum?.toString()!;
    // console.log('loadssss selected', this.draggedToLoadNum, this.selectedFieldPlanLoads);

    if (fieldPlanSummaryDetail !== null) { FieldPlanDetailComponent.lastLoadSelected = fieldPlanSummaryDetail[0]; }

    localStorage.setItem('draggedLoadNumbers', JSON.stringify(this.draggedLoadNumbers));
  }

  clearSelection() {
    this.draggedMarkNumbers = [];
    localStorage.removeItem("draggedMarkNumbers");
    this.draggedLoadNumbers = [];
    localStorage.removeItem("draggedLoadNumbers");
  }

  /** Predicate function that doesn't allow items to be dropped into a list. */
  noReturnPredicate(): boolean {
    return false;
  }

  isPanelDragDisabled(item: FieldPlanSummaryDetail): boolean {
    if (this.authService.hasRole('YardCrane') || this.authService.hasRole('YardForeman')
      || this.authService.hasRole('FabconGeneral') || this.authService.hasRole('3PL')) {
      return true;
    }
    else if (item.loadedTimestamp !== null || item.checkInTimestamp !== null) {
      return true;
    }

    return false;
  }

  isLoadDragDisabled(item: FieldPlanSummaryDetail | null): boolean {
    if (this.authService.hasRole('YardCrane') || this.authService.hasRole('YardForeman')
      || this.authService.hasRole('FabconGeneral') || this.authService.hasRole('3PL')) {
      return true;
    }
    else if (item?.checkOutTimestamp !== null || item.color === '#0000FF') {
      return true;
    }

    return false;
  }
}

function isSummaryDetail(obj: any): obj is FieldPlanSummaryDetail {
  return obj.markNum !== undefined
}

