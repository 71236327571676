import { Ship3plBOLShipInstructions } from './ship3pl-bol-ship-instructions.model';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Shipping3plBol } from './shipping3pl-bol.model';
import { ErrorResponse } from '../error-handling/error-response.model';
import { Ship3plBOLShipUpdateInstructions } from './ship3pl-bol-ship-update-instructions.model';

@Injectable({
  providedIn: 'root'
})
export class Shipping3plBolService {


  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
    this.baseUrl = environment.backend.baseURL;
  }


  get3PlBols(companyIds: number[], job: string): Observable<Shipping3plBol[]> {
    const options = { params: new HttpParams(), HttpHeaders: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    try {
      return this.http.get<Shipping3plBol[]>(`${this.baseUrl}/shipping3plbol/3plBols`,{ params: {companyIds: companyIds, job: job} });;
    } catch (error) {
      console.log(error);
      return new Observable<any>();
    }
  }

  update3plShipInstructions(ship3plBOLShipUpdateInstructions: Ship3plBOLShipUpdateInstructions[]) {
    const options = { params: new HttpParams(), HttpHeaders: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    try {
      return this.http.post<ErrorResponse>(`${this.baseUrl}/shipping3plbol/update3plShipBolInstructions`, ship3plBOLShipUpdateInstructions, options);
    } catch (error) {
      console.log(error);
      return new Observable<any>();
    }
  }

  update3plShipDeliveryInformation(ship3plBOLShipInstructions: Ship3plBOLShipInstructions) {
    const options = { params: new HttpParams(), HttpHeaders: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    try {
      return this.http.post<ErrorResponse>(`${this.baseUrl}/shipping3plbol/update3plShipDeliveryInformation`, ship3plBOLShipInstructions, options);
    } catch (error) {
      console.log(error);
      return new Observable<any>();
    }
  }

  update3plShipBolData(ship3plBOLLoadInfo: Shipping3plBol[]) {
    const options = { params: new HttpParams(), HttpHeaders: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    try {
      return this.http.post<ErrorResponse>(`${this.baseUrl}/shipping3plbol/update3plShipBolData`, ship3plBOLLoadInfo, options);
    } catch (error) {
      console.log(error);
      return new Observable<any>();
    }
  }
}
