import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-field-plan-detail-shipping-modal',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './field-plan-detail-shipping-modal.component.html',
  styleUrl: './field-plan-detail-shipping-modal.component.css'
})
export class FieldPlanDetailShippingModalComponent {
  @Output() confirmAction = new EventEmitter<any>();
  message!: string;
  okAction!: string;
  okActionAndSend!: string;
  constructor(public bsModalRef: BsModalRef) { }

  cancelClicked(): void {
    this.confirmAction.emit('cancel');
  }

  okClicked(): void {
    this.confirmAction.emit(this.okAction);
  }
  okSendClicked(): void {
    this.confirmAction.emit(this.okActionAndSend);
  }
}
