<!-- Begin Trailers List -->
<div class="container-fluid text-left">
  <div class="row">
    <h3>TRAILERS</h3>
  </div>
  <div class="row">
    <div class="col-lg-6 col-md-12">
      <table class="table table-striped table-bordered">
        <thead class="">
          <tr>
            <td class="col-lg-2" style="border-inline-end-color: transparent;">
              <label>Trailer Type</label>
              <select class="form-select" [(ngModel)]="selectedTrailerType">
                <option *ngFor="let trailer of trailerTypes" [ngValue]="trailer.name">
                  {{trailer.name}}
                </option>
              </select>
            </td>
            <td class="col-lg-2" style="border-color: transparent;">
              <label>Plant</label>
              <select #trailerCompanies class="form-select" [(ngModel)]="selectedTrailerCompany">
                <option *ngFor="let company of companies" [ngValue]="company['companyId']">
                  {{company['companyName']}}
                </option>
              </select>
            </td>
            <td class="col-lg-4" style="border-color: transparent;">
              <label>Trailer Name</label>
              <input type="text" placeholder="Enter, Click Save" class="form-control"
                [(ngModel)]="enteredTrailerName" value="enteredTrailerName">
            </td>
            <td class="col-lg-1 col-md-2">
              <button type="button" class="btn btn-primary btn-sm" [disabled]="invalidSearch()"
                (click)='onSearchTrailers()' style="width: 100px;">
                <i class="fa-solid fa-magnifying-glass"></i>
                SEARCH
              </button>
              <button type="button" class="btn btn-success btn-sm mt-2" [disabled]="invalidTrailer()"
                (click)="addTrailerLocation()" style="width: 100px;">
                <i class="fa-solid fa-cloud-arrow-up"></i>
                SAVE
              </button>
            </td>
          </tr>
          <tr>
            <th colspan="4">Trailer Name</th>
          </tr>
        </thead>
        <tbody>
          <div *ngIf="trailers.length==0">No data found based on search criteria</div>
          <tr *ngFor="let trailer of trailers">
            <td >
              <span>{{trailer?.trailerName}}</span>
            </td>
            <td  colspan="3">
              <button type="button" class="btn btn-danger btn-sm" (click)="deleteTrailerLocation(trailer.trailerId)">
                <i class="fa fa-trash-can"></i>
                DELETE
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<!-- End Trailers List -->
