import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, signal } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Shipping3plS3pl } from './shipping3pl-s3pl.model';
import { ErrorResponse } from '../error-handling/error-response.model';
import { Shipping3plCustomLoad } from './shipping3pl-custom-load.model';
import { Shipping3plS3plUpdateTime } from './shipping3pl-s3pl-update-time-model';

@Injectable({
  providedIn: 'root'
})
export class Shipping3plS3plService {
  httpOptions = { params: new HttpParams(), HttpHeaders: new HttpHeaders({ 'Content-Type': 'application/json' }) };
  currentJobNumber = signal<string>('');

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
    this.baseUrl = environment.backend.baseURL;
  }

  get3Pls(job: string): Observable<Shipping3plS3pl[]> {
    try {
      return this.http.get<Shipping3plS3pl[]>(`${this.baseUrl}/shipping3pl/3pls?job=${job}`, this.httpOptions);
    } catch (error) {
      console.log(error);
      return new Observable<any>();
    }
  }

  update3Pls(shipping3Pl: Shipping3plS3plUpdateTime): Observable<ErrorResponse> {
    return this.http.post<ErrorResponse>(`${this.baseUrl}/shipping3pl/update3pls`, shipping3Pl, this.httpOptions);
  }

  add3PlsCustomLoad(shipping3plCustomLoad: Shipping3plCustomLoad): Observable<ErrorResponse> {
    return this.http.post<ErrorResponse>(`${this.baseUrl}/shipping3pl/3plsAddCustomLoad`, shipping3plCustomLoad, this.httpOptions);
  }

  delete3PlsCustomLoad(job: number, jobLoadId: number): Observable<ErrorResponse> {
    return this.http.delete<ErrorResponse>(`${this.baseUrl}/shipping3pl/3plsDeleteCustomLoad/${job}/${jobLoadId}`, this.httpOptions);
  }

  setJobNumber(jobNumber: string){
    this.currentJobNumber.set(jobNumber);
  }
}
