import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ShippingYard } from './shipping-yard.model';
import { ShippingYardTrailer } from './shipping-yard-trailer.model';
import { ShippingYardLocationType } from './shipping-yard-location-type.model';
import { ShippingYardLocation } from './shipping-yard-location.model';
import { ErrorResponse } from '../error-handling/error-response.model';
import { ShippingYardMarkNumberReplacement } from './shipping-yard-marknumberreplacement.model';

const options = { params: new HttpParams(), HttpHeaders: new HttpHeaders({ 'Content-Type': 'application/json' }) };

@Injectable({
  providedIn: 'root'
})

export class ShippingYardService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
    this.baseUrl = environment.backend.baseURL;
  }

  getYards(companyId: number, craneCrewId: number): Observable<ShippingYard[]> {
    try {
      return this.http.get<ShippingYard[]>(`${this.baseUrl}/shippingyard/yards?companyId=${companyId}&&craneCrewId=${craneCrewId}`, options);
    } catch (error) {
      console.log(error);
      return new Observable<any>();
    }
  }

  getYardTrailers(companyId: number, trailerType: string): Observable<ShippingYardTrailer[]> {
    try {
      return this.http.get<ShippingYardTrailer[]>(`${this.baseUrl}/shippingyard/yardtrailers?companyId=${companyId}&&trailerType=${trailerType}`, options);
    } catch (error) {
      console.log(error);
      return new Observable<any>();
    }
  }

  getYardLocationTypes(companyId: number): Observable<ShippingYardLocationType[]> {
    try {
      return this.http.get<ShippingYardLocationType[]>(`${this.baseUrl}/shippingyard/yardlocationtypes?companyId=${companyId}`, options);
    } catch (error) {
      console.log(error);
      return new Observable<any>();
    }
  }

  getYardLocations(companyId: number, locationTypeId: number): Observable<ShippingYardLocation[]> {
    try {
      return this.http.get<ShippingYardLocation[]>(`${this.baseUrl}/shippingyard/yardlocations?companyId=${companyId}&&locationTypeId=${locationTypeId}`, options);
    } catch (error) {
      console.log(error);
      return new Observable<any>();
    }
  }

  updateShipYardLoad(companyId: number, jobLoadId: number, trailerId: string, trailerNumber: string, trailerLocationId: number, carrierName: string, trailerSize: number, loadingType: string) {
    try {
      if (loadingType == null) { loadingType = ''; }
      if (trailerSize == null) {
        return this.http.post<ErrorResponse>(`${this.baseUrl}/shippingyard/updateshipyardload?companyId=${companyId}&&jobLoadId=${jobLoadId}
                                              &&trailerId=${trailerId}&&trailerNumber=${trailerNumber}&&trailerLocationId=${trailerLocationId}&&carrierName=${carrierName}
                                              &&loadingType=${loadingType}`, options);
      }
      else {
        return this.http.post<ErrorResponse>(`${this.baseUrl}/shippingyard/updateshipyardload?companyId=${companyId}&&jobLoadId=${jobLoadId}
                                              &&trailerId=${trailerId}&&trailerNumber=${trailerNumber}&&trailerLocationId=${trailerLocationId}&&carrierName=${carrierName}
                                              &&trailerSize=${trailerSize}&&loadingType=${loadingType}`, options);
      }
    } catch (error) {
      console.log(error);
      return new Observable<any>();
    }
  }

  updateShipYardCustomItem(jobLoadId: number, customItemId: number, loadedOnTrailer: string) {
    try {
      if (loadedOnTrailer.length !== 0) {
        return this.http.post<ErrorResponse>(`${this.baseUrl}/shippingyard/updateshipyardcustomitem?jobLoadId=${jobLoadId}
                                              &&customItemId=${customItemId}&&loadedOnTrailer=${loadedOnTrailer}`, options);
      }
      else {
        return this.http.post<ErrorResponse>(`${this.baseUrl}/shippingyard/updateshipyardcustomitem?jobLoadId=${jobLoadId}
                                              &&customItemId=${customItemId}`, options);
      }
    } catch (error) {
      console.log(error);
      return new Observable<any>();
    }
  }

  updateShipYardPiece(companyId: number, pieceId: number, jobLoadBolPK: number, carrierName: string, trailerSize: number | null, loadingType: string, trailer: string, loadedOnTrailerDateTime: string) {
    try {

      let baseUri = `${this.baseUrl}/shippingyard/updateshipyardpiece?companyId=${companyId}&&pieceId=${pieceId}&&jobLoadBolPK=${jobLoadBolPK}&&carrierName=${carrierName}
      &&loadingType=${loadingType}&&trailer=${trailer}`;

      if (trailerSize != null) {
        baseUri += `&&trailerSize=${trailerSize}`;
      }

      if (loadedOnTrailerDateTime.length !== 0) {
        baseUri += `&&loadedOnTrailer=${loadedOnTrailerDateTime}`;
      }

      return this.http.post<ErrorResponse>(baseUri, options);

    } catch (error) {
      console.log(error);
      return new Observable<any>();
    }
  }

  getYardReplacementNumbers(job: string, mark: string): Observable<ShippingYardMarkNumberReplacement[]> {
    try {
      return this.http.get<ShippingYardMarkNumberReplacement[]>(`${this.baseUrl}/shippingyard/yardmarknumberreplacements?job=${job}&&mark=${mark}`, options);
    } catch (error) {
      console.log(error);
      return new Observable<any>();
    }
  }

  updateMarkNumberReplacements(job: string, oldMark: string, replacementMark: string, oldLoadNum: string, replacementLoadNum: string, oldSequence: string, replacementSequence: string, oldTrailerSequence: string, replacementTrailerSequence: string, oldCrane: number, replacementCrane: number) {
    try {
      return this.http.post<ErrorResponse>(`${this.baseUrl}/shippingyard/updatemarknumberreplacements?job=${job}&&oldMark=${oldMark}&&replacementMark=${replacementMark}&&oldLoadNum=${oldLoadNum}&&replacementLoadNum=${replacementLoadNum}
                                              &&oldSequence=${oldSequence}&&replacementSequence=${replacementSequence}&&oldTrailerSequence=${oldTrailerSequence}&&replacementTrailerSequence=${replacementTrailerSequence}&&oldCrane=${oldCrane}&&replacementCrane=${replacementCrane}`, options);
    } catch (error) {
      console.log(error);
      return new Observable<any>();
    }
  }
}
