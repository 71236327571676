<!-- Begin Alert Modal-->
<div class="modal" tabindex="-1" id="bolAlertModal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Error during update</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
          (click)="closeBolAlertModal()"></button>
      </div>
      <div class="modal-body">
        <label id="errorMessage"></label>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="closeBolAlertModal()">OK</button>
      </div>
    </div>
  </div>
</div>
<!-- End Alert Modal-->

<!-- Begin Edit Delivery Information Modal -->
<div class="modal fade" id="editDeliveryAddressModal" tabindex="-1" aria-labelledby="editDeliveryAddressModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-sm" style="--bs-modal-width: 350px;">
    <div class="modal-content">
      <div class="modal-header" style="display: block;">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
          style="display: block;"></button>
        <h1 class="modal-title fs-5" id="editDeliveryAddressModalLabel">Delivery Address</h1>
        <label class="form-label">Please enter any information about the Job</label>
      </div>
      <form (ngSubmit)="validateAddress() && saveDeliveryInfo()" class="modal-body needs-validation" novalidate>
        <div class="mb-3 position-relative">
          <label for="deliveryAddress" class="form-label">Address</label>
          <input type="text" class="form-control" id="deliveryAddress" name="deliveryAddress"
            aria-describedby="deliveryAddress deliveryAddressFeedback" [(ngModel)]="deliveryInformation.address1"
            required autocomplete="nope">
          <div id="deliveryAddressFeedback" class="invalid-feedback">
            Please enter Address
          </div>
        </div>
        <div class="mb-3 col-sm-8">
          <label for="deliveryCity" class="form-label">City</label>
          <input type="text" class="form-control" id="deliveryCity" name="deliveryCity"
            aria-describedby="deliveryCity deliveryCityFeedback" [(ngModel)]="deliveryInformation.city" required>
          <div id="deliveryCityFeedback" class="invalid-feedback">
            Please enter City
          </div>
        </div>
        <div class="mb-3 col-sm-6">
          <label for="deliveryState" class="form-label">State</label>
          <input type="text" class="form-control" id="deliveryState" name="deliveryState"
            aria-describedby="deliveryState deliveryStateFeedback" [(ngModel)]="deliveryInformation.state" required>
          <div id="deliveryStateFeedback" class="invalid-feedback">
            Please enter State
          </div>
        </div>
        <div class="mb-3 col-sm-6">
          <label for="deliveryZip" class="form-label">Zip</label>
          <input type="text" class="form-control" id="deliveryZip" name="deliveryZip"
            aria-describedby="deliveryZip deliveryZipFeedback" [(ngModel)]="deliveryInformation.zip" required>
          <div id="deliveryZipFeedback" class="invalid-feedback">
            Please enter Zip
          </div>
        </div>
        <div class="mb-3 col-sm-6">
          <label for="holidayPlant" class="form-label">Country</label>
          <select class="form-select" aria-label="Default select example" name="deliveryCountry" required>
            <option *ngFor="let country of countries" [value]="country.value"
              [selected]="deliveryInformation.country==country.value">
              {{country.text}}
            </option>
          </select>
          <div id="holidayPlantFeedback" class="invalid-feedback">
            Please select a Country
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger me-md-2" data-bs-dismiss="modal">Cancel</button>
          <button type="submit" class="btn btn-primary" type="submit" data-bs-dismiss="modal">Save</button>
        </div>
      </form>
    </div>
  </div>
</div>
<!-- End Edit Delivery Information Modal -->

<!-- Begin Shipping 3PL BOL List -->
<div class="row mt-2">
  <div class="col-lg-8 position-relative">
    <h3>SHIPPING 3PL BOL</h3>
    <div class="row">
      <div class="col-lg-2 col-md-2 mt-2">
        <input type="text" placeholder="Enter Job" class="form-control" [(ngModel)]="enteredJob" value="enteredJob"
          (click)="clearJobNumber()" (keydown.enter)="onSearchBols()">
      </div>
      <div class="col-auto mt-2">
        <button type="button" class="btn btn-primary btn-sm" [disabled]="invalidSearch()" (click)='onSearchBols()'>
          <i class="fa-solid fa-magnifying-glass"></i>
          SEARCH
        </button>
      </div>
      <div class="col-auto mt-2">
        <button type="button" class="btn btn-success btn-sm" [disabled]="!isEditable" (click)="updateBol()">
          <i class="fa-solid fa-square-plus"></i>
          SAVE
        </button>
      </div>
      <div class="col-auto mt-2">
        <button type="button" class="btn btn-danger btn-sm" [disabled]="!isEditable" (click)="showCancelModal(cancelTemplate)">
          <i class="fa-solid fa-circle-xmark"></i>
          Cancel
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-4 mt-2">
        <div *ngIf="shipping3plBols.length==0 && hasPerformedSearch" role="alert">No
          data found based on search criteria</div>
      </div>
    </div>
  </div>
  <div class="col-lg-8 col-md-7 stickyContainer">
    <table class="table table-bordered table-striped table-hover table-sm stickyBorderBottom">
      <thead class="table-light sticky-top top-0">
        <tr>
          <th class="text-nowrap text-center stickyBorder"><input type="checkbox" [value]="selectAllJobs"
              [checked]="selectAllJobs" [(ngModel)]="selectAllJobs" (click)="setSelectedBol(-1)"></th>
          <th class="text-nowrap stickyBorder h6" (click)="setOrder('deliveryDate_Display')">Del. Date <span
              *ngIf="order === 'deliveryDate_Display'" [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
            <span *ngIf="order === 'deliveryDate_Display'" [hidden]="!reverse"><i
                class="fa-solid fa-sort-up"></i></span>
            <span *ngIf="order !== 'deliveryDate_Display'"><i class="fa-solid fa-sort"></i></span>
          </th>
          <th class="text-nowrap stickyBorder h6" (click)="setOrder('expectedPickupDate_Display')">Exp. Pickup <span
              *ngIf="order === 'expectedPickupDate_Display'" [hidden]="reverse"><i
                class="fa-solid fa-sort-down"></i></span>
            <span *ngIf="order === 'expectedPickupDate_Display'" [hidden]="!reverse"><i
                class="fa-solid fa-sort-up"></i></span>
            <span *ngIf="order !== 'expectedPickupDate_Display'"><i class="fa-solid fa-sort"></i></span>
          </th>
          <th class="text-nowrap stickyBorder h6" (click)="setOrder('bol')">BOL # <span *ngIf="order === 'bol'"
              [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
            <span *ngIf="order === 'bol'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
            <span *ngIf="order !== 'bol'"><i class="fa-solid fa-sort"></i></span>
          </th>
          <th class="text-nowrap stickyBorder h6" (click)="setOrder('carrierName')">Carrier <span
              *ngIf="order === 'carrierName'" [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
            <span *ngIf="order === 'carrierName'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
            <span *ngIf="order !== 'carrierName'"><i class="fa-solid fa-sort"></i></span>
          </th>
          <th class="text-nowrap stickyBorder h6" (click)="setOrder('statusText')">Trailer Size <span
              *ngIf="order === 'statusText'" [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
            <span *ngIf="order === 'statusText'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
            <span *ngIf="order !== 'statusText'"><i class="fa-solid fa-sort"></i></span>
          </th>
          <th class="text-nowrap stickyBorder h6" (click)="setOrder('trailerSize')">Trailer <span
              *ngIf="order === 'trailerSize'" [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
            <span *ngIf="order === 'trailerSize'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
            <span *ngIf="order !== 'trailerSize'"><i class="fa-solid fa-sort"></i></span>
          </th>
          <th class="text-nowrap stickyBorder h6" (click)="setOrder('loadingType')">Load Type <span
              *ngIf="order === 'loadingType'" [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
            <span *ngIf="order === 'loadingType'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
            <span *ngIf="order !== 'loadingType'"><i class="fa-solid fa-sort"></i></span>
          </th>
          <th class="text-nowrap stickyBorder h6" (click)="setOrder('instructions')">Special Instructions <span
              *ngIf="order === 'instructions'" [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
            <span *ngIf="order === 'instructions'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
            <span *ngIf="order !== 'instructions'"><i class="fa-solid fa-sort"></i></span>
          </th>
          <th class="text-nowrap stickyBorder">Load Window</th>
          <th class="text-nowrap stickyBorder">Load #</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let shippingBol of shipping3plBols" (paste)="onPaste($event, shippingBol)">
          <td class="text-center"><input type="checkbox" [value]="shippingBol.isChecked"
              [checked]="shippingBol.isChecked" (click)="setSelectedBol(shippingBol.jobLoadBOLID)"></td>
          <td>
            <label for="" class="col-form-label
          col-form-label-sm">{{shippingBol?.deliveryDate_Display}}</label>
          </td>
          <td>
            <input type="text" class="form-control form-control-sm col-sm-2" bsDatepicker
              [bsConfig]="{ containerClass: 'theme-dark-blue', dateInputFormat: 'MM/DD/YYYY', selectFromOtherMonth: true, showWeekNumbers:false }"
              [(ngModel)]="shippingBol.expectedPickupDate_Display" name="expectedPickupDateDisplay"
              (ngModelChange)="editBol(shippingBol)" style="width: 90px;" autocomplete="off">
            <div id="expectedPickupDateDisplay" class="invalid-feedback">
              Please select a date.
            </div>
          </td>
          <td>
            <input type="text" class="form-control form-control-sm" name="shippingBol" style="width: 80px;"
              aria-describedby="shippingBol" [(ngModel)]="shippingBol.bol"
              (ngModelChange)="editBol(shippingBol)" autocomplete="off">
          </td>
          <td>
            <input type="text" class="form-control form-control-sm" name="carrierName" style="width: 80px;"
              aria-describedby="carrierName" [(ngModel)]="shippingBol.carrierName"
              (ngModelChange)="editBol(shippingBol)" autocomplete="off">
          </td>
          <td>
            <input type="number" class="form-control form-control-sm" name="trailerSize" style="width: 80px;"
              aria-describedby="trailerSize" [(ngModel)]="shippingBol.trailerSize"
              (ngModelChange)="editBol(shippingBol)" autocomplete="off">
          </td>
          <td>
            <input type="text" class="form-control form-control-sm" name="trailer" style="width: 80px;"
              aria-describedby="trailer" [(ngModel)]="shippingBol.trailer"
              (ngModelChange)="editBol(shippingBol)" autocomplete="off">
          </td>
          <td>
            <input type="text" class="form-control form-control-sm" name="loadingType" style="width: 80px;"
              aria-describedby="loadingType" [(ngModel)]="shippingBol.loadingType"
              (ngModelChange)="editBol(shippingBol)" autocomplete="off">
          </td>
          <td>
            <input type="text" class="form-control form-control-sm" name="instructions" aria-describedby="instructions"
              [(ngModel)]="shippingBol.instructions" (ngModelChange)="editBol(shippingBol)"
              style="width: 300px;" autocomplete="off">
          </td>
          <td>
            <select #loadWindow class="form-select" [(ngModel)]="shippingBol.loadWindowID"
              (ngModelChange)="editBol(shippingBol)" style="width: 125px;">
              <option *ngFor="let loadWindow of loadWindows" [ngValue]="loadWindow['loadWindowId']">
                {{loadWindow['loadWindowDesc']}}
              </option>
            </select>
          </td>
          <td>
            <select #loadNum class="form-select" [(ngModel)]="shippingBol.loadNum_Display"
              (ngModelChange)="editBolWithLoadNumber(shippingBol)" style="width:105px;">
                <option *ngFor="let loadNumber of shippingBol.ship3PlBOLLoadNumberInfos" [ngValue]="loadNumber.loadNum">
                  {{loadNumber.loadDescriptor}}
                </option>
            </select>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="col-lg-2 col-md-4 border" style="height: 450px">
    <div class="mt-2 row">
      <div class="col">
        <h5 class="mb-1">Ship Days: </h5>
      </div>
      <div class="col">
        <select class="form-select" [(ngModel)]="selectedShipDay">
          <option *ngFor="let ship of shipDays" [value]="ship.value">
            {{ship.text}}
          </option>
        </select>
      </div>
    </div>
    <div class="mt-2">
      <button type="button" class="btn btn-danger btn-sm" [disabled]="!hasSelectedBols"
      (click)="saveExpectedShipDates()">
        <i class="fa-regular fa-square-check"></i>
        APPLY TO SELECTED
      </button>
    </div>
    <hr />
    <div class="mt-2">
      <h5 class="mb-1">Delivery Address: </h5>
      <div *ngIf="deliveryInformation.address1 != ''">{{ deliveryInformation.address1 }}</div>
      <div *ngIf="deliveryInformation.city != ''">{{ deliveryInformation.city + ', ' + deliveryInformation.state + ' '
        + deliveryInformation.zip }}
      </div>
    </div>
    <div class="mt-2 mb-2">
      <button type="button" class="btn btn-success btn-sm" data-bs-toggle="modal"
        data-bs-target="#editDeliveryAddressModal" [disabled]="deliveryInformation.address1 == ''">
        <i class="fa-solid fa-square-plus"></i>
        EDIT DELIVERY INFORMATION
      </button>
    </div>
    <hr />
    <div class="mt-2">
      <h5 class="mb-1">Special Instructions: </h5>
      <textarea rows="4" cols="35" [(ngModel)]="deliveryInformation.specialInstructions"></textarea>
      <button type="button" class="btn btn-success btn-sm"
        [disabled]="!hasSelectedBols || deliveryInformation.specialInstructions == ''"
        (click)="applyInstructionsToSelected(deliveryInformation.specialInstructions)">
        <i class="fa-solid fa-square-plus"></i>
        APPLY TO SELECTED
      </button>
    </div>
  </div>
</div>
<!-- End Shipping 3PL BOL List -->

<ng-template #cancelTemplate>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Cancel Changes</h4>
  </div>
  <div class="modal-body text-center">
    <p>Changes have been made & will be lost. Do you want to cancel?</p>
    <button type="button" class="btn btn-success me-2" (click)="cancelEdit()">Yes</button>
    <button type="button" class="btn btn-primary" (click)="bsModalRef?.hide();" >No</button>
  </div>
</ng-template>
