<div style="height: 46px; overflow-y: auto; font-size: small;">
  <table class="table table-bordered" style="margin-bottom: 0px; border: 1px solid #ddd">
    <tr *ngFor="let summary of dailySummaries">
          <td style="padding: 1px;">{{summary.job}}</td>
          <td style="padding: 1px;">Net - {{summary.totalLoads}}</td>
          <td style="padding: 1px;">Snt - {{summary.sentLoads}}</td>
          <td style="padding: 1px;">Rem - {{summary.remainingLoads}}</td>
      </tr>
  </table>
</div>
