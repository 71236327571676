import { Router } from '@angular/router';
import { Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { AdminHolidayScheduleService } from 'src/app/admin-holiday-schedule/admin-holiday-schedule.service';
import { AuthService } from 'src/app/authentication/auth.service';
import { environment } from 'src/environments/environment';
import { Company } from './company-model';
import { ReloadRouteService } from 'src/app/reload-route.service ';

@Component({
  selector: 'app-company-drop-down',
  templateUrl: './company-drop-down.component.html',
  styleUrl: './company-drop-down.component.css'
})
export class CompanyDropDownComponent implements OnInit {
  companies!: Company[];
  adminHolidayScheduleService: AdminHolidayScheduleService = inject(AdminHolidayScheduleService);
  authService = inject(AuthService);
  reloadRouteService = inject(ReloadRouteService);

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.adminHolidayScheduleService.getCompanies().subscribe(result => {

      this.companies = result;
      this.onSelected();

    }, error => console.error(error));
  }

  onSelected(): void {
    this.authService.retrievePermission(this.authService.currentUserSig()!, false);
    let companyCode = this.companies.find(x => x.companyId == this.authService.currentUserSig()!.companyId)?.companyCode!;
    this.authService.currentUserSig()!.companyCode = companyCode;
      this.authService.currentUserSig()!.userCompanies = [];
      this.authService.currentUserSig()!.userCompanies.push(...this.companies);

    this.authService.setLocalStorage();

    this.reloadRouteService.redirectTo(this.router.url.split('?')[0]);
  }
}
