export class ShippingOfficeCheckInLoad {

  constructor(
    public jobLoadId: number | null,
    public isPreLoad: boolean,
    public deliveryDateTime: Date,
    public loadNumber: string,
    public bolNum: string,
    public missingItems: number,
    public qaIssuePanels: number,
    public width: number,
    public isPreLoadComplete: boolean,
    public isQAIssue: boolean,
    public selectText: string,
  ) {  }

}
