import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from './auth.service';

export const customInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService);

  const yardMapToken = authService.currentUserSig()?.token;
  const cloneRequest = req.clone({
    setHeaders:{
      Authorization: `Bearer ${yardMapToken}`
    }
  });

  return next(cloneRequest);
};
