import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CraneCrew } from './crane-crew.model';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommonDataService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
    this.baseUrl = environment.backend.baseURL;
  }


  getCraneCrews(companyId: number): Observable<CraneCrew[]> {
    const options = { params: new HttpParams(), HttpHeaders: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    try {
      return this.http.get<CraneCrew[]>(`${this.baseUrl}/admin/cranecrews?companyId=${companyId}`, options);
    } catch (error) {
      console.log(error);
      return new Observable<any>();
    }
  }
}
