import { ShippingOfficeCheckoutComponent } from './shipping-office-checkout/shipping-office-checkout.component';
import { Component, EventEmitter, Inject, LOCALE_ID, OnDestroy, OnInit, Output, TemplateRef, ViewChild, inject } from '@angular/core';
import { formatDate } from '@angular/common';
import { ShippingOfficeService } from './shipping-office.service';
import { environment } from 'src/environments/environment';
import { HolidaySchedule } from '../admin-holiday-schedule/admin-holiday-schedule.model';
import { ToastService } from '../toast/toast.service';
import { ShippingOfficeBolSearch } from './shipping-office-bol-search.model';
import { ShippingOffice } from './shipping-office.model';
import { ShippingOfficeCheckinService } from './shipping-office-checkin/shipping-office-checkin.service';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ShippingOfficeCheckinComponent } from './shipping-office-checkin/shipping-office-checkin.component';
import SignaturePad from 'signature_pad';
import { AuthService } from '../authentication/auth.service';
import { OrderPipe } from "../controls/order-pipe/order.pipe";
import { LoadingModalComponent } from '../controls/loading-modal/loading-modal.component';

@Component({
  selector: 'app-shipping-office',
  templateUrl: './shipping-office.component.html',
  styleUrl: './shipping-office.component.css'
})

export class ShippingOfficeComponent implements OnInit {

  title = 'Signature Pad by Rajesh Gami';
  signPad: any;
  @ViewChild('signPadCanvas', { static: false }) signaturePadElement: any;
  //signImage:any;

  bsModalRef?: BsModalRef;
  shippingOfficeCheckinService: ShippingOfficeCheckinService = inject(ShippingOfficeCheckinService);
  shippingOfficeService: ShippingOfficeService = inject(ShippingOfficeService);
  offices: ShippingOffice[] = [];
  modelBolSearch = new ShippingOfficeBolSearch([], new Date().toLocaleString('en-US', {
    month: '2-digit', day: '2-digit', year: 'numeric'
  }), '',);
  checkoutBolLoadInfoLabel: string = '';
  authService = inject(AuthService);
  order: string = "job";
  reverse: boolean = true;
  isLoading: boolean = false;
  hasPerformedSearch: boolean = false;
  propertyName: string = '';
  timeout: any;

  constructor(@Inject(LOCALE_ID) private locale: string, private toast: ToastService, private modalService: BsModalService) { }

  ngOnInit() {
    this.searchBol();
  }

  ngOnDestroy(){
    clearTimeout(this.timeout);
  }

  searchBol(): void {

    this.isLoading = true;
    this.hasPerformedSearch = false;

    try {
        this.modelBolSearch.companyIds = [];
        if(this.authService.currentUserSig()!.companyCode.split(',').length == 1){
          let companyId: number = this.authService.currentUserSig()!.userCompanies.find(x => x.companyCode == this.authService.currentUserSig()!.companyCode)?.companyId!;
          this.modelBolSearch.companyIds.push(companyId);
        }
        else if(this.authService.currentUserSig()!.companyCode.split(',').length > 1){
          this.modelBolSearch.companyIds = this.authService.currentUserSig()!.userCompanies.map(({ companyId }) => companyId);
        }

        if (this.modelBolSearch.expectedPickupDate !== undefined && this.modelBolSearch.bolNumber?.length == 0) {
          this.modelBolSearch.expectedPickupDate = new Date(this.modelBolSearch.expectedPickupDate!).toLocaleString('en-US', {
            month: '2-digit', day: '2-digit', year: 'numeric'
          });
        }
        else {
          this.modelBolSearch.expectedPickupDate = null;
        }

        if (this.modelBolSearch.bolNumber?.length == 0) { this.modelBolSearch.bolNumber = ''; }

        try {
          this.shippingOfficeService.getOffices(this.modelBolSearch).subscribe(result => {
            result.forEach((shippingOffice: ShippingOffice) => {
              this.updateProperties(shippingOffice);
            });

            this.offices = result;
            if (this.offices.length > 0) {
              this.modelBolSearch.expectedPickupDate = new Date(this.offices[0].expectedPickupDate).toLocaleString('en-US', {
                month: '2-digit', day: '2-digit', year: 'numeric'
              });
              this.hasPerformedSearch = true;
            }
            else {
              this.hasPerformedSearch = true;
            }

            if (this.propertyName != '') {
              this.reverse = !this.reverse;
              this.setOrder(this.propertyName);
            }

            this.isLoading = false;

            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
              this.searchBol();
               }, 30000)

          }, error => console.error(error));
        } catch (error) {
          console.log(error)
          this.isLoading = false;
        }
    } catch (error) {
      console.log(error)
      this.isLoading = false;
    }
  }

  private updateProperties(shippingOffice: ShippingOffice) {
    if (!(shippingOffice.checkInTimestamp != null || shippingOffice.loadedDateTime != null)) {
      shippingOffice.statusText = '';
      shippingOffice.statusImage = '';
    }
    else if (shippingOffice.checkOutTimestamp != null) {
      shippingOffice.statusText = 'Load has shipped';
      shippingOffice.statusImage = '/assets/images/LongTruck.png';
    } else if (shippingOffice.missingItems + shippingOffice.missingPanels == 0) {
      shippingOffice.statusText = 'Loading complete';
      shippingOffice.statusImage = '/assets/images/LoadedTruck.png';
    } else {
      shippingOffice.statusText = 'Ready to be loaded';
      shippingOffice.statusImage = '/assets/images/MiJack.png';
    }

    shippingOffice.canCheckIn = (shippingOffice.bolNum != null && shippingOffice.checkInTimestamp == null);

    shippingOffice.canCheckOut = (shippingOffice.bolNum != null &&
      shippingOffice.checkInTimestamp != null &&
      shippingOffice.checkOutTimestamp == null &&
      shippingOffice.missingItems + shippingOffice.missingPanels == 0);

    shippingOffice.times = (shippingOffice.checkInTimestamp != null ? 'IN: ' + formatDate(shippingOffice.checkInTimestamp, 'HH:mm', this.locale) : '') +
      (shippingOffice.loadedDateTime != null ? '<br />LD: ' + formatDate(shippingOffice.loadedDateTime, 'HH:mm', this.locale) : '') +
      (shippingOffice.checkOutTimestamp != null ? '<br />OUT: ' + formatDate(shippingOffice.checkOutTimestamp, 'HH:mm', this.locale) : '');

    shippingOffice.canPrintBol = (shippingOffice.checkOutTimestamp != null);
  }

  public invalidSearch(): boolean {

    if (this.authService.currentUserSig()!.companyId == 0) { return true; }

    if ((this.modelBolSearch.bolNumber !== null && this.modelBolSearch.bolNumber.length > 0) || this.modelBolSearch.expectedPickupDate !== undefined) {
      return false;
    }

    return true;
  }

  public clearSearch(): void {
    this.modelBolSearch = new ShippingOfficeBolSearch([], new Date().toLocaleString('en-US', {
      month: '2-digit', day: '2-digit', year: 'numeric'
    }), '',);

    this.offices = [];
  }

  printBOL(shippingOffice: ShippingOffice) {
    this.shippingOfficeService.getbolReport(shippingOffice.job, shippingOffice.loadNum!).subscribe(file => {

      let fileName = file.headers.get('content-disposition')?.split(';')[1].split('=')[1];
      let blob: Blob = file.body as Blob;
      let a = document.createElement('a');
      a.download = fileName;
      a.href = window.URL.createObjectURL(blob);
      a.click();

    }, error => console.error(error));
  }

  openCheckInModalWithComponent(shippingOffice: ShippingOffice) {

    const okAction = 'officeCheckInRefreshPage';
    const initialState: ModalOptions = {
      initialState: {
        title: 'Check-in',
        selectedOffice: shippingOffice,
        okAction: okAction
      }
    };
    this.bsModalRef = this.modalService.show(ShippingOfficeCheckinComponent, initialState);
    this.bsModalRef.content.closeBtnName = 'Close';

    this.bsModalRef?.content.confirmAction.subscribe((action: string) => {

      if (action === okAction) {
        //Need to wait for the CheckIn modal to close.
        this.sleep(200).then(() => {
          this.searchBol();
        });
      }
    });
  }

  openCheckOutModalWithComponent(shippingOffice: ShippingOffice) {
    const okAction = 'officeCheckOutRefreshPage';
    const initialState: ModalOptions = {
      initialState: {
        title: 'Check-out',
        selectedOffice: shippingOffice,
        class: 'modal-lg',
        okAction: okAction
      }
    };
    this.bsModalRef = this.modalService.show(ShippingOfficeCheckoutComponent, initialState);
    this.bsModalRef.content.closeBtnName = 'Close';

    this.bsModalRef?.content.confirmAction.subscribe((action: string) => {

      if (action === okAction) {
        //Need to wait for the CheckOut modal to close.
        this.sleep(200).then(() => {
          this.searchBol();
        });
      }
    });
  }

  ngAfterViewInit() {
    if (this.signaturePadElement != undefined) {
      this.signPad = new SignaturePad(this.signaturePadElement.nativeElement);
    }
  }
  /*It's work in devices*/
  startSignPadDrawing(event: Event) {
    console.log(event);
  }
  /*It's work in devices*/
  movedFinger(event: Event) {
  }
  /*Undo last step from the signature*/
  undoSign() {
    const data = this.signPad.toData();
    if (data) {
      data.pop(); // remove the last step
      this.signPad.fromData(data);
    }
  }
  /*Clean whole the signature*/
  clearSignPad() {
    this.signPad.clear();
  }
  /*Here you can save the signature as a Image*/
  saveSignPad() {
    const base64ImageData = this.signPad.toDataURL();
    // this.signImage = base64ImageData;
    //Here you can save your signature image using your API call.
  }

  setOrder(value: string) {

    this.propertyName = value;

    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }

  openSearchModal(message: string) {
    const initialState: ModalOptions = {
      initialState: {
        message: message
      }
    };

    this.modalService.show(LoadingModalComponent, Object.assign(initialState, { class: 'modal-lg modal-dialog-centered' }));
  }

  closeSearchModal() {
    this.modalService.hide();
  }

  sleep(ms: number | undefined) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}

