import { ShippingOfficeCheckinComponent } from './../shipping-office-checkin/shipping-office-checkin.component';
import { ShippingOfficeCheckoutChecklist } from './shipping-office-checkout-checklist.model';
import { Component, OnInit, ViewChild, TemplateRef, inject, Output, EventEmitter, LOCALE_ID, Inject } from '@angular/core';
import { ShippingOfficeCheckoutService } from './shipping-office-checkout.service';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ShippingOffice } from '../shipping-office.model';
import { HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { ToastService, toastTypes } from 'src/app/toast/toast.service';
import { ErrorResponse } from 'src/app/error-handling/error-response.model';
import { WriteSignaturePadComponent } from 'src/app/write-signature-pad/write-signature-pad.component';
import { formatDate } from '@angular/common';
import { getDate } from 'ngx-bootstrap/chronos/utils/date-getters';

@Component({
  selector: 'app-shipping-office-checkout',
  templateUrl: './shipping-office-checkout.component.html',
  styleUrl: './shipping-office-checkout.component.css'
})
export class ShippingOfficeCheckoutComponent implements OnInit {
  @ViewChild(WriteSignaturePadComponent)
  private signaturePadComponent!: WriteSignaturePadComponent;
  bsModalRef?: BsModalRef;
  selectedOffice!: ShippingOffice;
  checkoutService: ShippingOfficeCheckoutService = inject(ShippingOfficeCheckoutService);
  checklists: ShippingOfficeCheckoutChecklist [] =[];
  public checkoutBolLoadInfoLabel: string = '';
  shippingOfficeCheckinService: any;
  hideLoadMessage: boolean = false;
  hideValidationMessage: boolean = false;
  @Output() confirmAction = new EventEmitter<any>();
  okAction!: string;

  constructor(@Inject(LOCALE_ID) private locale: string, private toast: ToastService, private modalService: BsModalService) {}

  ngOnInit(): void {

    try {
      this.checkoutService.getCheckLists(this.selectedOffice.jobLoadId ?? 0).subscribe(result => {
        result.forEach((checklist: ShippingOfficeCheckoutChecklist) => {
          checklist.isInspected = checklist.shipOfficeInspectionTime != null;
        });

        this.checklists = result;

        //Setting the Canvas width here since it is in a modal.
        var canvas = document.querySelector("canvas");
        this.signaturePadComponent.handleSignatureCanvasSize(canvas!)

      }, error => console.error(error));
    } catch (error) {
      console.log(error)
    }
  }

  closeCheckOutModal(modalId?: number) {
    this.modalService.hide(modalId);
  }

  togglePieceVerified(checklistItem: ShippingOfficeCheckoutChecklist) {
    checklistItem.isInspected = !checklistItem.isInspected;

   this.hideLoadMessage = this.checklists.some(x => x.isInspected == true);

  }
  openModal(template: TemplateRef<void>) {
    this.bsModalRef = this.modalService.show(template);
  }

  saveShippingOfficeCheckOut() {

    this.signaturePadComponent.saveSignPad();

    this.selectedOffice.signatureImage = this.signaturePadComponent.SignImage

    this.selectedOffice.updateCheckInTimestamp = formatDate(this.selectedOffice.checkInTimestamp ?? new Date(), 'M/d/YYYY HH:mm:ss', this.locale);
    this.selectedOffice.updateCheckOutTimestamp = formatDate(new Date(), 'M/d/YYYY HH:mm:ss', this.locale);

    //Need to do a conversion here so we don't go back GMT 5.
    let checkinTimestamp: Date = new Date(this.selectedOffice.checkInTimestamp ?? new Date());
    this.selectedOffice.checkInTimestamp = checkinTimestamp;

    this.checkoutService.updateShippingOfficeCheckOut(this.selectedOffice).subscribe((response: ErrorResponse) => {
      this.closeCheckOutModal();

      this.toast.initiate({
        title: response.name,
        content: response.message,
        type: response.statusCode == 200 || response.statusCode == 201 ? toastTypes.success : toastTypes.error,
      });

      this.confirmAction.emit(this.okAction);

    }, (error: HttpErrorResponse) => {
      this.toast.initiate({
        title: error.error.name,
        content: error.error.message,
        type: toastTypes.error,
      });
    });
  }
}
