import { Injectable, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class UserPreferencesService implements OnInit {

  userPrefs: any;
  userPrefInputs: any = {};
  constructor(private cookieService: CookieService) {
    
  }

  ngOnInit(): void {
    this.getUserPreferences;   
  }

  //get the preferences from the cookie
  public getUserPreferences(): any{
    this.userPrefs = this.cookieService.get('FieldPlanPrefs');
    if (this.userPrefs === null || this.userPrefs === "") {
      this.userPrefs = "status|mark|loadNum|minPnl|width|plant|weight|weight|wall|type";
      var formmatedUserPrefs = this.formatInputPreps(this.userPrefs);
      this.setUserPreferences(formmatedUserPrefs);
      this.userPrefs = this.cookieService.get('FieldPlanPrefs');
    }
    return this.userPrefs;
  }

  //this takes in a string of userPreferences and splits them into the input fields
  public formatInputPreps(userPrefs: string): any {
    this.userPrefInputs = {};

    this.userPrefInputs.status = userPrefs == null || userPrefs.indexOf('status') >= 0;
    this.userPrefInputs.mark = userPrefs == null || userPrefs.indexOf('mark') >= 0;
    this.userPrefInputs.loadNum = userPrefs == null || userPrefs.indexOf('loadNum') >= 0;
    this.userPrefInputs.minPnl = userPrefs == null || userPrefs.indexOf('minPnl') >= 0;
    this.userPrefInputs.width = userPrefs == null || userPrefs.indexOf('width') >= 0;
    this.userPrefInputs.plant = userPrefs == null || userPrefs.indexOf('plant') >= 0;
    this.userPrefInputs.weight = userPrefs == null || userPrefs.indexOf('weight') >= 0;
    this.userPrefInputs.length = userPrefs == null || userPrefs.indexOf('weight') >= 0;
    this.userPrefInputs.wall = userPrefs == null || userPrefs.indexOf('wall') >= 0;
    this.userPrefInputs.type = userPrefs == null || userPrefs.indexOf('type') >= 0;

    // Hide summary row defaults to false when no prefs are yet set
    this.userPrefInputs.hideSummaryRow = userPrefs != null && userPrefs.indexOf('hideSummaryRow') >= 0;
    return this.userPrefInputs;
  }

  //get the data from the inputs and put it into a string and set the cookie
  public setUserPreferences(userInputs: any): any {
    this.userPrefs = '';

    this.userPrefs += (userInputs.status ? 'status|' : '');
    this.userPrefs += (userInputs.mark ? 'mark|' : '');
    this.userPrefs += (userInputs.loadNum ? 'loadNum|' : '');
    this.userPrefs += (userInputs.minPnl ? 'minPnl|' : '');
    this.userPrefs += (userInputs.width ? 'width|' : '');
    this.userPrefs += (userInputs.plant ? 'plant|' : '');
    this.userPrefs += (userInputs.weight ? 'weight|' : '');
    this.userPrefs += (userInputs.length ? 'length|' : '');
    this.userPrefs += (userInputs.wall ? 'wall|' : '');
    this.userPrefs += (userInputs.type ? 'type|' : '');
    this.userPrefs += (userInputs.hideSummaryRow ? 'hideSummaryRow|' : '');

    this.cookieService.set('FieldPlanPrefs', this.userPrefs, 365);
    return this.getUserPreferences();
  }
}
