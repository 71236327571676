  <div *ngIf="isLoaded" class="mmodal-dialog-center">
    <div class="modal-header">
      <h4 class="modal-title pull-left">Crane Movement After Load {{ this.loadNumber }}</h4>
      <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true" class="visually-hidden">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="modal-body">
        <div>To delete a crane movement enter zero minutes</div>
        <div class="row mt-5">
          <label class="col-md-6">Crane Move Minutes</label>
          <span class="col-md-6" ><input id="craneMoveMinutes" type="number" class="form-control mb-1" pattern="[0-9]*" [(ngModel)]="this.craneDelay.craneMoveDelay" /></span>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-light" data-bs-dismiss="modal" (click)="bsModalRef.hide();">Cancel</button>
      <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="okClicked(); bsModalRef.hide();">OK</button>
    </div>
  </div>

