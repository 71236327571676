<div class="col-lg-10 position-relative">
  <h3>SHIPPING OFFICE</h3>
  <div class="row">
    <div class="col-lg-2 col-md-2">
      <input type="text" placeholder="Datepicker" class="form-control" bsDatepicker
        [bsConfig]="{ containerClass: 'theme-dark-blue', dateInputFormat: 'MM/DD/YYYY', selectFromOtherMonth: true, showWeekNumbers:false }"
        [(ngModel)]="modelBolSearch.expectedPickupDate" name="expectedPickupDate" required>
    </div>
    <div class="col-lg-2 col-md-2">
      <input type="text" placeholder="Enter BOL Number" class="form-control" [(ngModel)]="modelBolSearch.bolNumber" (keydown.enter)="searchBol()">
    </div>
    <div class="col-md-3">
      <button type="button" class="btn btn-primary btn-sm me-md-2" [disabled]="invalidSearch()" (click)='searchBol()'>
        <i class="fa-solid fa-magnifying-glass"></i>
        SEARCH
      </button>
      <button type="button" class="btn btn-danger btn-sm" [disabled]="invalidSearch()" (click)='clearSearch()'>
        <i class="fa-regular fa-circle-xmark"></i>
        CLEAR
      </button>
    </div>

    <div class="col-lg-2 col-md-3" *ngIf="modelBolSearch.companyIds.length > 0">
      <app-shipping-daily-summary [CompanyIds]="modelBolSearch.companyIds"
        [ExpectedPickupDate]="modelBolSearch.expectedPickupDate"></app-shipping-daily-summary>
    </div>
    <div class="row">
      <div class="col-lg-4">
        <div *ngIf="offices.length==0 && hasPerformedSearch" role="alert">No data found based on search criteria</div>
      </div>
    </div>
  </div>
  <div class="col-lg-11 position-relative stickyContainer">
    <table class="table table-bordered table-striped table-hover table-sm stickyBorderBottom">
      <thead class="table-light sticky-top top-0">
        <tr>
          <th class="text-nowrap stickyBorder" (click)="setOrder('statusText')">STAT <span *ngIf="order === 'statusText'"
              [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
            <span *ngIf="order === 'statusText'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
            <span *ngIf="order !== 'statusText'"><i class="fa-solid fa-sort"></i></span>
          </th>
          <th class="text-nowrap stickyBorder" (click)="setOrder('job')">JOB <span *ngIf="order === 'job'" [hidden]="reverse"><i
                class="fa-solid fa-sort-down"></i></span>
            <span *ngIf="order === 'job'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
            <span *ngIf="order !== 'job'"><i class="fa-solid fa-sort"></i></span>
          </th>
          <th class="text-nowrap stickyBorder" (click)="setOrder('bolNum')">BOL <span *ngIf="order === 'bolNum'"
              [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
            <span *ngIf="order === 'bolNum'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
            <span *ngIf="order !== 'bolNum'"><i class="fa-solid fa-sort"></i></span>
          </th>
          <th class="text-nowrap stickyBorder" (click)="setOrder('loadWindow')">WINDOW <span *ngIf="order === 'loadWindow'"
              [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
            <span *ngIf="order === 'loadWindow'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
            <span *ngIf="order !== 'loadWindow'"><i class="fa-solid fa-sort"></i></span>
          </th>
          <th class="text-nowrap stickyBorder" (click)="setOrder('carrierName')">CARRIER <span *ngIf="order === 'carrierName'"
              [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
            <span *ngIf="order === 'carrierName'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
            <span *ngIf="order !== 'carrierName'"><i class="fa-solid fa-sort"></i></span>
          </th>
          <th class="text-nowrap stickyBorder" (click)="setOrder('tractorDescription')">TRUCK DESC <span
              *ngIf="order === 'tractorDescription'" [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
            <span *ngIf="order === 'tractorDescription'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
            <span *ngIf="order !== 'tractorDescription'"><i class="fa-solid fa-sort"></i></span>
          </th>
          <th class="text-nowrap stickyBorder" (click)="setOrder('loadNum')">LOAD # <span *ngIf="order === 'loadNum'"
              [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
            <span *ngIf="order === 'loadNum'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
            <span *ngIf="order !== 'loadNum'"><i class="fa-solid fa-sort"></i></span>
          </th>
          <th class="text-nowrap stickyBorder" (click)="setOrder('trailer')">TRAILER <span *ngIf="order === 'trailer'"
              [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
            <span *ngIf="order === 'trailer'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
            <span *ngIf="order !== 'trailer'"><i class="fa-solid fa-sort"></i></span>
          </th>
          <th class="text-nowrap stickyBorder" (click)="setOrder('location')">LOC <span *ngIf="order === 'location'"
              [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
            <span *ngIf="order === 'location'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
            <span *ngIf="order !== 'location'"><i class="fa-solid fa-sort"></i></span>
          </th>
          <th class="text-nowrap stickyBorder" (click)="setOrder('craneCrew')">CRANE <span *ngIf="order === 'craneCrew'"
              [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
            <span *ngIf="order === 'craneCrew'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
            <span *ngIf="order !== 'craneCrew'"><i class="fa-solid fa-sort"></i></span>
          </th>
          <th class="text-nowrap stickyBorder" (click)="setOrder('deliveryDateTime')">DEL DATE <span
              *ngIf="order === 'deliveryDateTime'" [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
            <span *ngIf="order === 'deliveryDateTime'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
            <span *ngIf="order !== 'deliveryDateTime'"><i class="fa-solid fa-sort"></i></span>
          </th>
          <th class="text-nowrap stickyBorder" (click)="setOrder('deliveryDateTime')">DEL TIME <span
              *ngIf="order === 'deliveryDateTime'" [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
            <span *ngIf="order === 'deliveryDateTime'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
            <span *ngIf="order !== 'deliveryDateTime'"><i class="fa-solid fa-sort"></i></span>
          </th>
          <th class="text-nowrap stickyBorder" (click)="setOrder('times')">CARRIER TIMES <span *ngIf="order === 'times'"
              [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
            <span *ngIf="order === 'times'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
            <span *ngIf="order !== 'times'"><i class="fa-solid fa-sort"></i></span>
          </th>
          <th class="text-nowrap stickyBorder" (click)="setOrder('isPreLoad')">P-LD <span *ngIf="order === 'isPreLoad'"
              [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
            <span *ngIf="order === 'isPreLoad'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
            <span *ngIf="order !== 'isPreLoad'"><i class="fa-solid fa-sort"></i></span>
          </th>
          <th class="stickyBorder"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let office of offices | orderBy:propertyName:reverse "> <!-- | orderBy: order:reverse:true -->
          <td>
            <img src="{{office?.statusImage}}" title="{{office?.statusText}}" class="stat">
          </td>
          <td>
            <span>{{office?.job}}</span>
          </td>
          <td>
            <span>{{office?.bolNum}}</span>
          </td>
          <td>
            <span>{{office?.loadWindow}}</span>
          </td>
          <td class="text-wrap" style="width: 100px;">
            <span>{{office?.carrierName}}</span>
          </td>
          <td class="text-wrap" style="width: 100px;">
            <span>{{office?.tractorDescription}}</span>
          </td>
          <td>
            <span>{{office?.loadNum}} <a (click)="openCheckInModalWithComponent(office)"
                *ngIf="authService.hasPermission('ShippingOfficeEdit') && office?.checkInTimestamp != null && office?.checkOutTimestamp == null">
                <i class="fa-solid fa-pen-to-square" title="Edit Load Assignment"
                  style="height: 15px; cursor: pointer; color: #0d6efd"></i>
              </a> </span>
          </td>
          <td>
            <span>{{office?.trailer}}</span>
          </td>
          <td>
            <span>{{office?.location}}</span>
          </td>
          <td>
            <span>{{office?.craneCrew}}</span>
          </td>
          <td>
            <span>{{office?.deliveryDateTime | date:'MM/dd/yyyy'}}</span>
          </td>
          <td>
            <span>{{office?.deliveryDateTime | date:'HH:mm'}}</span>
          </td>
          <td>
            <div [innerHTML]="office?.times"></div>
          </td>
          <td>
            <i *ngIf="office?.isPreLoad" class="fa-solid fa-check fa-lg" title="Load is a Pre-Load"
              style="color: green;"></i>
          </td>
          <td>
            <div class="container px-0" style="width: 200px">
              <button type="button" class="btn btn-primary btn-sm me-2" [disabled]="!office?.canCheckIn"
                (click)="openCheckInModalWithComponent(office)" *ngIf="authService.hasPermission('ShippingOfficeEdit')">
                Check-In
              </button>
              <button type="button" class="btn btn-danger btn-sm me-2" [disabled]="!office?.canCheckOut"
                data-bs-toggle="modal" data-bs-target="#checkoutModal" (click)="openCheckOutModalWithComponent(office)"
                *ngIf="authService.hasPermission('ShippingOfficeEdit')">
                Check-Out
              </button>
              <a *ngIf="office?.canPrintBol" (click)="printBOL(office)">
                <i class="fa fa-print" title="Print BOL" style="height: 20px; cursor: pointer;"></i>
              </a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
