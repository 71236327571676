import { Shipping3plBolService } from './../shipping3pl-bol/shipping3pl-bol.service';
import { ShippingYardCustomItem } from './../shipping-yard/shipping-yard-custom-item.model';
import { Component, OnInit, inject } from '@angular/core';
import { ShippingSetupService } from './shipping-setup.service';
import { ShippingSetup } from './shipping-setup.model';
import { ShippingYardPanel } from '../shipping-yard/shipping-yard-panel.model';
import { ShippingOfficeCheckInCraneCrew } from '../shipping-office/shipping-office-checkin/shipping-office-checkin-crane-crew.model';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { ShippingSetupCustomItem } from './shipping-setup-custom-item.model';
import { ToastService, toastTypes } from '../toast/toast.service';
import { ErrorResponse } from '../error-handling/error-response.model';
import { AuthService } from '../authentication/auth.service';
import { User } from '../authentication/user-model';
import { LoadingModalComponent } from '../controls/loading-modal/loading-modal.component';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { ShippingYardService } from '../shipping-yard/shipping-yard.service';
import { ShippingYardTrailer } from '../shipping-yard/shipping-yard-trailer.model';
import { Shipping3plBol } from '../shipping3pl-bol/shipping3pl-bol.model';

@Component({
  selector: 'app-shipping-setup',
  templateUrl: './shipping-setup.component.html',
  styleUrl: './shipping-setup.component.css',
})
export class ShippingSetupComponent implements OnInit {

  shippingSetupService: ShippingSetupService = inject(ShippingSetupService);
  shippingYardService: ShippingYardService = inject(ShippingYardService);
  shipping3plBolService: Shipping3plBolService = inject(Shipping3plBolService);
  shippingSetups: ShippingSetup[] = [];
  checkAll: boolean = false;
  isCollapsed = false;
  selectedShippingSetup = new ShippingSetup(0, null, '', null, 0, 0, 0, 0, 0, 0, '', '', 0, 0, '', '', '', 0, new Date(),
    new Date(), 0, 0, 0, false, false, false, [], [], '', '', false, false, false, '', 0, '', '');
  craneCrews: ShippingOfficeCheckInCraneCrew[] = [];
  searchJobNumber!: string;
  customItems: ShippingSetupCustomItem[] = [];
  isLoading: boolean = false;
  authService = inject(AuthService);
  order: string = "loadDescriptor";
  reverse: boolean = true;
  bsModalRef?: BsModalRef;
  selectAllSetups: boolean = false;
  hasSelectedSetups: boolean = false;
  propertyName: string = '';
  yardTrailers: ShippingYardTrailer[] = [];

  constructor(private toast: ToastService, private modalService: BsModalService) { }

  ngOnInit(): void {

  }

  getSearchLoads() {

    this.openSeachModal('Loading Shipping Setup...');
    this.isLoading = true;

    try {
      this.sleep(1000).then(() => {

        this.hasSelectedSetups = false;

        let userCompanyIds: number[] = [];


        let storedAuthenticatedUser = localStorage.getItem('authenticatedUser');

        if (storedAuthenticatedUser !== null) {
          let localUser: User = JSON.parse(storedAuthenticatedUser);
          localUser.userCompanies.forEach((company) => {
            userCompanyIds.push(company.companyId);
          })
        }

        this.shippingSetupService.getSetupLoads(userCompanyIds, this.searchJobNumber).subscribe(result => {
          let alternateCrane = result.some(x => x.crane == 2);
          let hasAlternateCrane: boolean = alternateCrane == null;

          //console.log('result', result)

          result.forEach((shippingOffice: ShippingSetup) => {
            this.updateSetupProperties(shippingOffice, hasAlternateCrane);
          });

          this.shippingSetups = result;

          this.shippingSetupService.getCraneCrews(this.authService.currentUserSig()!.companyId).subscribe(result => {

            this.craneCrews = result;

          }, error => {
            this.isLoading = false;
            console.error(error)
            this.closeSearchModal();
          });

          this.shippingSetupService.getCustomItems().subscribe(result => {

            this.customItems = result;
            this.isLoading = false;

          }, error => {
            this.isLoading = false;
            console.error(error)
            this.closeSearchModal();
          });

          this.reverse = true;
          this.propertyName = '';
        }, error => console.error(error));

        this.shippingYardService.getYardTrailers(this.authService.currentUserSig()!.companyId, 'JOB').subscribe(result => {

          this.yardTrailers = result;

          this.yardTrailers = this.yardTrailers.sort(function (a, b) {
            return a.trailerName.localeCompare(b.trailerName, undefined, {
              numeric: true,
              sensitivity: 'base'
            });
          });
        }, error => console.error(error));

        this.closeSearchModal();
      });
    } catch (error) {
      console.log(error)
      this.isLoading = false;
      this.closeSearchModal();
    }
  }
  private updateSetupProperties(shippingSetup: ShippingSetup, hasAlternateCrane: boolean) {
    let allPreLoad: boolean = true;

    shippingSetup.maxSquareFeet = _.max(_.map(shippingSetup.panels, (x: any) => x.squareFeet));
    //shippingSetup.totalTimeToSet = _.sum(_.map(shippingSetup.panels, (x: any) => x.timeToSet));
    shippingSetup.maxLength = _.max(_.map(shippingSetup.panels, (x: any) => x.length));
    shippingSetup.maxWidth = _.max(_.map(shippingSetup.panels, (x: any) => x.width));
    //shippingSetup.craneCrewId = _.first(_.map(shippingSetup.panels, (x: any) => x.craneCrewId));
    shippingSetup.loadDescriptor = shippingSetup.loadNumber + (hasAlternateCrane ? ' - ' + shippingSetup.craneName : '');

    let loadedPanel = shippingSetup.panels.some(x => x.isTrailerLoaded == true);
    let loadedItem = shippingSetup.customItems.some(x => x.isTrailerLoaded == true);
    let checkedOutPanel = shippingSetup.panels.some(x => x.checkOutTimestamp != null);

    shippingSetup.hasItems = shippingSetup.customItems.length > 0;

    if (loadedPanel || loadedItem) {
      // this load cannot be modified
      shippingSetup.isLoaded = true;
    }

    if (checkedOutPanel) {
      // this load cannot be modified
      shippingSetup.isCheckedOut = true;
    }

    if (!shippingSetup.isPreLoad) {
      allPreLoad = false;
    }

    for (var p = 0; p < shippingSetup.panels.length; p++) {
      var panel = shippingSetup.panels[p];

      panel.allowUp = (p > 0);
      panel.allowDown = (p + 1 < shippingSetup.panels.length);
      panel.isExpanded = false;
    }

    for (var p = 0; p < shippingSetup.customItems.length; p++) {
      var item = shippingSetup.customItems[p];
      item.isExpanded = false;
    }

    this.checkAll = (allPreLoad); // If everything is a preload, then default checkbox to true

  }

  toggleExpanded(panels: ShippingYardPanel[]) {
    panels.forEach((panel: ShippingYardPanel) => {
      panel.isExpanded = !panel.isExpanded;
    });

  }

  toggleCustomItemExpanded(customItem: ShippingYardCustomItem[]) {
    customItem.forEach((item: ShippingYardCustomItem) => {
      item.isExpanded = !item.isExpanded;
    });

  }


  editSetup(shippingSetup: ShippingSetup) {
    this.selectedShippingSetup = shippingSetup;
  }

  cancel(){
    this.getSearchLoads();
  }
  deleteCustomItem(customItemId: number) {
    let items: ShippingYardCustomItem[];
    items = this.selectedShippingSetup.customItems.filter(x => x.customItemId !== customItemId);
    this.selectedShippingSetup.customItems = items;
  }

  addCustomItem() {
    debugger;
    this.selectedShippingSetup.customItems.push(new ShippingYardCustomItem(this.selectedShippingSetup.jobLoadId, 0, '', 0, null, new Date(), false, '', false));
  }

  saveCustomItems() {

    let craneCrewId: string = this.selectedShippingSetup.craneCrewId == undefined ? "" : this.selectedShippingSetup.craneCrewId.toString();

    this.shippingSetupService.updateShipSetupPreload(this.selectedShippingSetup).subscribe((response: ErrorResponse) => {
        this.toast.initiate({
          title: response.name,
          content: response.message,
          type: response.statusCode == 200 || response.statusCode == 201 ? toastTypes.success : toastTypes.error,
        });

        this.shippingSetupService.updateShipSetupLoadItems(this.selectedShippingSetup.jobLoadId, this.selectedShippingSetup.customItems).subscribe((response: ErrorResponse) => {
          this.toast.initiate({
            title: response.name,
            content: response.message,
            type: response.statusCode == 200 || response.statusCode == 201 ? toastTypes.success : toastTypes.error,
          });

          this.getSearchLoads();

        }, (error: HttpErrorResponse) => {
          this.toast.initiate({
            title: error.error.name,
            content: error.error.message,
            type: toastTypes.error,
          });
        });
      }, (error: HttpErrorResponse) => {
        this.toast.initiate({
          title: error.error.name,
          content: error.error.message,
          type: toastTypes.error,
        });
      });
  }
  public invalidSearch(): boolean {
    if (this.searchJobNumber == undefined || this.searchJobNumber.length == 0 || this.authService.currentUserSig()!.companyId == 0 || this.isLoading == true) {
      return true;
    }

    return false;
  }

  setOrder(value: string) {

    this.propertyName = value;

    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }

  movePanelUp(load: ShippingSetup, panel: ShippingYardPanel) {
    var index = load.panels.findIndex(x => x.mark == panel.mark); // _.findIndex(load.Panels, x => (<any>x).Mark == panel.Mark);

    var prevPanel = load.panels[index - 1];

    this.shippingSetupService.updateShipSetupTrailerSequence(Number(load.job), panel.mark, prevPanel.trailerSEQ, prevPanel.mark, panel.trailerSEQ).subscribe({
      next: (response: ErrorResponse) => {
        load.panels.splice(index - 1, 0, load.panels.splice(index, 1)[0]);

        prevPanel.allowUp = true;
        prevPanel.allowDown = (index + 1 < load.panels.length);
        panel.allowUp = index > 1;
        panel.allowDown = true;

        this.toast.initiate({
          title: response.name,
          content: response.message,
          type: response.statusCode == 200 || response.statusCode == 201 ? toastTypes.success : toastTypes.error,
        });

      },
      error: (error) => {
        // Handle error cases
        this.toast.initiate({
          title: error.error.name,
          content: error.error.message,
          type: toastTypes.error,
        });

        console.error(error);
      }
    });
  }

  movePanelDown(load: ShippingSetup, panel: ShippingYardPanel) {
    var index = load.panels.findIndex(x => x.mark == panel.mark); // _.findIndex(load.Panels, x => (<any>x).Mark == panel.Mark);

    var nextPanel = load.panels[index + 1];

    this.shippingSetupService.updateShipSetupTrailerSequence(Number(load.job), panel.mark, nextPanel.trailerSEQ, nextPanel.mark, panel.trailerSEQ).subscribe({
      next: (response: ErrorResponse) => {
        load.panels.splice(index + 1, 0, load.panels.splice(index, 1)[0]);

        nextPanel.allowDown = true;
        nextPanel.allowUp = (index > 0);
        panel.allowUp = true;
        panel.allowDown = (index + 1 + 1 < load.panels.length);

        this.toast.initiate({
          title: response.name,
          content: response.message,
          type: response.statusCode == 200 || response.statusCode == 201 ? toastTypes.success : toastTypes.error,
        });
      },
      error: (error) => {
        // Handle error cases
        this.toast.initiate({
          title: error.error.name,
          content: error.error.message,
          type: toastTypes.error,
        });

        console.error(error);
      }
    });
  }

  togglePreLoad(load: ShippingSetup) {

    load.isPreLoad = !load.isPreLoad;

    this.shippingSetupService.updateShipSetupPreload(load).subscribe({
        next: (response: ErrorResponse) => {

          this.toast.initiate({
            title: response.name,
            content: response.message,
            type: response.statusCode == 200 || response.statusCode == 201 ? toastTypes.success : toastTypes.error,
          });

        },
        error: (error) => {
          this.toast.initiate({
            title: error.error.name,
            content: error.error.message,
            type: toastTypes.error,
          });
        }
      });
  }

  toggleCheckAll(event: any) {
    this.shippingSetups.forEach(x => x.isPreLoad = event.target.checked);

    this.shippingSetups.forEach((shippingSetup: ShippingSetup) => {
      this.shippingSetupService.updateShipSetupPreload(shippingSetup).subscribe({
          next: (response: ErrorResponse) => {

          },
          error: (error) => {
            console.log('error', error.error);
          }
        });
    });
  }

  openSeachModal(message: string) {
    const initialState: ModalOptions = {
      initialState: {
        message: message
      }
    };
    this.bsModalRef = this.modalService.show(LoadingModalComponent, Object.assign(initialState, { class: 'modal-lg modal-dialog-centered' }));
  }

  closeSearchModal() {
    this.modalService.hide();
  }

  sleep(ms: number | undefined) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  setSelectedSetup(selectedJobLoadId: number) {
    let selectedSetup = this.shippingSetups.find(x => x.jobLoadId == selectedJobLoadId);

    if (selectedSetup !== undefined) {
      selectedSetup.isPreLoad = !selectedSetup?.isPreLoad;
    }

    let anySelectedRows: ShippingSetup[] = this.shippingSetups.filter(x => x.isPreLoad == true);

    this.hasSelectedSetups = anySelectedRows!.length > 0 ? true : false;
  }

  // trackByFn(index: any, item: ShippingSetup) {
  //   return item ? item.rowId : undefined;
  // }

}
