import { Inject, Injectable, OnInit } from '@angular/core';
import { ShippingOffice } from '../shipping-office.model';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ShippingOfficeCheckInCraneCrew } from './shipping-office-checkin-crane-crew.model';
import { ShippingOfficeCheckInLoad } from './shipping-office-checkin-load.model';
import { ErrorResponse } from 'src/app/error-handling/error-response.model';

@Injectable({
  providedIn: 'root'
})
export class ShippingOfficeCheckinService implements OnInit {
  checkInShippingOffice: ShippingOffice | undefined;

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
    this.baseUrl = environment.backend.baseURL;
  }

  ngOnInit(): void {

  }

  getCheckIn() {
  }

  getCraneCrews(companyId: number): Observable<ShippingOfficeCheckInCraneCrew[]> {
    const options = { params: new HttpParams(), HttpHeaders: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    try {
      return this.http.get<ShippingOfficeCheckInCraneCrew[]>(`${this.baseUrl}/shippingoffice/craneCrews?companyId=${companyId}`, options);
    } catch (error) {
      console.log(error);
      return new Observable<any>();
    }
  }

  getLoads(companyId: number, jobNumber: number): Observable<ShippingOfficeCheckInLoad[]> {
    const options = { params: new HttpParams(), HttpHeaders: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    try {
      return this.http.get<ShippingOfficeCheckInLoad[]>(`${this.baseUrl}/shippingoffice/loads?companyId=${companyId}&&jobNumber=${jobNumber}`, options);
    } catch (error) {
      console.log(error);
      return new Observable<any>();
    }
  }

  updateShippingOfficeCheckIn(shippingOfficeCheckIn: ShippingOffice) {
    const options = { params: new HttpParams(), HttpHeaders: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    try {
      return this.http.post<ErrorResponse>(`${this.baseUrl}/shippingoffice/updateshipofficeload`, shippingOfficeCheckIn, options);
    } catch (error) {
      console.log(error);
      return new Observable<any>();
    }
  }

  deleteShippingOfficeCheckIn(shippingOfficeCheckIn: ShippingOffice) {

    let deleteShippingOfficeCheckIn: ShippingOffice = shippingOfficeCheckIn;

    deleteShippingOfficeCheckIn.craneCrewId = null;
    deleteShippingOfficeCheckIn.jobLoadId = null;
    deleteShippingOfficeCheckIn.loadNum = null;
    deleteShippingOfficeCheckIn.checkInTimestamp = null;
    deleteShippingOfficeCheckIn.checkOutTimestamp = null;
    deleteShippingOfficeCheckIn.trailer = null;
    deleteShippingOfficeCheckIn.trailerSize = null;
    deleteShippingOfficeCheckIn.tractorDescription = null;
    deleteShippingOfficeCheckIn.carrierName = null;

    const options = { params: new HttpParams(), HttpHeaders: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    try {
      return this.http.post<ErrorResponse>(`${this.baseUrl}/shippingoffice/updateshipofficeload`, deleteShippingOfficeCheckIn, options);
    } catch (error) {
      console.log(error);
      return new Observable<any>();
    }
  }
}
