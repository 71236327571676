<div class="modal-header">
  <h4 class="modal-title pull-left">Confirmation</h4>
  <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="bsModalRef?.hide()">
    <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div>
    <p>{{message}}</p>
    <h5>This field plan has already been accepted. Modifications may affect shipping schedule.
    </h5>
    <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
      (click)="okSendClicked(); bsModalRef?.hide();">OK & Finished Editing</button>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-light" data-bs-dismiss="modal"
    (click)="cancelClicked(); bsModalRef?.hide()">Cancel</button>
  <button type="button" class="btn btn-primary" (click)="okClicked(); bsModalRef?.hide();">OK</button>
</div>
