<div class="modal-header modal-dialog-centered">
  <h4 class="modal-title pull-left">Email sent</h4>
  <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="bsModalRef?.hide()">
    <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div>
    <p>{{message}}</p>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-light" data-bs-dismiss="modal"
    (click)="cancelClicked(); bsModalRef?.hide()">Cancel</button>
  <button type="button" class="btn btn-primary" (click)="okClicked(); bsModalRef?.hide();">OK</button>
</div>
