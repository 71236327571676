<!-- Field Plan User Preferences Modal -->
<div class="modal fade" id="userPrferencesModal" tabindex="-1" aria-labelledby="userPrferencesModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="userPreferencesModalLabel">User Preferences</h5>
        <button id="closeBtn" type="button" class="btn-close" (click)="prefChangeCanceled()" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Column Preferences</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Status</td>
                <td><input type="checkbox" [(ngModel)]="this.userPrefInputs.status"></td>
              </tr>
              <tr>
                <td>Mark</td>
                <td><input type="checkbox" [(ngModel)]="this.userPrefInputs.mark"></td>
              </tr>
              <tr>
                <td>Load #</td>
                <td><input type="checkbox" [(ngModel)]="this.userPrefInputs.loadNum"></td>
              </tr>
              <tr>
                <td>Min/Pnl</td>
                <td><input type="checkbox" [(ngModel)]="this.userPrefInputs.minPnl"></td>
              </tr>
              <tr>
                <td>Width</td>
                <td><input type="checkbox" [(ngModel)]="this.userPrefInputs.width"></td>
              </tr>
              <tr>
                <td>Plant</td>
                <td>
                  <input type="checkbox" [(ngModel)]="this.userPrefInputs.plant">
                </td>
              </tr>
              <tr>
                <td>Weight</td>
                <td><input type="checkbox" [(ngModel)]="this.userPrefInputs.weight"></td>
              </tr>
              <tr>
                <td>Length</td>
                <td><input type="checkbox" [(ngModel)]="this.userPrefInputs.length"></td>
              </tr>
              <tr>
                <td>Wall</td>
                <td><input type="checkbox" [(ngModel)]="this.userPrefInputs.wall" /></td>
              </tr>
              <tr>
                <td>Panel Type</td>
                <td><input type="checkbox" [(ngModel)]="this.userPrefInputs.type"></td>
              </tr>
            </tbody>
          </table>
          <table class="table table-striped">
            <thead>
              <tr>
                <th colspan="2">Display Preferences</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Hide Summary Row</td>
                <td>
                  <input type="checkbox" [(ngModel)]="this.userPrefInputs.hideSummaryRow">
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-footer">
        <button id="userPrefClose" type="button" class="btn btn-danger" (click)="prefChangeCanceled()" data-bs-dismiss="modal">Cancel</button>
        <button type="button" (click)="updateUserPreferences();" data-bs-target="#userPreferences" class="btn btn-primary">Save</button>
      </div>
    </div>
  </div>
</div>
<!-- End Field User Preferences Modal -->
