<!-- Begin Check-In modal -->
<div class="modal-header">
  <h5 class="modal-title">Check-In</h5>
  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
    (click)="closeCheckInModal()"></button>
</div>
<div class="modal-body">
  <div class="col-12 col-md-12">

    <form name="officeCheckin" class="row g-3 needs-validation" (ngSubmit)="saveShippingOfficeCheckIn()" novalidate>
      <div class="row">
        <div class="col-md-8">
          <label class="form-label mt-2">BOL #: {{ selectedOffice.bolNum }}  <a if.bind="selectedOffice.canPrintBol" (click)="printBOL(selectedOffice)">
            <i class="fa fa-print" title="Print BOL" style="height: 20px; cursor: pointer;"></i>
          </a></label>

        </div>
      </div>
      <div class="row">
        <div class="col-md-8">
          <label class="form-label mt-2">Crane Crew</label>
          <select #checkinCraneCrews class="form-select" name="craneCrewID" [(ngModel)]="selectedOffice.craneCrewId">
            <option *ngFor="let crane of craneCrews" [ngValue]="crane['craneCrewID']">
              {{crane['description']}}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-md-10">
          <label class="form-label mt-2">Load Num</label>
          <select #loads class="form-select" name="jobLoadId" [(ngModel)]="selectedOffice.loadNum">
            <option [ngValue]=null>--Please Select--</option>
            <option *ngFor="let jobLoad of jobLoads" [ngValue]="jobLoad.loadNumber">
              {{jobLoad['selectText']}}
            </option>
          </select>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <label class="form-label mt-2">Truck Descr</label>
          <input name="tractorDescription" type="text" [(ngModel)]="selectedOffice.tractorDescription"
            class="form-control" />
          <span>(Color/Make/Truck Number)</span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-8">
          <label class="form-label mt-2">Carrier</label>
          <input name="carrierName" type="text" [(ngModel)]="selectedOffice.carrierName" class="form-control" />
        </div>
      </div>

      <div class="modal-footer">
        <div class="row col-md-12">
          <div class="col-md-6">
            <button type="button" class="btn btn-primary" data-bs-dismiss="deleteConfirmationModal" data-bs-toggle="modal"
              data-bs-target="#deleteConfirmationModal" (click)="openModal(template)">Delete</button> <!--disabled="selectedOffice.jobLoadId !== null"-->
          </div>
          <div class="col-md-6">
            <button type="button" class="btn btn-danger" style="margin-left: 75px;" data-bs-dismiss="modal"
              (click)="closeCheckInModal()">Cancel</button>
            <button class="btn btn-primary" style="float: right;" type="submit">OK</button>
          </div>
        </div>
        </div>
    </form>
  </div>
</div>
<!-- End Check-In Modal -->

<!-- Begin Confirmation Delete Modal -->
<ng-template #template class="modal-dialog-centered">
  <div class="modal-header">
    <h4 class="modal-title pull-left">Confirmation</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="bsModalRef?.hide()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Are you sure you want to unassign the carrier from this load?</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="bsModalRef?.hide()">Cancel</button>
    <button type="button" class="btn btn-danger" (click)="deleteShippingOfficeCheckIn(selectedOffice); bsModalRef?.hide()">OK</button>
  </div>
</ng-template>
<!-- End Confirmation Delete Modal -->
