import { ChangeDetectorRef, Component, ElementRef,  Input,  OnDestroy, OnInit, TemplateRef, inject } from '@angular/core';
import { HttpErrorResponse, HttpParams, HttpStatusCode } from '@angular/common/http';
import { FieldPlanDataService } from "./field-plan.data.service";
import { Observable } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { UserPreferencesService } from './user-preferences-modal/user-preferences.service';
import { FieldPlanUpdateProperties } from './field-plan-update-properties.model';
import { ErrorResponse } from '../error-handling/error-response.model';
import { ToastService, toastTypes } from '../toast/toast.service';
import { FieldPlanProjectDetail } from './field-plan-project-detail.model';
import { environment } from 'src/environments/environment';
import { AuthService } from '../authentication/auth.service';
import { FieldPlanCraneSummaryInfo, FieldPlanSummaryDetail, FieldPlanSummaryInfo, ProjectDateExceptions } from './fieldPlanSearchResult.model';
import { forEach } from 'lodash';
import { AlertModalComponent } from '../controls/alert-modal/alert-modal.component';
import { FieldPlanLoadingModalComponent } from './field-plan-detail/field-plan-loading-modal/field-plan-loading-modal.component';
import { FieldPlanEmailType } from './field-plan-detail/field-plan-email-type-enum';
import { FieldPlanEmailSentModalComponent } from './field-plan-detail/field-plan-email-sent-modal/field-plan-email-sent-modal.component';
import { ActivatedRoute, Params, Router } from '@angular/router';


@Component({
  selector: 'app-field-plan',
  templateUrl: './field-plan.component.html',
  styleUrls: ['./field-plan.component.css'],
})

export class FieldPlanComponent implements OnInit, OnDestroy {
  @Input() id?: string;
  isOpen = false;
  private element: any;
  bsModalRef?: BsModalRef;
  projectSearchResults$!: Observable<any>;
  isFieldPlanDisplayed: boolean = false;
  jobNumber!: number;
  panelTypes$!: Observable<any>;
  fieldPlanDataService: FieldPlanDataService = inject(FieldPlanDataService);
  userPreferenceService: UserPreferencesService = inject(UserPreferencesService);
  projectDateExceptions!: ProjectDateExceptions;

  // constructor(private http: HttpClient, @Inject('BASE_URL') public baseUrl: string) {

  // }

  isEditable: boolean = true;
  isFriday: boolean = true;
  totalLoads: number = 0;
  totalTime: number = 0;
  firstShipDate: Date = new Date();


  hasProject = false;
  isProjectManager: boolean = false;
  isYardManager: boolean = false;
  isAdministrator: boolean = false;

  isLoaded: boolean = false;
  isAttached: boolean = false;

  noScheduleFound: boolean = false;
  fullyMobilized: boolean = false;
  fullyShipped: boolean = false;
  partiallyMobilized: boolean = true;
  canEditProject: boolean = true;
  canEditLoads: boolean = true;
  canChangeCraneCount: boolean = true;
  canRecalculateUnmobilized: boolean = false;
  canRecalculateJob: boolean = false;
  sortableType: string = "1";
  canReverseSort: boolean = (this.sortableType == "1");
  canCreateDay: boolean = (this.sortableType != "1");
  fabconFridayProject: boolean = false;
  showModifiedAlert: boolean = false;
  loadCount: number = 0;
  multiplePlants: boolean = false;
  plantSummary: string = '';
  projectTitle: string = '';
  projectUrl: string = '';
  projectNumber: string = '';
  showToggle: boolean = false;

  project: FieldPlanProjectDetail = {
    projectNumber: '',
    projectName: '',
    bidStretchRate: 0,
    planSettingRate: 0,
    fabconFriday: false,
    invalidResultset: false,
    forceDatabaseUpdate: false,
    projectManage: '',
    projectManagerEmail: '',
    paymentStatus: false,
    fieldSup: '',
    fieldForeman: '',
    startDate: '',
    estErecDays: 0,
    craneCount: 0,
    maxWeight: 0,
    maxPanelWeight: 0,
    erector: '',
  };
  craneCount: number = 0;
  wipReport: string = '';
  widthReport: string = '';
  electronicJobFile: string = '';


  fieldPlanCranes: any[] = [];

  selectedType: any;
  selectedCrane: number = 0;
  userPrefs!: any;
  availableCranes: number = 0;
  maxWeight: number = 0;
  origMaxWeight: number = 0;
  origAvailableCranes: number = 0;
  showAlertModal: boolean = false;
  sumByWidthsByWallLink: string = '';
  wipLink: string = '';
  alertComments: string = '';
  authService = inject(AuthService);
  //Added for Multi-drop functionality
  events = [];
  emailType: string = '';
  fieldPlanEmailType: typeof FieldPlanEmailType = FieldPlanEmailType;

  constructor(private cdr: ChangeDetectorRef, private el: ElementRef, private toast: ToastService, private modalService: BsModalService, private route: ActivatedRoute) {
    this.element = el.nativeElement;
  }

  ngOnInit() {
    // move element to bottom of page (just before </body>) so it can be displayed above everything else
    document.body.appendChild(this.element);
    this.isProjectManager = this.authService.hasRole('ProjectManager');
    this.isYardManager = this.authService.hasRole('YardManagement');
    this.isAdministrator = this.authService.hasRole('Administration');

    this.userPrefs = this.userPreferenceService.getUserPreferences();

    this.isEditable = this.determineIsEditable();

    this.route.params.subscribe(params => {
      if (Object.keys(params).length > 0) {
        this.jobNumber = Number(params['job']);
        this.searchJob(this.jobNumber);
      }
    });

    this.resizeWindow();
  }

  

  // these are needed so the correct size is calculated when the
  //job info button is toggled.   the true hiehgts cannot be determined
  // until the panel is rendered 
  attachListeners() {
    const jobCollapsible = document.getElementById('collapseJobInfo');
    jobCollapsible?.addEventListener('hidden.bs.collapse', event => { this.resizeWindow() });
    jobCollapsible?.addEventListener('shown.bs.collapse', event => { this.resizeWindow() });
  }

  ngOnDestroy() {
    // remove modal element from html
    this.element.remove();
  }

  private determineIsEditable(): boolean {

    let isProjectManager = this.authService.hasRole('ProjectManager');
    let isYardManager = this.authService.hasRole('YardManagement');
    let isAdministrator = this.authService.hasRole('Administration');

    return isAdministrator || isProjectManager || isYardManager;
  }

  open() {
    this.element.style.display = 'block';
    document.body.classList.add('modal-open');
    this.isOpen = true;
  }

  close() {
    this.element.style.display = 'none';
    document.body.classList.remove('modal-open');
    this.isOpen = false;
  }


  openModal(template: TemplateRef<void>, sendEmailType?: FieldPlanEmailType) {
    this.bsModalRef = this.modalService.show(template, Object.assign({}, { class: 'modal-lg modal-dialog-centered' }));

    if (typeof sendEmailType !== undefined) {
      this.emailType = sendEmailType as FieldPlanEmailType;
    }
  }

  openAlertModal(alertMessage: string) {
    const initialState: ModalOptions = {
      initialState: {
        list: [alertMessage],
        title: 'Alert'
      },
      backdrop: 'static',
      keyboard: false
    };
    this.bsModalRef = this.modalService.show(AlertModalComponent, initialState);
    this.bsModalRef.content.closeBtnName = 'OK';
  }

  onPrefsUpdated(updatedPrefs: any) {
    this.userPrefs = updatedPrefs;
  }

  reloadSearchResults() {
    this.openSeachModal();
    this.getJobSearch(this.jobNumber);
  }

  reOrderLoadsChanged() {
    this.openSeachModal();
    this.getJobSearch(this.jobNumber);
  }

  openSeachModal() {
    this.bsModalRef = this.modalService.show(FieldPlanLoadingModalComponent, Object.assign({}, { class: 'modal-lg modal-dialog-centered' }));
  }

  closeSearchModal() {
    this.modalService.hide();
  }

  searchJob(jobNumber: number): void {
    this.openSeachModal();
    this.getJobSearch(jobNumber);
    setTimeout(() => {
      this.resizeWindow();
      this.attachListeners();
    }, 1000);

    this.showModifiedAlert = false;
    this.showToggle = true;
  }

  resizeWindow() {
    let divProjectDetails = <HTMLElement>document.getElementById("projectDetails");
    let height = window.innerHeight - (130 + divProjectDetails.clientHeight); // Plus 130px is for the App Menu

    let divFieldPlanDetails = <HTMLElement>document.getElementById("fieldPlanDetails");
    divFieldPlanDetails.style.height = height.toString() + 'px';
  } 

  getJobSearch(jobNumber: number): void {

    this.alertComments = '';

    localStorage.removeItem("draggedMarkNumbers");

    if (jobNumber === undefined) {
      const alertMsg = `Enter a job number to search`;
      this.openAlertModal(alertMsg);
      return;
    }

    this.isFieldPlanDisplayed = true;

    const options = { params: new HttpParams().set('jobNumber', jobNumber) };

    this.projectSearchResults$ = this.fieldPlanDataService.getFieldPlanProjectSearchResult(jobNumber)
      .pipe(
        map((apiResponse: any) => {
          //for (let searchResult of apiResponse) {
          let fieldPlanCount: number = 0;
          this.hasProject = apiResponse !== null || apiResponse !== undefined;

          // add in code to check for editable settings
          if (this.isProjectManager) {
            this.canEditProject = !this.fullyMobilized;
            this.canRecalculateUnmobilized = !this.fullyMobilized;
            this.canChangeCraneCount = !this.partiallyMobilized;
          } else if (this.isAdministrator) {
            this.canEditProject = true;
            this.canEditLoads = true;
            this.canChangeCraneCount = true;
            this.canRecalculateUnmobilized = true;
            this.canRecalculateJob = true;
          } else if (this.isYardManager) {
            this.canEditProject = false;

            // yard manager can only modify once mobilization starts
            this.canRecalculateJob = this.partiallyMobilized;
            this.canEditLoads = this.partiallyMobilized;
          } else {

            this.partiallyMobilized = true;
            this.canEditProject = false;
            this.canEditLoads = false;
            this.isEditable = false;
          }

          // Count number of loads in project
          this.loadCount = 0;
          this.multiplePlants = false;
          this.plantSummary = '';

          let plantCounts = new Array();
          for (let crane of apiResponse.fieldPlanCraneSummaryInfo) {
            for (let summary of crane.fieldPlanSummaries) {
              let uniquePlants = [...new Set(summary.summaryData.map((item: FieldPlanSummaryDetail) => item.castLoc))];
              for (let castLoc of uniquePlants) {
                const locLoads = summary.summaryData.filter((sd: FieldPlanSummaryDetail) => sd.castLoc == castLoc);
                //debugger;
                const loadCounts = [...new Set(locLoads.map((item: FieldPlanSummaryDetail) => item.loadNum))].length;
                var plantCount = _.find(plantCounts, x => x.CastLoc == castLoc);
                if (plantCount === undefined) {
                  plantCount = { CastLoc: castLoc, LoadCount: loadCounts };
                  plantCounts.push(plantCount);
                }
                else {
                  plantCount.LoadCount += loadCounts;
                }
              }
            }
          }

          if (plantCounts.length > 1) {
            this.multiplePlants = true;
            plantCounts.forEach(pc => {
              if (pc.CastLoc == '')
                pc.CastLoc = 'UNK';

              this.plantSummary += `${pc.CastLoc}: ${pc.LoadCount} `;
            })
          }

          let sumLoadCount = 0;
          for (let i = 0; i < plantCounts.length; i++) {
            sumLoadCount += plantCounts[i]?.LoadCount;
          }

          this.loadCount = sumLoadCount; //plantCounts[0]?.LoadCount;

          this.projectUrl = `https://intranet.fabcon-usa.com/sites/jobmgmt/projects/${apiResponse?.project?.projectNumber}/default.aspx`;
          this.projectNumber = apiResponse?.project?.projectNumber;
          this.projectTitle = apiResponse?.project?.projectName;
          this.maxWeight = apiResponse?.project?.maxWeight;
          this.origMaxWeight = apiResponse?.project?.maxWeight;
          this.availableCranes = apiResponse?.project?.craneCount === 0 ? 1 : apiResponse?.project?.craneCount;
          this.origAvailableCranes = apiResponse?.project?.craneCount === 0 ? 1 : apiResponse?.project?.craneCount;

          this.project = apiResponse.project;
          this.projectDateExceptions = apiResponse.projectDateExceptions;
          this.fabconFridayProject = apiResponse?.project?.fabconFriday;
          this.craneCount = apiResponse?.project?.craneCount;

          if (apiResponse.fieldPlanCraneSummaryInfo.length > 0 && apiResponse.fieldPlanCraneSummaryInfo[0].fieldPlanSummaries.length > 0) {
            this.firstShipDate = apiResponse.fieldPlanCraneSummaryInfo?.[0].fieldPlanSummaries?.[0].shipDate;
          }

          this.fieldPlanCranes = apiResponse.fieldPlanCraneSummaryInfo;
          this.noScheduleFound = this.fieldPlanCranes.length == 0;
          this.fullyMobilized = this.checkFullyMobilized();
          this.partiallyMobilized = this.checkPartiallyMobilized();
          this.fullyShipped = this.checkFullyShipped();
          this.closeSearchModal();
          this.isFieldPlanDisplayed = false;

          this.sumByWidthsByWallLink = environment.reportServer + "%2fProjectManagerReports%2fSum+of+Widths+by+Wall&ProjectID=" + Number(jobNumber);
          this.wipLink = environment.reportServer + "%2fField%2fNew%20WIP&project_id=";

          this.fieldPlanDataService.getFieldPlanProjectErector(this.project.projectNumber).subscribe({
            next: (result) => {
              this.project.erector = Object.values(result)[0];
            },
            error: (error) => {

              console.error(error);
            }
          });

          this.panelTypes$ = this.fieldPlanDataService.getPanelTypes()
            .pipe(
              map((panelTypes: any) => {
                return panelTypes;
              })
            );

          return apiResponse;
        })
    );    
  }

  applyWeightChange(shouldRefresh: boolean) {
    let fieldPlanProperties = new FieldPlanUpdateProperties(this.jobNumber, this.maxWeight, this.availableCranes);

    this.fieldPlanDataService.updateMaxWeight(fieldPlanProperties).subscribe({
      next: (response: ErrorResponse) => {

        if (response.statusCode == HttpStatusCode.Ok && shouldRefresh) {
          this.reloadSearchResults();
        }
      },
      error: (error: HttpErrorResponse) => {
        this.toast.initiate({
          title: error.error.name,
          content: error.error.message,
          type: toastTypes.error,
        });
      }
    });
  }

  showSendAlertButton() {
    this.showModifiedAlert = true;
  }
  showSendAlertModal(template: TemplateRef<void>) {
    this.bsModalRef = this.modalService.show(template, Object.assign({}, { class: 'modal-dialog-centered' }));
  };

  availableCranesChanged(): void {
    let fieldPlanProperties = new FieldPlanUpdateProperties(this.jobNumber, this.origMaxWeight, this.availableCranes);

    this.fieldPlanDataService.updateCraneCount(fieldPlanProperties).subscribe({
      next: (response: ErrorResponse) => {
        this.getJobSearch(this.jobNumber);
      },
      error: (error: HttpErrorResponse) => {
        this.toast.initiate({
          title: error.error.name,
          content: error.error.message,
          type: toastTypes.error,
        });
      }
    });
  }

  recalculateUnmobilized() {
    this.fieldPlanDataService.recalculateUnmobilized(this.jobNumber).subscribe({
      next: (response: ErrorResponse) => {
        this.getJobSearch(this.jobNumber);

      },
      error: (error: HttpErrorResponse) => {
        this.toast.initiate({
          title: error.error.name,
          content: error.error.message,
          type: toastTypes.error,
        });
      }
    });
  }

  recalculateJob() {
    this.fieldPlanDataService.recalculateJob(this.jobNumber).subscribe((response: ErrorResponse) => {
      this.getJobSearch(this.jobNumber);

    }, (error: HttpErrorResponse) => {
      this.toast.initiate({
        title: error.error.name,
        content: error.error.message,
        type: toastTypes.error,
      });
    });
  }

  approveJob() {
    this.fieldPlanDataService.approveJob(this.jobNumber, this.authService.currentUserSig()?.userName!).subscribe({
      next: (response: ErrorResponse) => {
        this.getJobSearch(this.jobNumber);

      },
      error: (error: HttpErrorResponse) => {
        this.toast.initiate({
          title: error.error.name,
          content: error.error.message,
          type: toastTypes.error,
        });
      }
    });
  }

  resetMaxWeight() {
    this.maxWeight = this.origMaxWeight;
  }

  resetAvailableCranes() {
    this.availableCranes = this.origAvailableCranes;
  }

  private checkPartiallyMobilized(): boolean {
    for (let craneIndex = 0; craneIndex < this.fieldPlanCranes.length; craneIndex++) {
      let craneSummary = this.fieldPlanCranes[craneIndex];
      for (let index = 0; index < craneSummary.fieldPlanSummaries?.length; index++) {
        let summary = craneSummary.fieldPlanSummaries[index];
        if (summary.mobilization != null)
          return true;
      }
    }

    // No panels have a mobilization
    return false;
  }

  private checkFullyMobilized(): boolean {
    if (this.fieldPlanCranes.length == 0)
      return false;

    for (let craneSummary of this.fieldPlanCranes) {
      for (let summary of craneSummary.fieldPlanSummaries) {
        if (summary.mobilization == null && summary.panelData.length > 0)
          return false;
      }
    }

    // All panels have a mobilization
    return true;
  }

  private checkFullyShipped(): boolean {
    if (this.fieldPlanCranes.length == 0)
      return false;

    for (let craneSummary of this.fieldPlanCranes) {
      for (let summary of craneSummary.fieldPlanSummaries) {
        var unshippedDay = true;
        for (let data of summary.summaryData) {
          if (data.summaryRow &&
            (data.checkOutTimestamp != null ||
              data.checkInTimestamp != null ||
              data.loadedTimestamp != null)) {
            unshippedDay = false;
          }
        }

        // This day has no shipments yet
        if (unshippedDay)
          return false;
      }
    }

    // All panels have a mobilization
    return true;
  }

  checkIsFriday(shipDate: Date) {
    const sDate = new Date(shipDate);
    return sDate.getDay() === 5;

    //   || this.isTomorrowHoliday(detailItem.shipDate);
  }

  fabconFridayChange() {
    let addedDates: Date[] = [];
    let removedDates: Date[] = [];
    const firstCrane: FieldPlanCraneSummaryInfo = this.fieldPlanCranes[0];
    let fridays: Date[] = []

    firstCrane.fieldPlanSummaries.forEach((fps, FieldPlanSummaryInfo) => {
      if (this.checkIsFriday(fps.shipDate)) {
        fridays.push(fps.shipDate);
      }
    });

    addedDates = this.fabconFridayProject ? fridays : [];
    removedDates = !this.fabconFridayProject ? fridays : [];

    this.fieldPlanDataService.changeFabconFriday(this.jobNumber, addedDates, removedDates).subscribe({
      next: (response: ErrorResponse) => {
        if (response.statusCode === HttpStatusCode.Ok || response.statusCode === HttpStatusCode.Created) {
          this.reloadSearchResults();
        }
      },
      error: (error: HttpErrorResponse) => {
        this.toast.initiate({
          title: error.error.name,
          content: error.error.message,
          type: toastTypes.error,
        });
      }
    });
  }

  setEmailType(emailType: FieldPlanEmailType) {
    this.emailType = emailType;
  }

  sendEmail() {
    const okAction = 'sendEmail';
    const initialSuccessState: ModalOptions = {
      initialState: {
        okAction: okAction,
        message: 'Notice sent successfully'
      }
    };


    switch (this.emailType) {
      case FieldPlanEmailType.sendFieldPlanModificationEmail:
        this.fieldPlanDataService.sendFieldPlanModifiedAlert(this.jobNumber, this.alertComments, this.authService.currentUserSig()?.userName!).subscribe({
          next: (response: ErrorResponse) => {
            console.log('response', response);

            this.bsModalRef = this.modalService.show(FieldPlanEmailSentModalComponent, initialSuccessState);
            this.bsModalRef?.setClass('modal-dialog-centered');

            this.bsModalRef?.content.confirmAction.subscribe((action: string) => {
              if (action === okAction) {
                this.searchJob(this.jobNumber);
              }
            });
          },
          error: (error: HttpErrorResponse) => {
            const initialFailState: ModalOptions = {
              initialState: {
                okAction: okAction,
                message: 'Error during update: ' + error.message
              }
            };
            this.bsModalRef = this.modalService.show(FieldPlanEmailSentModalComponent, initialFailState);
            this.bsModalRef?.setClass('modal-dialog-centered');
          }
        });
        break;

      case FieldPlanEmailType.sendFieldPlanApprovedEmail:
        this.fieldPlanDataService.approveJob(this.jobNumber, this.authService.currentUserSig()?.userName!).subscribe({
          next: (response: ErrorResponse) => {
            this.bsModalRef = this.modalService.show(FieldPlanEmailSentModalComponent, initialSuccessState);
            this.bsModalRef?.setClass('modal-dialog-centered');

            this.bsModalRef?.content.confirmAction.subscribe((action: string) => {
              if (action === okAction) {
                this.searchJob(this.jobNumber);
              }
            });

          },
          error: (error: HttpErrorResponse) => {
            this.toast.initiate({
              title: error.error.name,
              content: error.error.message,
              type: toastTypes.error,
            });
          }
        });
        break;

      default:
        break;
    }
  }

  //Added for Multi-drop functionality
  clearEvents(): void {
    this.events = [];
  }

  itemsRemoved(ev: any, list: any) {
    console.log('itemsRemoved', ev, list);
    //this.events.push({text: `itemsRemoved from ${list}`, ev: JSON.stringify(ev)});
  }

  itemsAdded(ev: any, list: any) {
    console.log('itemsAdded', ev, list);
    //this.events.push({text: `itemsAdded to ${list}`, ev: JSON.stringify(ev)});
  }

  itemsUpdated(ev: any, list: any) {
    console.log('itemsUpdated', ev, list);
    //this.events.push({text: `itemsUpdated in ${list}`, ev: JSON.stringify(ev)});
  }

  selectionChanged(ev: any, list: any) {
    console.log('selectionChanged', ev, list);
    //this.events.push({text: `selectionChanged in ${list}`, ev: JSON.stringify(ev)});
  }
}
