import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export enum toastTypes {
  error,
  success,
}

export interface ToastData {
  title: string;
  content: string;
  show?: boolean;
  type?: toastTypes;
  progressWidth?: string;
  styleClasses?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  data: ToastData = ({} as any) as ToastData;

  public open = new Subject<ToastData>();

  initiate(data: ToastData) {
    //if (data.type) {
      //this.data.type = toastTypes.error;
      //console.log(this.data);
    //}
    this.data = { ...data, show: true, progressWidth: '100%' };

    if(this.data.type === 0){
      this.data.styleClasses = "toast fade show text-bg-danger";
    }
    this.open.next(this.data);
  }

  hide() {
    this.data = { ...this.data, show: false };
    this.open.next(this.data);
  }
}
