import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ShippingOfficeCheckoutChecklist } from './shipping-office-checkout-checklist.model';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ShippingOffice } from '../shipping-office.model';
import { ErrorResponse } from 'src/app/error-handling/error-response.model';

@Injectable({
  providedIn: 'root'
})
export class ShippingOfficeCheckoutService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
    this.baseUrl = environment.backend.baseURL;
  }

  getCheckLists(jobLoadId: number): Observable<ShippingOfficeCheckoutChecklist[]> {
    const options = { params: new HttpParams(), HttpHeaders: new HttpHeaders({'Content-Type': 'application/json'}) };
    try {
      return this.http.get<ShippingOfficeCheckoutChecklist[]>(`${this.baseUrl}/shippingoffice/loadChecklist?jobLoadId=${jobLoadId}`, options);
    } catch (error) {
      console.log(error);
      return new Observable<any>();
    }
  }

  updateShippingOfficeCheckOut(shippingOfficeCheckIn: ShippingOffice) {
    const options = { params: new HttpParams(), HttpHeaders: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    try {
      return this.http.post<ErrorResponse>(`${this.baseUrl}/shippingoffice/updateshipofficeload`, shippingOfficeCheckIn, options);
    } catch (error) {
      console.log(error);
      return new Observable<any>();
    }
  }
}
