<!-- Begin Move Panel Modal -->
<div class="modal fade modal-lg" id="assignTrailerModal" tabindex="-1" aria-labelledby="addAssignTrailerLabel"
  aria-hidden="true" bsModal #modal="bs-modal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 *ngIf="selectedPanelLocation" class="modal-title fs-5" id="assignTrailerLabel">Move Panel {{
          selectedPanelLocation.castNumber }} - {{ selectedPanelLocation.jobNumber }}
          - {{ selectedPanelLocation.markNumber }} to Location
        </h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
          (click)="closeModal()"></button>
      </div>
      <div class="container">
        <div *ngIf="errorMessage">
          <div class="alert alert-danger" role="alert">
            <i class="fa-solid fa-triangle-exclamation"></i>
            {{errorMessage}}
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-lg-6 me-4">
            <img id="PanelDrawing" alt="" [src]="selectedPanelLocationImage" width="400" height="450" />
          </div>
          <div class="col-md-4" *ngIf="selectedPanelLocation">
            <div>
              <label class="form-label" for="location">Location</label>
              <select class="form-select" name="location" [(ngModel)]="selectedPanelLocation.locationTypeID"
                [disabled]="!authService.hasPermission('YardSearchEdit')" (change)="changeLocationType()">
                <option [ngValue]="0">Choose Location</option>
                <option *ngFor="let location of yardLocationTypes" [ngValue]="location.locationTypeId">
                  {{location.locationTypeName}}
                </option>
              </select>
            </div>
            <div class="mt-2">
              <label class="form-label" for="locationTypeRow">{{ rowText }}</label>
              <select class="form-select" name="locationTypeRow" [(ngModel)]="selectedPanelLocation.rowName"
                [disabled]="!authService.hasPermission('YardSearchEdit')" (change)="changeLocationType()">
                <option [ngValue]="''">Choose Row</option>
                <option *ngFor="let row of panelLocationTypeRows" [ngValue]="row.locationTypeRowName">
                  {{row.locationTypeRowName}}
                </option>
              </select>
            </div>
            <div class="mt-2" *ngIf="selectSection">
              <label class="form-label" for="locationTypeRowSection">{{ sectionText }}</label>
              <select class="form-select" name="locationTypeRowSection" [(ngModel)]="selectedPanelLocation.locationID"
                [disabled]="!authService.hasPermission('YardSearchEdit')">
                <option [ngValue]="0">Choose Section</option>
                <option *ngFor="let row of panelLocationTypeRowSections" [ngValue]="row.locationID">
                  {{row.locationTypeSectionName}}
                </option>
              </select>
            </div>
            <div class="mt-2" *ngIf="selectTrailer">
              <label class="form-label" for="trailer">Trailer</label>
              <select class="form-select" name="trailer" [(ngModel)]="selectedPanelLocation.trailerID"
                [disabled]="!authService.hasPermission('YardSearchEdit')">
                <option [ngValue]="null" selected>Choose Trailer</option>
                <option *ngFor="let trailer of yardTrailers" [ngValue]="trailer.trailerId">
                  {{trailer.trailerName}}
                </option>
              </select>
            </div>
            <div class="mt-2" *ngIf="selectTrailer">
              <input type="text" [(ngModel)]="selectedPanelLocation.trailerName" class="form-control"
                [disabled]="!authService.hasPermission('YardSearchEdit')" />
            </div>
            <div class="mt-2" *ngIf="selectStackIndex">
              <label class="form-label" for="stackIndex">Stack Index</label>
              <select class="form-select" name="stackIndex" [(ngModel)]="selectedPanelLocation.stackIndex"
                [disabled]="!authService.hasPermission('YardSearchEdit')">
                <option [ngValue]="null" selected>Choose Index</option>
                <option *ngFor="let stackIndex of stackIndexes" [ngValue]="stackIndex">
                  {{stackIndex}}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer mt-2">
        <button type="button" class="btn btn-danger me-md-2" (click)="closeModal()"
          *ngIf="authService.hasPermission('YardSearchEdit')">Cancel</button>
        <button type="submit" class="btn btn-primary" type="submit" [disabled]="isPreviousDisabled"
          (click)="updateMovePevious()">Previous</button>
        <button type="submit" class="btn btn-primary" type="submit" [disabled]="isNextDisabled"
          (click)="updateMoveNext()">Next</button>
        <button type="submit" class="btn btn-primary" type="submit" (click)="updateAndClose()">Close</button>
      </div>
    </div>
  </div>
</div>
<!-- End Move Panel Modal -->
<div class="row mt-2">
  <div class="col-lg-8 position-relative mb-3">
    <h3>PANEL LOCATION</h3>
    <div class="row mt-1">
      <div class="col-md-2 form-group mb-3">
        <input id="cast" type="number" pattern="[0-9]*" class="form-control" (keydown.enter)="searchPanelLocations()"
          placeholder="Cast" [(ngModel)]="castNumber" />
      </div>
      <div class="col-md-2 form-group mb-3">
        <input id="job" type="number" pattern="[0-9]*" class="form-control" (keydown.enter)="searchPanelLocations()"
          placeholder="Job" [(ngModel)]="jobNumber" />
      </div>
      <div class="col-md-2 form-group mb-3">
        <input id="mark" type="number" pattern="[0-9]*" class="form-control" (keydown.enter)="searchPanelLocations()"
          placeholder="Mark" [(ngModel)]="markNumber" />
      </div>
      <div class="col-md-4 form-group mb-3">
        <button type="button" class="btn btn-primary btn-sm me-md-2" [disabled]="invalidSearch()"
          (click)="searchPanelLocations()">
          <i class="fa-solid fa-magnifying-glass"></i>
          SEARCH
        </button>
        <button type="button" class="btn btn-danger btn-sm" [disabled]="invalidSearch()" (click)='clearSearch()'>
          <i class="fa-regular fa-circle-xmark"></i>
          CLEAR
        </button>
      </div>
    </div>
  </div>
  <div class="col-lg-12 position-relative stickyContainer">
    <table class="table table-bordered table-striped table-hover table-sm stickyBorderBottom" style="top: -1px;">
      <thead class="table-light sticky-top top-0">
        <tr>
          <th class="stickyBorder" scope="col" (click)="setOrder('castNumber')">
            Cast <span *ngIf="order === 'castNumber'" [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
            <span *ngIf="order === 'castNumber'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
            <span *ngIf="order !== 'castNumber'"><i class="fa-solid fa-sort"></i></span>
          </th>
          <th class="stickyBorder" scope="col" (click)="setOrder('job')">
            Job <span *ngIf="order === 'job'" [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
            <span *ngIf="order === 'job'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
            <span *ngIf="order !== 'job'"><i class="fa-solid fa-sort"></i></span>
          </th>
          <th class="stickyBorder" scope="col" (click)="setOrder('markNumber')">
            Mark <span *ngIf="order === 'markNumber'" [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
            <span *ngIf="order === 'markNumber'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
            <span *ngIf="order !== 'markNumber'"><i class="fa-solid fa-sort"></i></span>
          </th>
          <th class="stickyBorder" scope="col" (click)="setOrder('bedSeq')">
            SEQ <span *ngIf="order === 'bedSeq'" [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
            <span *ngIf="order === 'bedSeq'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
            <span *ngIf="order !== 'bedSeq'"><i class="fa-solid fa-sort"></i></span>
          </th>
          <th class="stickyBorder" scope="col" (click)="setOrder('shipSeq')">
            Ship SEQ <span *ngIf="order === 'shipSeq'" [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
            <span *ngIf="order === 'shipSeq'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
            <span *ngIf="order !== 'shipSeq'"><i class="fa-solid fa-sort"></i></span>
          </th>
          <th class="stickyBorder" scope="col" (click)="setOrder('castDate')">
            Cast Date <span *ngIf="order === 'castDate'" [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
            <span *ngIf="order === 'castDate'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
            <span *ngIf="order !== 'castDate'"><i class="fa-solid fa-sort"></i></span>
          </th>
          <th class="stickyBorder" scope="col" (click)="setOrder('qaStatusDescription')">
            Status <span *ngIf="order === 'qaStatusDescription'" [hidden]="reverse"><i
                class="fa-solid fa-sort-down"></i></span>
            <span *ngIf="order === 'qaStatusDescription'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
            <span *ngIf="order !== 'qaStatusDescription'"><i class="fa-solid fa-sort"></i></span>
          </th>
          <th class="stickyBorder" scope="col" (click)="setOrder('locationName')">
            Location <span *ngIf="order === 'locationName'" [hidden]="reverse"><i
                class="fa-solid fa-sort-down"></i></span>
            <span *ngIf="order === 'locationName'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
            <span *ngIf="order !== 'locationName'"><i class="fa-solid fa-sort"></i></span>
          </th>
          <th class="stickyBorder" scope="col" (click)="setOrder('loadNum')">
            Load <span *ngIf="order === 'loadNum'" [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
            <span *ngIf="order === 'loadNum'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
            <span *ngIf="order !== 'loadNum'"><i class="fa-solid fa-sort"></i></span>
          </th>
          <th class="stickyBorder" scope="col" (click)="setOrder('width')">
            Width <span *ngIf="order === 'width'" [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
            <span *ngIf="order === 'width'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
            <span *ngIf="order !== 'width'"><i class="fa-solid fa-sort"></i></span>
          </th>
          <th class="stickyBorder" scope="col" (click)="setOrder('length')">
            Length <span *ngIf="order === 'length'" [hidden]="reverse"><i class="fa-solid fa-sort-down"></i></span>
            <span *ngIf="order === 'length'" [hidden]="!reverse"><i class="fa-solid fa-sort-up"></i></span>
            <span *ngIf="order !== 'length'"><i class="fa-solid fa-sort"></i></span>
          </th>
          <th class="stickyBorder" scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let panel of panelLocations; track panel | orderBy: order:reverse:true">
          <td>
            {{ panel.castNumber }}
          </td>
          <td>
            {{ panel.jobNumber }}
          </td>
          <td>
            {{ panel.markNumber }}
          </td>
          <td>
            {{ panel.bedSeq }}
          </td>
          <td>
            {{ panel.shipSeq }}
          </td>
          <td>
            {{ panel.castDate | date: 'M/d/YYYY' }}
          </td>
          <td [ngStyle]="{'background-color': panel.qaStatusName }">
            {{ panel.qaStatusDescription }}
          </td>
          <td class="col-md-1">
            {{ panel.locationName }}
          </td>
          <td>
            {{ panel.loadNum }}
          </td>
          <td>
            {{ panel.width }}
          </td>
          <td>
            {{ panel.length }}
          </td>
          <td style="text-align: center;">
            <button class="btn btn-sm btn-success" (click)="openModal(panel)"
              *ngIf="authService.hasPermission('YardSearchEdit')" [disabled]="panel.loadedOnTrailerDateTime!=null">Edit</button>
            <button class="btn btn-sm btn-success" (click)="openModal(panel)"
              *ngIf="authService.hasPermission('YardSearchView')">View</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
