// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  backend: {
   //baseURL:"https://localhost:44415/api"

   baseURL:"https://yardmmappdev.fabconprecast.com/api"

    //baseURL:"https://172.16.3.133/api"

  },
  defaultCompanyId: 10,
  drawingNotFoundFile: "/assets/images/image_not_found.png",
  reportServer: "http://sqlsrv12DEV/ReportServer?"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
