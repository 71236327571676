import { Component, Inject, LOCALE_ID, OnInit, inject } from '@angular/core';
import { Shipping3plS3plService } from './shipping3pl-s3pl.service';
import { Shipping3plS3pl } from './shipping3pl-s3pl.model';
import { AdminHolidayScheduleService } from '../admin-holiday-schedule/admin-holiday-schedule.service';
import { forEach } from 'lodash';
import { Time, formatDate } from '@angular/common';
import { HOUR, MINUTE } from 'ngx-bootstrap/chronos/units/constants';
import { ErrorResponse } from '../error-handling/error-response.model';
import { ToastService, toastTypes } from '../toast/toast.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Shipping3plCustomLoad } from './shipping3pl-custom-load.model';
import { Shipping3plS3plUpdateTime } from './shipping3pl-s3pl-update-time-model';
import { AuthService } from '../authentication/auth.service';
import { Company } from '../controls/company-drop-down/company-model';
import { User } from '../authentication/user-model';

@Component({
  selector: 'app-shipping3pl-s3pl',
  templateUrl: './shipping3pl-s3pl.component.html',
  styleUrl: './shipping3pl-s3pl.component.css'
})
export class Shipping3plS3plComponent implements OnInit {
  adminHolidayScheduleService: AdminHolidayScheduleService = inject(AdminHolidayScheduleService);
  shipping3plS3plService: Shipping3plS3plService = inject(Shipping3plS3plService);
  shipping3plS3pls: Shipping3plS3pl[] = [];
  shipping3plCustomLoad = new Shipping3plCustomLoad(0, 0, 0, formatDate(new Date(), 'MM/dd/YYYY', this.locale));
  enteredJob = '';
  companies: Company[] = [];
  hasSelectedRow: boolean = false;
  adjustTimeForRestOfDay: boolean = false;
  applyDeliveryStartTimeEntireJob: boolean = false;
  rowDate: any;
  rowCrane: any;
  selectedRowLoadNumber: any;
  deliveryTime!: Date;
  customLoadDeliveryTime!: Date;
  selectedRow!: Shipping3plS3pl;
  appliedTime: string = '';
  appliedOptions = [
    {
      id: 1,
      text: "Adjust Time for the Rest of the Day?",
      value: 'DAY'
    },
    {
      id: 2,
      text: "Apply Time to Remaining Loads?",
      value: 'JOB'
    },
    {
      id: 3,
      text: "Apply Time to Selected Row?",
      value: 'ROW'
    }];
  authService = inject(AuthService);
  hasPerformedSearch: boolean = false;

  constructor(@Inject(LOCALE_ID) private locale: string, private toast: ToastService) { }

  ngOnInit(): void {
    this.appliedTime = this.appliedOptions[0].value;
    this.enteredJob = this.shipping3plS3plService.currentJobNumber();
    this.shipping3plCustomLoad.companyCode = this.authService.currentUserSig()!.companyId;

    if (this.enteredJob.length > 0) {
      this.onSearch3Pls();
    }

    let storedAuthenticatedUser = localStorage.getItem('authenticatedUser');

    if (storedAuthenticatedUser !== null) {
      let localUser: User = JSON.parse(storedAuthenticatedUser);
      console.log(localUser.userCompanies)
      this.companies = localUser.userCompanies;
    }

    this.deliveryTime = new Date();
    this.deliveryTime.setHours(7);
    this.deliveryTime.setMinutes(0);

    this.customLoadDeliveryTime = new Date();
    this.customLoadDeliveryTime.setHours(12);
    this.customLoadDeliveryTime.setMinutes(0);
  }

  public invalidSearch(): boolean {
    if (this.enteredJob == undefined || this.enteredJob.length == 0) {
      return true;
    }

    return false;
  }

  onSearch3Pls(): void {
    this.shipping3plS3plService.setJobNumber(this.enteredJob);

    this.load3PlsData();
  }

  private load3PlsData(): void {
    try {
      this.hasSelectedRow = false;

      this.shipping3plS3plService.get3Pls(this.enteredJob).subscribe({
        next: (shipping3plS3plsResult) => {
          this.shipping3plS3pls = shipping3plS3plsResult;

          this.shipping3plS3pls.forEach((value, index) => {
            if (value.craneMoveDelay > 0) {
              let cmInsert = new Shipping3plS3pl(value.jobLoadId, false, value.craneId, value.job, 0, "CM", '', 0, 0, 0, value.craneMoveDelay,
                0, value.deliveryDate, null, value.panelCount, value.craneName);
              this.shipping3plS3pls.splice(index + 1, 0, cmInsert);
            }
          });

        },
        error: (error) => {
          // Handle error cases
          console.error(error);
        }
      });
      this.hasPerformedSearch = true;
    } catch (error) {
      console.log(error)
    }
  }

  onSelectRow(selectedRow: Shipping3plS3pl) {
    this.selectedRow = selectedRow;
    this.hasSelectedRow = true;
  }

  invalidApplyToSelected(): boolean {
    if (!this.hasSelectedRow) {
      return true;
    }

    return false;
  }

  applyToSelectedRow() {

    if(this.selectedRow.load == "CM") { return; }

    let selectedRowDeliveryDate: Date = new Date(this.selectedRow.deliveryDate);

    if (this.appliedTime == "ROW") {
      selectedRowDeliveryDate.setHours(this.deliveryTime.getHours());
      selectedRowDeliveryDate.setMinutes(this.deliveryTime.getMinutes());
      selectedRowDeliveryDate.setSeconds(0);

      this.selectedRow.deliveryTime = selectedRowDeliveryDate;

      let rowUpdateTime: Shipping3plS3plUpdateTime = new Shipping3plS3plUpdateTime(this.selectedRow.jobLoadId, formatDate(selectedRowDeliveryDate, 'YYYY-MM-dd HH:mm:ss', this.locale));
      this.shipping3plS3plService.update3Pls(rowUpdateTime).subscribe((response: ErrorResponse) => {

        this.load3PlsData();

      }, (error: HttpErrorResponse) => {
        this.toast.initiate({
          title: error.error.name,
          content: error.error.message,
          type: toastTypes.error,
        });
      });
      return;
    }

    if (this.appliedTime == "DAY") {
      let selectedRowIndex: number = this.shipping3plS3pls.findIndex(x => this.selectedRow.jobLoadId == x.jobLoadId);
      let dayShipping3plS3pls: Shipping3plS3pl[] = this.shipping3plS3pls.filter((x, index) => x.deliveryDate == this.selectedRow.deliveryDate && index >= selectedRowIndex);

      this.setDateTimeForMultipleRows(dayShipping3plS3pls).then((adjustTimeForRestOfDay) => {

        adjustTimeForRestOfDay.forEach((dayRow) => {

          if (dayRow.load != "CM") {
            let dayUpdateTime: Shipping3plS3plUpdateTime = new Shipping3plS3plUpdateTime(dayRow.jobLoadId, formatDate(dayRow.deliveryTime!, 'YYYY-MM-dd HH:mm:ss', this.locale));
            this.shipping3plS3plService.update3Pls(dayUpdateTime).subscribe((response: ErrorResponse) => {

              this.load3PlsData();

            }, (error: HttpErrorResponse) => {
              this.toast.initiate({
                title: error.error.name,
                content: error.error.message,
                type: toastTypes.error,
              });
            });
          }
        });

      });
      return;
    }

    if (this.appliedTime == "JOB") {
      let jobShipping3plS3pls: Shipping3plS3pl[] = [];
      let selectedRowIndex: number = this.shipping3plS3pls.findIndex(x => this.selectedRow.jobLoadId == x.jobLoadId);
      this.shipping3plS3pls.forEach((currentRow, index) => {
        if (index >= selectedRowIndex) {
          jobShipping3plS3pls.push(currentRow);
        }
      });

      this.setDateTimeForMultipleRows(jobShipping3plS3pls).then((adjustTimeForRestOfJob) => {

        adjustTimeForRestOfJob.forEach((jobRow) => {
          if (jobRow.load != "CM") {
            let jobUpdateTime: Shipping3plS3plUpdateTime = new Shipping3plS3plUpdateTime(jobRow.jobLoadId, formatDate(jobRow.deliveryTime!, 'YYYY-MM-dd HH:mm:ss', this.locale));
            this.shipping3plS3plService.update3Pls(jobUpdateTime).subscribe((response: ErrorResponse) => {
              this.load3PlsData();

            }, (error: HttpErrorResponse) => {
              this.toast.initiate({
                title: error.error.name,
                content: error.error.message,
                type: toastTypes.error,
              });
            });
          }
        });

      });
      return;

    }
  }

  cancelCustomLoad() {
    this.shipping3plCustomLoad = new Shipping3plCustomLoad(0, this.authService.currentUserSig()!.companyId, 0, formatDate(new Date(), 'MM/dd/YYYY', this.locale));
    this.customLoadDeliveryTime = new Date();
    this.customLoadDeliveryTime.setHours(12);
    this.customLoadDeliveryTime.setMinutes(0);
  }

  saveCustomLoad() {
    let customLoadDeliveryDateTime: Date = this.setDateTime(this.shipping3plCustomLoad.deliveryDate, this.customLoadDeliveryTime);

    this.shipping3plCustomLoad.jobNumber = Number(this.enteredJob);
    this.shipping3plCustomLoad.weight = this.shipping3plCustomLoad.weight;
    this.shipping3plCustomLoad.deliveryDate = formatDate(customLoadDeliveryDateTime, 'YYYY-MM-dd HH:mm:ss', this.locale);

    this.shipping3plS3plService.add3PlsCustomLoad(this.shipping3plCustomLoad).subscribe((response: ErrorResponse) => {
      this.toast.initiate({
        title: response.name,
        content: response.message,
        type: response.statusCode == 200 || response.statusCode == 201 ? toastTypes.success : toastTypes.error,
      });

      this.shipping3plCustomLoad = new Shipping3plCustomLoad(0, this.authService.currentUserSig()!.companyId, 0, formatDate(new Date(), 'MM/dd/YYYY', this.locale));

      this.customLoadDeliveryTime = new Date();
      this.customLoadDeliveryTime.setHours(12);
      this.customLoadDeliveryTime.setMinutes(0);

      this.load3PlsData();

    }, (error: HttpErrorResponse) => {
      this.toast.initiate({
        title: error.error.name,
        content: error.error.message,
        type: toastTypes.error,
      });
    });
  }

  onCompanyChange(value: any) {
    this.shipping3plCustomLoad.companyCode = value.target.value;
  }

  deleteCustomLoad() {
    this.shipping3plS3plService.delete3PlsCustomLoad(Number(this.selectedRow.job), this.selectedRow.jobLoadId).subscribe((response: ErrorResponse) => {
      this.toast.initiate({
        title: response.name,
        content: response.message,
        type: response.statusCode == 200 || response.statusCode == 201 ? toastTypes.success : toastTypes.error,
      });

      this.load3PlsData();

    }, (error: HttpErrorResponse) => {
      this.toast.initiate({
        title: error.error.name,
        content: error.error.message,
        type: toastTypes.error,
      });
    });
  }

  invalidCustomLoad(): boolean {
    if (this.shipping3plCustomLoad.weight == 0) {
      return true;
    }

    return false;
  }

  private setDateTime(deliveryDate: string, deliveryTime: Date) {
    let setDeliveryDateTime: Date = new Date(deliveryDate)
    setDeliveryDateTime.setHours(deliveryTime.getHours());
    setDeliveryDateTime.setMinutes(deliveryTime.getMinutes());
    setDeliveryDateTime.setSeconds(0);

    return setDeliveryDateTime;
  }

  private async setDateTimeForMultipleRows(dayShipping3plS3pls: Shipping3plS3pl[]) {
    let setMinutes: number = 0;
    let addMinutes: number = 0;
    let minutes: number = 0;
    let previousDeliveryDate = formatDate(new Date(), 'MM-dd-YYYY', this.locale);

    dayShipping3plS3pls.forEach((currentRow, index) => {

      let deliveryDateTime: Date = new Date(currentRow.deliveryDate);

      deliveryDateTime.setDate(deliveryDateTime.getDate());
      deliveryDateTime.setHours(this.deliveryTime.getHours());
      deliveryDateTime.setMinutes(this.deliveryTime.getMinutes());
      deliveryDateTime.setSeconds(0);

      if (previousDeliveryDate !== formatDate(new Date(currentRow.deliveryDate), 'MM-dd-YYYY', this.locale)) {
        setMinutes = 0;
        addMinutes = 0;
        minutes = 0;
        previousDeliveryDate = formatDate(new Date(currentRow.deliveryDate), 'MM-dd-YYYY', this.locale);
        setMinutes = deliveryDateTime.getMinutes();
      }
      else if (currentRow.load == "CM") {
        if(index == 0)
        {
          setMinutes = setMinutes + dayShipping3plS3pls[index].timeToSet;
        }
        else{
          setMinutes = setMinutes + dayShipping3plS3pls[index - 1].timeToSet;
        }

        addMinutes = setMinutes;
      }
      else {
        minutes = setMinutes;
        if(index == 0)
        {
          setMinutes = setMinutes + dayShipping3plS3pls[index].timeToSet;
        }
        else{
          setMinutes = setMinutes + dayShipping3plS3pls[index - 1].timeToSet;
        }
      }

      if (currentRow.load != "CM") {
        addMinutes = setMinutes;
        deliveryDateTime.setMinutes(addMinutes);
        currentRow.deliveryTime = new Date(deliveryDateTime);
        previousDeliveryDate = formatDate(new Date(currentRow.deliveryDate), 'MM-dd-YYYY', this.locale);
        minutes = 0;
      }
    });

    return new Promise<Shipping3plS3pl[]>(resolve => {
      setTimeout(() => {
        resolve(dayShipping3plS3pls);
      }, 1000);
    });
  }

  clearJobNumber() {
    this.shipping3plS3plService.setJobNumber('');
  }
}
