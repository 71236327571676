<!-- Begin Create Custom Load Modal -->
<div class="modal fade" id="createCustomLoadModal" tabindex="-1" aria-labelledby="addcreateCustomLoadModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-sm modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="addcreateCustomLoadModalLabel">Create Custom Load</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="mb-3">
          <label for="createCustomLoadPlant" class="form-label">Plant</label>
          <select class="form-select" aria-label="Default select customLoadConfirmation"
            [(ngModel)]="shipping3plCustomLoad.companyCode" name="plant" required (change)="onCompanyChange($event)">
            <option *ngFor="let company of companies" [value]="company.companyId"
              [selected]="authService.currentUserSig()!.companyId == company.companyId">
              {{company.companyName}}
            </option>
          </select>
          <div id="createCustomLoadPlantFeedback" class="invalid-tooltip">
            Please select a Plant.
          </div>
        </div>
        <div class="mb-3 position-relative">
          <label class="form-label" for="createCustomLoadDescription">Weight</label>
          <input type="number" class="form-control" id="createCustomLoadDescription" name="description"
            aria-describedby="createCustomLoadDescription createCustomLoadDescriptionFeedback"
            [(ngModel)]="shipping3plCustomLoad.weight" required>
          <div id="createCustomLoadDescriptionFeedback" class="invalid-tooltip">
            Please enter Weight.
          </div>
        </div>
        <div class="mb-3 position-relative">
          <label for="createCustomLoadDate" class="form-label">Delivery Date</label>
          <input type="text" placeholder="Datepicker" class="form-control" bsDatepicker
            [bsConfig]="{ containerClass: 'theme-dark-blue', dateInputFormat: 'MM/DD/YYYY', selectFromOtherMonth: true, showWeekNumbers:false }"
            [(ngModel)]="shipping3plCustomLoad.deliveryDate" name="createCustomLoadDate" required>
          <div id="createCustomLoadDateFeedback" class="invalid-tooltip">
            Please select a date.
          </div>
        </div>

        <div class="mb-3 position-relative">
          <label class="form-label" for="createCustomLoadDescription">Delivery Time</label>
          <timepicker [showSpinners]="false" [(ngModel)]="customLoadDeliveryTime"></timepicker>
          <div id="createCustomLoadDescriptionFeedback" class="invalid-tooltip">
            Please enter Description.
          </div>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-danger me-md-2" data-bs-dismiss="modal"
            (click)="cancelCustomLoad()">Cancel</button>
          <button type="button" class="btn btn-primary" [disabled]="invalidCustomLoad()" data-bs-dismiss="modal"
            (click)="saveCustomLoad()">Save</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End Create Custom Load Modal -->

<!-- Begin Delete Modal -->
<div class="modal fade" id="customLoadConfirmationModal" tabindex="-1"
  aria-labelledby="customLoadConfirmationModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-md modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="customLoadConfirmationModalLabel">Delete Custom Load?</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        This will delete the custom load. Proceed?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-danger" data-bs-toggle="modal"
          data-bs-target="#customLoadConfirmationModal" (click)="deleteCustomLoad()">OK</button>
      </div>
    </div>
  </div>
</div>
<!-- End Delete Modal -->

<!-- Begin Shipping 3PL List -->
<div class="row mt-2">
  <div class="col-lg-8 position-relative">
    <h3>SHIPPING 3PL</h3>
    <div class="row">
      <div class="col-lg-4 mt-2">
        <input type="text" placeholder="Enter Job" class="form-control" [(ngModel)]="enteredJob" value="enteredJob"
          (click)="clearJobNumber()" (keydown.enter)="onSearch3Pls()">
      </div>
      <div class="col-lg-4 mt-2">
        <button type="button" class="btn btn-primary btn-sm" [disabled]="invalidSearch()" (click)='onSearch3Pls()'>
          <i class="fa-solid fa-magnifying-glass"></i>
          SEARCH
        </button>
      </div>
      <div class="row">
        <div class="col-lg-4 mt-2">
          <div *ngIf="shipping3plS3pls.length==0 && hasPerformedSearch" role="alert">No data found based on search
            criteria</div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-6 stickyContainer">
    <table class="table table-bordered table-striped table-hover table-sm stickyBorderBottom" id="shippingPl">
      <thead class="table-light sticky-top top-0">
        <tr>
          <th class="text-nowrap text-center stickyBorder">Crane</th>
          <th class="text-nowrap text-center stickyBorder">Load #</th>
          <th class="text-nowrap text-center stickyBorder">Plant</th>
          <th class="text-nowrap text-center stickyBorder">Wt</th>
          <th class="text-nowrap text-center stickyBorder">Wd</th>
          <th class="text-nowrap text-center stickyBorder">Len</th>
          <th class="text-nowrap text-center stickyBorder">Del. Date</th>
          <th class="text-nowrap text-center stickyBorder">Time</th>
          <th class="text-nowrap text-center stickyBorder">Del. Time</th>
        </tr>
      </thead>
      <tbody>
        <tr [ngClass]="shippingPl == selectedRow ? 'clickable highlightSelectedRow' : 'clickable'"
          *ngFor="let shippingPl of shipping3plS3pls" (click)='onSelectRow(shippingPl)'>
          <td>
            <span>{{shippingPl?.craneName}}</span>
          </td>
          <td>
            <div *ngIf="shippingPl.customLoad == true">
              <span data-bs-toggle="modal" data-bs-target="#customLoadConfirmationModal">{{shippingPl?.load}} <i
                  class="fa fa-trash-can" style="color: #dc3545;" title="Delete"></i></span>
            </div>
            <div *ngIf="shippingPl.customLoad == false">
              <span>{{shippingPl?.load}}</span>
            </div>
          </td>
          <td>
            <span>{{shippingPl?.castLocation}}</span>
          </td>
          <td>
            <span *ngIf="shippingPl.weight > 0">{{shippingPl?.weight}}</span>
          </td>
          <td>
            <span *ngIf="shippingPl.width > 0">{{shippingPl?.width | number: '1.2-2'}}</span>
          </td>
          <td>
            <span *ngIf="shippingPl.length > 0">{{shippingPl?.length | number: '1.2-2'}}</span>
          </td>
          <td>
            <span>{{shippingPl?.deliveryDate | date:'MM/dd/yyyy'}}</span>
          </td>
          <td>
            <span>{{shippingPl?.timeToSet}}</span>
          </td>
          <td>
            <span *ngIf="shippingPl.deliveryTime !== null">{{shippingPl?.deliveryTime | date:'HH:mm'}}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="col-lg-2 border mx-2" style="height: 275px;">
    <div class="mt-2">
      <label class="mb-1">Delivery Time: </label>
      <timepicker [showSpinners]="false" [(ngModel)]="deliveryTime"></timepicker>
    </div>
    <div class="mt-2">
      <div class="form-check" *ngFor="let option of appliedOptions">
        <input class="form-check-input" type="radio" name="radioAppliedTime" id="{{option.id}}" [value]="option.value"
          [(ngModel)]="appliedTime">
        <label class="form-check-label" for="option">
          {{option.text}}
        </label>
      </div>
    </div>
    <div class="mt-2">
      <button type="button" class="btn btn-danger btn-sm" [disabled]="invalidApplyToSelected() && appliedTime == 'DAY'"
        (click)="applyToSelectedRow()">
        <i class="fa-regular fa-square-check"></i>
        APPLY
      </button>
    </div>
    <hr />
    <div class="mt-2 mb-2">
      <button type="button" class="btn btn-success btn-sm" data-bs-toggle="modal"
        data-bs-target="#createCustomLoadModal" [disabled]="invalidSearch()">
        <i class="fa-solid fa-square-plus"></i>
        CREATE CUSTOM LOAD
      </button>
    </div>
  </div>
</div>
<!-- End Shipping 3PL List -->
