import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AdminTrailer } from './admin-trailer.model';
import { ErrorResponse } from '../error-handling/error-response.model';

@Injectable({
  providedIn: 'root'
})
export class AdminTrailersService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
    this.baseUrl = environment.backend.baseURL;
  }

  getTrailers(companyId: number, trailerType: string): Observable<any> {
    const options = { params: new HttpParams(), HttpHeaders: new HttpHeaders({'Content-Type': 'application/json'}) };
    try {
      return this.http.get<AdminTrailer>(`${this.baseUrl}/admin/trailers?companyId=${companyId}&&trailerType=${trailerType}`, options);
    } catch (error) {
      console.log(error);
      return new Observable<any>();
    }
  }

  addTrailerLocation(adminTrailer: AdminTrailer): Observable<ErrorResponse> {
    const options = { params: new HttpParams(), HttpHeaders: new HttpHeaders({'Content-Type': 'application/json'}) };

    return this.http.post<ErrorResponse>(`${this.baseUrl}/admin/trailers`, adminTrailer, options);
  }

  deleteTrailerLocation(trailerKey: number): Observable<ErrorResponse> {

    const options = { params: new HttpParams(), HttpHeaders: new HttpHeaders({'Content-Type': 'application/json'}) };
    return this.http.delete<ErrorResponse>(`${this.baseUrl}/admin/trailers/${trailerKey}`, options);
  }
}
