import { PanelLocationTypeRowSection } from './panel-location-type-row-section.model';
import { PanelLocationTypeRow } from './panel-location-type-row.model';
import { environment } from 'src/environments/environment';
import { PanelLocationService } from './panel-location.service';
import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { PanelLocation } from './panel-location.model';
import { OrderPipe } from "../controls/order-pipe/order.pipe";
import { BsModalRef, BsModalService, ModalDirective, ModalOptions } from 'ngx-bootstrap/modal';
import { ShippingYardLocation } from '../shipping-yard/shipping-yard-location.model';
import { ShippingYardLocationType } from '../shipping-yard/shipping-yard-location-type.model';
import { ShippingYardService } from '../shipping-yard/shipping-yard.service';
import { ShippingYardTrailer } from '../shipping-yard/shipping-yard-trailer.model';
import { ToastService, toastTypes } from '../toast/toast.service';
import { ErrorResponse } from '../error-handling/error-response.model';
import { AuthService } from '../authentication/auth.service';
import { LoadingModalComponent } from '../controls/loading-modal/loading-modal.component';

@Component({
  selector: 'app-panel-location',
  templateUrl: './panel-location.component.html',
  styleUrl: './panel-location.component.css'
})
export class PanelLocationComponent implements OnInit {
  @ViewChild(ModalDirective, { static: false }) modal?: ModalDirective;

  castNumber: number | null = null;
  jobNumber: number | null = null;
  markNumber: number | null = null;
  panelLocationService: PanelLocationService = inject(PanelLocationService);
  panelLocations!: PanelLocation[];
  order: string = "job";
  reverse: boolean = false;
  selectedPanelLocation!: PanelLocation;
  selectedPanelLocationImage: string = '';
  imageBase64: string = '';
  yardLocations: ShippingYardLocation[] = [];
  yardLocationTypes: ShippingYardLocationType[] = [];
  yardTrailers: ShippingYardTrailer[] = [];
  shippingYardService: ShippingYardService = inject(ShippingYardService);
  stackIndexes: (number)[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  panelLocationTypeRows: PanelLocationTypeRow[] = [];
  panelLocationTypeRowSections: PanelLocationTypeRowSection[] = [];
  sectionText: string = '';
  rowText: string = '';
  selectSection: boolean = false;
  selectTrailer: boolean = false;
  selectStackIndex: boolean = false;
  isLoading: boolean = false;
  isPreviousDisabled: boolean = true;
  isNextDisabled: boolean = false;
  errorMessage: string = '';
  authService = inject(AuthService);

  bsModalRef?: BsModalRef;

  constructor(private toast: ToastService, private orderPipe: OrderPipe, private modalService: BsModalService) {
  }
  ngOnInit(): void {
  }

  searchPanelLocations() {

    this.openSeachModal('Loading Panel Location...');

    this.isLoading = true;
    let searchCastNumber: string = this.castNumber == undefined ? '' : this.castNumber.toString();
    let searchJobNumber: string = this.jobNumber == undefined ? '' : this.jobNumber.toString();
    let searchMarkNumber: string = this.markNumber == undefined ? '' : this.markNumber.toString();

    this.panelLocationService.getPanelLocations(this.authService.currentUserSig()!.companyId, searchCastNumber, searchJobNumber, searchMarkNumber).subscribe({
      next: (panelLocationResult) => {
        this.panelLocations = panelLocationResult;
        this.setOrder(this.order, true);
        this.isLoading = false;
        this.closeSearchModal();
      },
      error: (error) => {
        // Handle error cases
        this.isLoading = false;
        console.error(error);
        this.closeSearchModal();
      }
    });
  }

  setOrder(value: string, isSaved: boolean = false) {

    if (this.order === value && !isSaved) {
      this.reverse = !this.reverse;
    }

    this.order = value;

    switch (value) {
      case "castNumber":
        this.panelLocations = this.reverse ? this.panelLocations.sort((a, b) => b.castNumber.localeCompare(a.castNumber)) : this.panelLocations.sort((a, b) => a.castNumber.localeCompare(b.castNumber));
        break;
      case "job":
        this.panelLocations = this.reverse ? this.panelLocations.sort((a, b) => b.jobNumber - a.jobNumber) : this.panelLocations.sort((a, b) => a.jobNumber - b.jobNumber);
        break;
      case "markNumber":
        this.panelLocations = this.reverse ? this.panelLocations.sort((a, b) => b.markNumber - a.markNumber) : this.panelLocations.sort((a, b) => a.markNumber - b.markNumber);
        break;
      case "bedSeq":
        this.panelLocations = this.reverse ? this.panelLocations.sort((a, b) => b.bedSeq - a.bedSeq) : this.panelLocations.sort((a, b) => a.bedSeq - b.bedSeq);
        break;
      case "shipSeq":
        this.panelLocations = this.reverse ? this.panelLocations.sort((a, b) => b.shipSeq.localeCompare(a.shipSeq)) : this.panelLocations.sort((a, b) => a.shipSeq.localeCompare(b.shipSeq));
        break;
      case "castDate":
        this.panelLocations = this.reverse ? this.panelLocations.sort((a, b) => new Date(b.castDate).getTime() - new Date(a.castDate).getTime()) : this.panelLocations.sort((a, b) => new Date(a.castDate).getTime() - new Date(b.castDate).getTime());
        break;
      case "qaStatusDescription":
        this.panelLocations = this.reverse ? this.panelLocations.sort((a, b) => b.qaStatusDescription.localeCompare(a.qaStatusDescription)) : this.panelLocations.sort((a, b) => a.qaStatusDescription.localeCompare(b.qaStatusDescription));
        break;
      case "locationName":
        this.panelLocations = this.reverse ? this.panelLocations.sort((a, b) => b.locationName.localeCompare(a.locationName)) : this.panelLocations.sort((a, b) => a.locationName.localeCompare(b.locationName));
        break;
      case "loadNum":
        this.panelLocations = this.reverse ? this.panelLocations.sort((a, b) => b.loadNum.localeCompare(a.loadNum)) : this.panelLocations.sort((a, b) => a.loadNum.localeCompare(b.loadNum));
        break;
      case "width":
        this.panelLocations = this.reverse ? this.panelLocations.sort((a, b) => b.width.localeCompare(a.width)) : this.panelLocations.sort((a, b) => a.width.localeCompare(b.width));
        break;
      case "length":
        this.panelLocations = this.reverse ? this.panelLocations.sort((a, b) => b.length.localeCompare(a.length)) : this.panelLocations.sort((a, b) => a.length.localeCompare(b.length));
        break;

      default:
        break;
    }
  }

  async openModal(panel: PanelLocation) {
    this.loadModal(panel);
  }

  closeModal() {
    this.modal?.hide();
    this.selectedPanelLocation = ({} as PanelLocation)
  }

  changeLocationType() {
    let locationType = this.yardLocationTypes.find(f => f.locationTypeId == this.selectedPanelLocation.locationTypeID)

    if (locationType != null) {
      this.sectionText = locationType.sectionLabel;
      this.rowText = locationType.rowLabel;

      this.selectSection = (locationType.locationTypeName != 'On Trailer' && locationType.locationTypeName != 'Temp Location');
      this.selectTrailer = locationType.locationTypeName == 'On Trailer';
      this.selectStackIndex = (locationType.locationTypeName != 'On Trailer' && locationType.locationTypeName != 'Temp Location');

      // Hide tertiary option when we don't have an applicable section type
      if (locationType.sectionLabel == null) {
        this.selectSection = false;
      }

      this.panelLocationService.getPanelLocationTypeRows(this.authService.currentUserSig()!.companyId, this.selectedPanelLocation.locationTypeID!).subscribe({
        next: (result) => {
          this.panelLocationTypeRows = result;

          if (this.selectedPanelLocation.rowName.length > 0) {
            this.panelLocationService.getPanelLocationTypeRowSections(this.authService.currentUserSig()!.companyId, this.selectedPanelLocation.locationTypeID!, this.selectedPanelLocation.rowName).subscribe({
              next: (result) => {
                this.panelLocationTypeRowSections = result;
                if (locationType?.locationTypeName == "Temp Location") {
                  this.selectedPanelLocation.locationID = this.panelLocationTypeRowSections[0]?.locationID;
                }
              },
              error: (error) => {
                // Handle error cases
                console.error(error);
              }
            });
          }
        },
        error: (error) => {
          // Handle error cases
          console.error(error);
        }
      });

    }
  }

  async loadModal(panel: PanelLocation) {
    this.selectSection = true;
    this.selectTrailer = false;
    this.selectStackIndex = true;

    this.selectedPanelLocation = panel;

    this.setPrevNextEnabled();

    this.panelLocationService.getPanelImage(panel.jobNumber.toString(), panel.markNumber.toString()).subscribe((panelImageResult) => {

      this.selectedPanelLocationImage = panelImageResult.body == "" ? environment.drawingNotFoundFile : panelImageResult.body;
    }, error => console.error(error));

    await this.loadTrailers();

    this.shippingYardService.getYardLocationTypes(this.authService.currentUserSig()!.companyId).subscribe(locationTypeResult => {
      this.yardLocationTypes = locationTypeResult;

      if (this.selectedPanelLocation.locationTypeID !== undefined) { this.changeLocationType(); }

    }, error => console.error(error));

    this.modal?.show();
  }

  async loadTrailers() {
    this.shippingYardService.getYardTrailers(this.authService.currentUserSig()!.companyId, 'YARD').subscribe({
      next: (result) => {
        result = result.sort(function (a, b) {
          return a.trailerName.localeCompare(b.trailerName, undefined, {
            numeric: true,
            sensitivity: 'base'
          });
        });

        this.yardTrailers = result;
      },
      error: (error) => {
        // Handle error cases
        console.error(error);
      },
      complete: () => {
        // Handle completion cases
      }
    });
  }

  updateMoveNext() {

    this.getSectionName();

    if (!this.isModalValid()) { return; }

    if(this.selectTrailer){
    this.selectedPanelLocation.locationID = this.panelLocationTypeRowSections.find(f => f.locationTypeID == this.selectedPanelLocation.locationTypeID
      && f.locationTypeRowName == this.selectedPanelLocation.rowName)
      ?.locationID!; //&& f.locationTypeSectionName == this.selectedPanelLocation.sectionName
    }

    this.panelLocationService.updatePanelLocation(this.selectedPanelLocation.companyID, this.selectedPanelLocation.pieceID, this.selectedPanelLocation.locationID,
      this.selectedPanelLocation.trailerID!, this.selectedPanelLocation.trailerName, this.selectedPanelLocation.stackIndex!).subscribe({
        next: (response: ErrorResponse) => {

          this.toast.initiate({
            title: response.name,
            content: response.message,
            type: response.statusCode == 200 || response.statusCode == 201 ? toastTypes.success : toastTypes.error,
          });

          let nextIndex: number = this.panelLocations.findIndex(x => this.selectedPanelLocation.pieceID == x.pieceID);

          nextIndex += 1;
          this.selectedPanelLocation = this.panelLocations[nextIndex];

          this.changeLocationType();
          this.setPrevNextEnabled();
          this.searchPanelLocations();
        },
        error: (error) => {
          this.toast.initiate({
            title: error.error.name,
            content: error.error.message,
            type: toastTypes.error,
          });
        }
      });
  }

  updateMovePevious() {

    this.getSectionName();
    if (!this.isModalValid()) { return; }

    if(this.selectTrailer){
      this.selectedPanelLocation.locationID = this.panelLocationTypeRowSections.find(f => f.locationTypeID == this.selectedPanelLocation.locationTypeID
                                              && f.locationTypeRowName == this.selectedPanelLocation.rowName)
                                              ?.locationID!;
    }

    this.panelLocationService.updatePanelLocation(this.selectedPanelLocation.companyID, this.selectedPanelLocation.pieceID, this.selectedPanelLocation.locationID,
      this.selectedPanelLocation.trailerID!, this.selectedPanelLocation.trailerName, this.selectedPanelLocation.stackIndex!).subscribe({
        next: (response: ErrorResponse) => {

          this.toast.initiate({
            title: response.name,
            content: response.message,
            type: response.statusCode == 200 || response.statusCode == 201 ? toastTypes.success : toastTypes.error,
          });

          let nextIndex: number = this.panelLocations.findIndex(x => this.selectedPanelLocation.pieceID == x.pieceID);
          nextIndex = nextIndex - 1;

          this.selectedPanelLocation = this.panelLocations[nextIndex];
          this.changeLocationType();
          this.setPrevNextEnabled();
          this.searchPanelLocations();
        },
        error: (error) => {
          this.toast.initiate({
            title: error.error.name,
            content: error.error.message,
            type: toastTypes.error,
          });
        }
      });
  }

  public invalidSearch(): boolean {

    if (this.authService.currentUserSig()!.companyId == 0) { return true; }

    if (this.castNumber !== null || this.jobNumber !== null || this.markNumber !== null) {
      return false;
    }

    return true;
  }

  public clearSearch(): void {

    this.castNumber = null;
    this.jobNumber = null;
    this.markNumber = null
    this.panelLocations = [];
  }

  async setPrevNextEnabled() {
    let recordIndex = this.panelLocations.findIndex(x => this.selectedPanelLocation.pieceID == x.pieceID);

    this.isPreviousDisabled = recordIndex == 0 ? true : false;
    this.isNextDisabled = recordIndex + 1 == this.panelLocations.length ? true : false;
  }

  isModalValid(): boolean {
    this.errorMessage = '';

    if ((this.selectedPanelLocation.locationTypeID == null) || (this.selectedPanelLocation.locationTypeID == 0)) {
      this.errorMessage = "Please select a Location Type.";
    }
    else if (this.selectSection && this.selectedPanelLocation.sectionName.length == 0) { //&&  Site.isNullOrWhitespace(results.LocationSectionID)
      this.errorMessage = "Please select a " + this.sectionText + ".";
    }
    else if (this.selectTrailer && this.selectedPanelLocation.trailerID == null
      && this.selectedPanelLocation.trailerName.length == 0) {
      this.errorMessage = "Please select a Trailer.";
    }
    else if (this.selectedPanelLocation.rowName.length == 0) {
      this.errorMessage = "Please select a " + this.rowText + ".";
    }

    if (this.errorMessage != '') {
      return false;
    }
    else {
      return true;
    }
  }

  updateAndClose() {

    this.getSectionName();

    if (!this.isModalValid()) { return; }



    if(this.selectTrailer){
      this.selectedPanelLocation.locationID = this.panelLocationTypeRowSections.find(f => f.locationTypeID == this.selectedPanelLocation.locationTypeID
        && f.locationTypeRowName == this.selectedPanelLocation.rowName)
        ?.locationID!;
    }

    this.panelLocationService.updatePanelLocation(this.selectedPanelLocation.companyID, this.selectedPanelLocation.pieceID, this.selectedPanelLocation.locationID,
      this.selectedPanelLocation.trailerID, this.selectedPanelLocation.trailerName, this.selectedPanelLocation.stackIndex).subscribe({
        next: (response: ErrorResponse) => {

          this.toast.initiate({
            title: response.name,
            content: response.message,
            type: response.statusCode == 200 || response.statusCode == 201 ? toastTypes.success : toastTypes.error,
          });

          this.closeModal();
          this.searchPanelLocations();
        },
        error: (error) => {
          this.toast.initiate({
            title: error.error.name,
            content: error.error.message,
            type: toastTypes.error,
          });
        }
      });
  }

  openSeachModal(message: string) {
    const initialState: ModalOptions = {
      initialState: {
        message: message
      }
    };
    this.bsModalRef = this.modalService.show(LoadingModalComponent, Object.assign(initialState, { class: 'modal-lg modal-dialog-centered' }));
  }

  closeSearchModal() {
    this.modalService.hide();
  }

  getSectionName() {
    this.selectedPanelLocation.sectionName = this.panelLocationTypeRowSections.find(x => x.locationID == this.selectedPanelLocation.locationID)?.locationTypeSectionName!;

    if (this.selectedPanelLocation.sectionName == undefined) { this.selectedPanelLocation.sectionName = ''; }
  }
}
