import { Inject, Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable, map } from "rxjs";
import { ApiProjectResponse } from "./fieldPlanSearchResult.model";
import { environment } from 'src/environments/environment';
import { FieldPlanUpdateProperties } from "./field-plan-update-properties.model";
import { FieldPlanFabconFridayUpdate } from "./field-plan-fabcon-friday-update.model";
import { FieldPlanShipDateUpdate } from "./field-plan-shipdate-update.model";
import { FieldPlanMinToSetUpdate } from "./field-plan-detail/field-plan-mintoset-update.model";
import { MobilizationUpdate } from "./field-plan-detail/mobilization-update.model";
import { FieldPlanCreateDay } from "./field-plan-detail/field-plan-create-day.model";
import { FieldPlanSwitchCrane } from "./field-plan-detail/field-plan-switch-crane.model";
import { FieldPlanCraneMove } from "./field-plan-detail/field-plan-move-crane.model";
import { ErrorResponse } from "../error-handling/error-response.model";
import { FieldPlanReassignPanelsUpdate } from "./field-plan-detail/field-plan-reassign-panels-update";
import { FieldPlanResortLoadsUpdate } from "./field-plan-detail/field-plan-resort-loads-update";
import { FieldPlanResortPanelsUpdate } from "./field-plan-detail/field-plan-resort-panels-update";

@Injectable({
  providedIn: 'root'
})
export class FieldPlanDataService {
  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
    this.baseUrl = environment.backend.baseURL;
  }

  getFieldPlanProjectSearchResult(jobNumber: number): Observable<ApiProjectResponse> {
    const options = { params: new HttpParams().set('jobNumber', jobNumber), HttpHeaders: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    try {
      return this.http.get<any>(`${this.baseUrl}/fieldplan`, options).pipe(map((searchResults) => {
        return searchResults;
      }))
    } catch (error) {
      console.log(error)
      return new Observable<any>();
    }
  }

  getFieldPlanProjectErector(projectId: string): Observable<string> {
    const options = { params: new HttpParams(), HttpHeaders: new HttpHeaders({'Content-Type': 'application/json'}) };

    try {
      return this.http.get<string>(`${this.baseUrl}/fieldplan/erector?projectId=${projectId}`, options);
    } catch (error) {
      console.log(error);
      return new Observable<any>();
    }
  }

  getPanelTypes(): Observable<any> {
    const options = { params: new HttpParams(), HttpHeaders: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    try {
      return this.http.get<any>(`${this.baseUrl}/fieldplan/panelTypes`, options).pipe(map((panelTypes) => {
        return panelTypes;
      }))

    } catch (error) {
      console.log(error)
      return new Observable<any>();
    }
  }

  updateMaxWeight(fieldPlanProperties: FieldPlanUpdateProperties): Observable<any> {
    const options = { params: new HttpParams(), HttpHeaders: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    try {
      return this.http.put<any>(`${this.baseUrl}/fieldplan/updateMaxWeight`, fieldPlanProperties, options);
    } catch (error) {
      console.log(error)
      return new Observable<any>();
    }
  }

  updateCraneCount(fieldPlanProperties: FieldPlanUpdateProperties): Observable<any> {
    const options = { params: new HttpParams(), HttpHeaders: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    try {
      return this.http.put<any>(`${this.baseUrl}/fieldplan/updateCraneCount`, fieldPlanProperties, options);
    } catch (error) {
      console.log(error)
      return new Observable<any>();
    }
  }

  recalculateUnmobilized(jobNumber: number): Observable<any> {
    const options = { params: new HttpParams(), HttpHeaders: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    try {
      return this.http.put<any>(`${this.baseUrl}/fieldplan/recalculateUnmobilized/${jobNumber}`, options);
    } catch (error) {
      console.log(error)
      return new Observable<any>();
    }
  }

  recalculateJob(jobNumber: number): Observable<any> {
    const options = { params: new HttpParams(), HttpHeaders: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    try {
      return this.http.put<any>(`${this.baseUrl}/fieldplan/recalculateJob/${jobNumber}`, options);
    } catch (error) {
      console.log(error)
      return new Observable<any>();
    }
  }

  approveJob(jobNumber: number, userName: string): Observable<any> {
    const options = { params: new HttpParams(), HttpHeaders: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    try {
      return this.http.put<ErrorResponse>(`${this.baseUrl}/fieldplan/approveJob/${jobNumber}/${userName}`, options);
    } catch (error) {
      console.log(error)
      return new Observable<ErrorResponse>();
    }
  }

  changedShipDate(jobNumber: number, craneId: number, oldShipDate: Date, newShipDate: Date): Observable<any> {
    const options = { params: new HttpParams(), HttpHeaders: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const shipDateParms: FieldPlanShipDateUpdate = { CraneNumber: craneId, OldShipDate: oldShipDate, NewShipDate: newShipDate };

    try {
      return this.http.put<any>(`${this.baseUrl}/fieldplan/shipDateChanged/${jobNumber}`, shipDateParms, options);
    } catch (error) {
      console.log(error)
      return new Observable<any>();
    }
  }

  changeFabconFriday(jobNumber: number, addedDates: Date[], removedDates: Date[]): Observable<any> {
    let params = new HttpParams();
    let ffDates: FieldPlanFabconFridayUpdate = { AddedDates: addedDates, RemovedDates: removedDates }

    const options = { params: params, HttpHeaders: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    try {
      return this.http.put<any>(`${this.baseUrl}/fieldplan/applyFabconFridayChange?JobNumber=${jobNumber}`, ffDates, options).pipe();
    } catch (error) {
      console.log(error)
      return new Observable<any>();
    }
  }

  resortPanels(jobNumber: number, targetCraneNumber: number, targetShipDate: Date, nextLoad: boolean, markNumbers: string[], prevMarkNumber: string): Observable<any> {
    let params = new HttpParams();
    const options = { params: params, HttpHeaders: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    let resortPanels: FieldPlanResortPanelsUpdate = { jobNumber: jobNumber, targetCraneNumber: targetCraneNumber, targetShipDate: targetShipDate, markNumbers: markNumbers, prevMarkNumber: prevMarkNumber, nextLoad: nextLoad }
    try {
      return this.http.put<ErrorResponse>(`${this.baseUrl}/fieldplan/resortPanels?JobNumber=${jobNumber}`, resortPanels, options);
    } catch (error) {
      console.log(error)
      return new Observable<ErrorResponse>();
    }
  }

  resortLoads(jobNumber: number, targetCraneNumber: number, targetShipDate: Date, loadNumbers: number[], prevLoadNumber: string): Observable<any> {
    let params = new HttpParams();
    const options = { params: params, HttpHeaders: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    let resortLoads: FieldPlanResortLoadsUpdate = { jobNumber: jobNumber, targetCraneNumber: targetCraneNumber, targetShipDate: targetShipDate, loadNumbers: loadNumbers, prevLoadNumber: prevLoadNumber }
    try {
      return this.http.put<ErrorResponse>(`${this.baseUrl}/fieldplan/resortLoads?JobNumber=${jobNumber}`, resortLoads, options);
    } catch (error) {
      console.log(error)
      return new Observable<ErrorResponse>();
    }
  }


  //minutes to set update
  applyFieldPlanPanelChanges(jobNumber: number, minToSet: number, selectedPanels: string[]): Observable<any> {
    let params = new HttpParams();
    const options = { params: params, HttpHeaders: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    let mtsUpdates: FieldPlanMinToSetUpdate = { jobNumber: jobNumber, selectedPanels: selectedPanels, minutesToSet: minToSet }
    try {
      return this.http.put<any>(`${this.baseUrl}/fieldplan/applyFieldPlanPanelChanges/${jobNumber}`, mtsUpdates, options);
    } catch (error) {
      console.log(error)
      return new Observable<any>();
    }
  }

  reassignPanels(jobNumber: number, craneNumber: number, shipDate: Date, desiredPanelCount: number): Observable<any> {
    let params = new HttpParams();
    const options = { params: params, HttpHeaders: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    let reassignUpdates: FieldPlanReassignPanelsUpdate = { jobNumber: jobNumber, craneNumber: craneNumber, shipDate: shipDate, panelCount: desiredPanelCount }
    try {
      return this.http.put<ErrorResponse>(`${this.baseUrl}/fieldplan/reassignPanels?JobNumber=${jobNumber}`, reassignUpdates, options);
    } catch (error) {
      console.log(error)
      return new Observable<ErrorResponse>();
    }
  }

  reverseOrderFieldPlanPanels(jobNumber: number, craneNumber: number, selectedPanels: string[]): Observable<any> {
    let params = new HttpParams();
    const options = { params: params, HttpHeaders: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    try {
      return this.http.put<ErrorResponse>(`${this.baseUrl}/fieldplan/reverseOrderPanels?JobNumber=${jobNumber}&&CraneNumber=${craneNumber}`, selectedPanels, options);
    } catch (error) {
      console.log(error)
      return new Observable<ErrorResponse>();
    }
  }

  splitLoads(jobNumber: number, craneNumber: number, selectedPanels: string[]): Observable<any> {
    let params = new HttpParams();
    const options = { params: params, HttpHeaders: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    try {
      return this.http.put<ErrorResponse>(`${this.baseUrl}/fieldplan/splitLoad?JobNumber=${jobNumber}&&CraneNumber=${craneNumber}`, selectedPanels, options);
    } catch (error) {
      console.log(error)
      return new Observable<ErrorResponse>();
    }
  }

  createMobilization(jobNumber: number, craneNumber: number, shipDate: Date, selectedPanel: string, userName: string): Observable<any> {
    let params = new HttpParams();
    const options = { params: params, HttpHeaders: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    let mobilizationModel: MobilizationUpdate = { jobNumber: jobNumber, craneNumber: craneNumber, selectedPanel: selectedPanel, shipDate: shipDate, userName: userName };

    try {
      return this.http.put<ErrorResponse>(`${this.baseUrl}/fieldplan/createMobilization?JobNumber=${jobNumber}&&CraneNumber=${craneNumber}`, mobilizationModel, options);
    } catch (error) {
      console.log(error)
      return new Observable<ErrorResponse>();
    }
  }

  createDay(jobNumber: number, craneNumber: number, shipDate: Date, selectedLoad: number): Observable<any> {
    let params = new HttpParams();
    const options = { params: params, HttpHeaders: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    let createDayModel: FieldPlanCreateDay = { jobNumber: jobNumber, craneNumber: craneNumber, loadNumber: selectedLoad, targetDate: shipDate };

    try {
      return this.http.post<ErrorResponse>(`${this.baseUrl}/fieldplan/createDay?JobNumber=${jobNumber}`, createDayModel, options);
    } catch (error) {
      console.log(error)
      return new Observable<ErrorResponse>();
    }
  }

  switchCrane(jobNumber: number, craneNumber: number, shipDate: Date, selectedPanels: string[]): Observable<any> {
    let params = new HttpParams();
    const options = { params: params, HttpHeaders: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    const switchCraneModel: FieldPlanSwitchCrane = { jobNumber: jobNumber, craneNumber: craneNumber, selectedPanels: selectedPanels, shipDate: shipDate };

    try {
      return this.http.put<ErrorResponse>(`${this.baseUrl}/fieldplan/switchCrane?JobNumber=${jobNumber}`, switchCraneModel, options);
    } catch (error) {
      console.log(error)
      return new Observable<ErrorResponse>();
    }
  }

  getCraneDelay(jobNumber: number, craneNumber: number, selectedLoad: number): Observable<FieldPlanCraneMove> {
    let params = new HttpParams();
    const options = { params: params, HttpHeaders: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    try {
      return this.http.get<FieldPlanCraneMove>(`${this.baseUrl}/fieldplan/getCraneDelay?JobNumber=${jobNumber}&&CraneNumber=${craneNumber}&&LoadNumber=${selectedLoad}`, options)
        .pipe(map((craneDelay) => {
          return craneDelay
        }))
    } catch (error) {
      console.log(error)
      return new Observable<FieldPlanCraneMove>();
    }
  }

  setCraneDelay(jobNumber: number, craneNumber: number, selectedLoad: number, craneMoveDelay: number): Observable<any> {
    let params = new HttpParams();
    const options = { params: params, HttpHeaders: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const moveCraneModel: FieldPlanCraneMove = { job: jobNumber, crane: craneNumber, loadNum: selectedLoad, craneMoveDelay: craneMoveDelay };

    try {
      return this.http.put<ErrorResponse>(`${this.baseUrl}/fieldplan/setCraneDelay?JobNumber=${jobNumber}`, moveCraneModel, options);
    } catch (error) {
      console.log(error)
      return new Observable<any>();
    } _
  }

  sendFieldPlanModifiedAlert(projectNumber: number, comments: string, userName: string): Observable<any> {
    const options = { params: new HttpParams(), HttpHeaders: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    console.log('sendFieldPlanModifiedAlert-vals', projectNumber, comments, userName)
    try {
      return this.http.post<ErrorResponse>(`${this.baseUrl}/fieldplan/fieldplanmodifiedalert?projectNumber=${projectNumber}&&comments=${comments}&&userName=${userName}`, options);
    } catch (error) {
      console.log(error);
      return new Observable<any>();
    }
  }
}
