<div *ngIf="isInitialized">
  <div class="modal-header">
    <h4 class="modal-title pull-left">Confirm</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="modal-body">
      <div>{{dateMessage}}</div>
      <div class="row">
        <input type="text" placeholder="Datepicker" class="form-control" bsDatepicker
               [(ngModel)]="currentShipDate" name="shipDate"
               [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', containerClass: 'theme-dark-blue', selectFromOtherMonth: true, showWeekNumbers:false }"
               [minDate]="this.minDate"
               required>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light" data-bs-dismiss="modal" (click)="bsModalRef.hide();">Cancel</button>
    <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="okClicked(); bsModalRef.hide();">OK</button>
  </div>
</div>
