<div class="modal-header" style="display: block;">
  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="closeCheckOutModal()"
    style="display: block;"></button>
  <label class="form-label">Check-Out BOL#: {{ selectedOffice.bolNum }} - Load# {{ selectedOffice.loadNum }}</label>
</div>
<div class="modal-body container-fluid">
  <div class="container">
    <div class="row">
      <div class="col">
        <table class="table table-striped table-bordered">
          <thead>
            <tr class="table-light">
              <th scope="col">Item</th>
              <th scope="col">Present</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let checklistItem of checklists">
              <td>
                <span>{{checklistItem?.itemDescription}}</span>
              </td>
              <td>
                <button [disabled]="checklistItem.isInspected" class="btn btn-success btn-sm me-md-2"
                  (click)="togglePieceVerified(checklistItem)">
                  {{checklistItem.isInspected ? 'Verified' : 'Verify'}}
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h5 style="color: red;" *ngIf="hideLoadMessage">Please confirm all pieces are on load</h5>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div style="text-align: center;">
          <app-write-signature-pad [SignImage]="selectedOffice.signatureImage"></app-write-signature-pad>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <h5 style="color: red;" *ngIf="hideValidationMessage">Please sign before continuing</h5>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" data-bs-dismiss="modal" (click)="closeCheckOutModal()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="saveShippingOfficeCheckOut()">OK</button>
  </div>
</div>
