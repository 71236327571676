<!-- Begin Bootstrap 5 -->
<div class="toast-container p-3 top-0 end-0" id="toastPlacement" data-original-class="toast-container p-3">
    <div role="alert" aria-live="assertive" aria-atomic="true"
    [ngClass]="toastService.data.styleClasses" data-bs-autohide="true">
    <div class="toast-header">
      <strong class="me-auto">{{ toastService.data.title }} </strong>
    </div>
    <div class="toast-body">{{ toastService.data.content }}</div>

  </div>
</div>
<!-- End Bootstrap 5 -->
