import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-login-authentication-modal',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './login-authentication-modal.component.html',
  styleUrl: './login-authentication-modal.component.css'
})
export class LoginAuthenticationModalComponent {
  constructor(public bsModalRef: BsModalRef) { }
}
