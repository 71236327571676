import { Component } from '@angular/core';
import { ToastService } from './toast/toast.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'app';
  events = [];

  constructor(private toast: ToastService) {}

  async ngOnInit() {

  }
}
