import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PanelLocation } from './panel-location.model';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { PanelLocationTypeRow } from './panel-location-type-row.model';
import { PanelLocationTypeRowSection } from './panel-location-type-row-section.model';
import { ErrorResponse } from '../error-handling/error-response.model';

const options = { params: new HttpParams(), HttpHeaders: new HttpHeaders({'Content-Type': 'application/json'}) };
@Injectable({
  providedIn: 'root'
})
export class PanelLocationService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
    this.baseUrl = environment.backend.baseURL;
  }

  getPanelLocations(companyId: number, castNumber: string, jobNumber: string, mark: string): Observable<PanelLocation[]> {

    try {
      return this.http.get<PanelLocation[]>(`${this.baseUrl}/panellocation/locations?companyId=${companyId}&&castNumber=${castNumber}&&jobNumber=${jobNumber}&&mark=${mark}`, options);
    } catch (error) {
      console.log(error);
      return new Observable<any>();
    }
  }

  getPanelImage(jobNumber: string, mark: string): Observable<any>{
    const imageOptions = { params: new HttpParams(), HttpHeaders: new HttpHeaders({'Content-Type': 'image/png'}),  responseType: 'blob' };

    try {
      return this.http.get(`${this.baseUrl}/panellocation/panelimage?jobNumber=${jobNumber}&&mark=${mark}`, {
        observe: 'response', responseType: 'text'
      });
    } catch (error) {
      console.log(error);
      return new Observable<any>();
    }
  }

  getPanelLocationTypeRows(companyId: number, locationTypeId: number): Observable<PanelLocationTypeRow[]> {

    try {
      return this.http.get<PanelLocationTypeRow[]>(`${this.baseUrl}/panellocation/locationtyperows?companyId=${companyId}&&locationTypeId=${locationTypeId}`, options);
    } catch (error) {
      console.log(error);
      return new Observable<any>();
    }
  }

  getPanelLocationTypeRowSections(companyId: number, locationTypeId: number, rowName: string): Observable<PanelLocationTypeRowSection[]> {

    try {
      return this.http.get<PanelLocationTypeRowSection[]>(`${this.baseUrl}/panellocation/locationtyperowsections?companyId=${companyId}&&locationTypeId=${locationTypeId}&&rowName=${rowName}`, options);
    } catch (error) {
      console.log(error);
      return new Observable<any>();
    }
  }

  updatePanelLocation(companyId: number, pieceId: number, locationId: number | null, trailerId: number | null, trailerName: string, stackIndex: number | null) {

    let apiLocationIdParam: string = locationId !== null ? `&&locationId=${locationId}` : '';
    let apiTrailerIdParam: string = trailerId !== null ? `&&trailerId=${trailerId}` : '';
    let apiTrailerNameParam: string = trailerName !== '' ? `&&trailerName=${trailerName}` : trailerName;
    let apiStackIndexParam: string = stackIndex !== null ? `&&stackIndex=${stackIndex}` : '';

    let apiUrl: string = `${this.baseUrl}/panellocation/updatepanellocation?companyId=${companyId}&&pieceId=${pieceId}`;

    if(apiLocationIdParam != '') { apiUrl += apiLocationIdParam}
    if(apiTrailerIdParam != '') { apiUrl += apiTrailerIdParam}
    if(apiTrailerNameParam != '') { apiUrl += apiTrailerNameParam}
    if(apiStackIndexParam != '') {apiUrl += apiStackIndexParam}


    try {
      return this.http.post<ErrorResponse>(apiUrl, options);
      // return this.http.post<ErrorResponse>(`${this.baseUrl}/panellocation/updatepanellocation?companyId=${companyId}
      //                                         &&pieceId=${pieceId}&&locationId=${locationId}&&trailerId=${trailerId}&&trailerName=${trailerName}&&stackIndex=${stackIndex}`, options);
    } catch (error) {
      console.log(error);
      return new Observable<any>();
    }
  }
}
