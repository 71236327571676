<!-- Begin Add Holiday Modal -->
<div class="modal fade" id="addHolidayModal" tabindex="-1" aria-labelledby="addHolidayModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="addHolidayModalLabel">Add Holiday</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <form (ngSubmit)="onSubmit()" #form="ngForm" class="modal-body needs-validation" novalidate>
        <div class="mb-3 position-relative">
          <label for="holidayDate" class="form-label">Holiday Date</label>
          <input type="text" placeholder="Datepicker" class="form-control" bsDatepicker
            [bsConfig]="{ containerClass: 'theme-dark-blue', dateInputFormat: 'MM/DD/YYYY', selectFromOtherMonth: true, showWeekNumbers:false }" [(ngModel)]="modelSchedule.holidayDate" name="holidayDate" required>
            <div id="holidayDateFeedback" class="invalid-tooltip">
              Please select a date.
            </div>
        </div>
        <div class="mb-3">
          <label for="holidayPlant" class="form-label">Plant</label>
          <select class="form-select" aria-label="Default select example" [(ngModel)]="modelSchedule.companyId" name="companyId" required>
            <option selected disabled>
              Select a Plant
            </option>
            <option *ngFor="let company of companies" [value]="company['companyId']">
              {{company['companyName']}}
            </option>
          </select>
          <div id="holidayPlantFeedback" class="invalid-tooltip">
            Please select a Plant.
          </div>
        </div>
        <div class="mb-3 position-relative">
          <label class="form-label" for="holidayDescription">Holiday Description</label>
          <input type="text" class="form-control" id="holidayDescription" name="description"
            aria-describedby="holidayDescription holidayDescriptionFeedback" [(ngModel)]="modelSchedule.description" required>
          <div id="holidayDescriptionFeedback" class="invalid-tooltip">
            Please enter Description.
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger me-md-2" data-bs-dismiss="modal">Cancel</button>
          <button type="submit" class="btn btn-primary" type="submit">Save</button>
        </div>
      </form>
    </div>
  </div>
</div>
<!-- End Add Holiday Modal -->
<!-- Begin Holiday List -->
<div class="row mt-2">
  <h3>HOLIDAYS</h3>
  <div class="col-lg-8">
    <table class="table table-bordered">
      <thead class="table-striped">
        <tr>
          <td colspan="4">
            <button type="button" class="btn btn-success btn-sm" data-bs-toggle="modal"
              data-bs-target="#addHolidayModal">
              <i class="fa-solid fa-square-plus"></i>
              ADD HOLIDAY
            </button>
          </td>
        </tr>
        <tr>
          <th>DATE</th>
          <th>PLANT</th>
          <th>DESCRIPTION</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let schedule of (schedules$ | async) as holidaySchedules">
          <td>
            <input type="text" placeholder="Datepicker" class="form-control" bsDatepicker
              [bsConfig]="{ containerClass: 'theme-dark-blue', dateInputFormat: 'MM/DD/YYYY', selectFromOtherMonth: true, showWeekNumbers:false }"
              [(ngModel)]="schedule.holidayDate">
          </td>
          <td>
            <select class="form-select" aria-label="Default select example" [(ngModel)]="schedule.companyId">
              <option *ngFor="let company of schedule.companies" [ngValue]="company.companyId">
                {{company.companyName}}
              </option>
            </select>
          </td>
          <td><input type="text" class="form-control" id="description" aria-describedby="description"
              value="{{schedule.description}}"></td>
          <td style="padding-top: 10px;">
            <button type="button" class="btn btn-warning btn-sm me-md-2" data-bs-toggle="modal"
            data-bs-target="#addHolidayModal" (click)="editHolidaySchedule(schedule)">
              <i class="fa fa-pencil"></i>
              EDIT
            </button>
            <button type="button" class="btn btn-danger btn-sm" (click)="deleteHoliday(schedule.holidayScheduleId)">
              <i class="fa fa-trash-can"></i>
              DELETE
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<!-- End Holiday List -->
