import { ShippingYardCustomItem } from "../shipping-yard/shipping-yard-custom-item.model";
import { ShippingYardPanel } from "../shipping-yard/shipping-yard-panel.model";

export class ShippingSetup {

  constructor(
    public jobLoadId: number,
    public jobLoadBOLId: number | null,
    public job: string,
    public loadWindowID: number | null,
    public totalWeight: number,
    public crane: number,
    public width: number,
    public maxWidth: number,
    public maxLength: number,
    public maxSquareFeet: number,
    public loadNumber: string,
    public trailer: string,
    public trailerId: number | null,
    public craneCrewId: number | null,
    public carrierName: string,
    public castLocation: string,
    public bolNum: string,
    public companyId: number,
    public deliveryDateTime: Date,
    public checkOutTimestamp: Date,
    public missingItems: number,
    public missingPanels: number,
    public qaIssuePanels: number,
    public isPreLoadComplete: boolean,
    public isPreLoad: boolean,
    public isQAIssue: boolean,
    public panels: ShippingYardPanel[],
    public customItems: ShippingYardCustomItem[],
    public craneName: string,
    public loadDescriptor: string,
    public hasItems: boolean,
    public isLoaded: boolean,
    public isCheckedOut: boolean,
    public description: string,
    public trailerSize: number | null,
    public loadingType: string,
    public trailerDescription: string,
  ) {  }
}
