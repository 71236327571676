import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FieldPlanDataService } from '../field-plan.data.service';
import { FieldPlanCraneMove } from '../field-plan-detail/field-plan-move-crane.model';

@Component({
  selector: 'app-crane-move-modal',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './crane-move-modal.component.html',
  styleUrl: './crane-move-modal.component.css'
})
export class CraneMoveModalComponent implements OnInit {
  jobNumber!: number;
  craneNumber!: number;
  loadNumber!: number;

  @Output() moveCrane = new EventEmitter<any>();

  craneDelay!: FieldPlanCraneMove;
  craneMoveMinutes: number = 0;
  isLoaded: boolean = false;

  constructor(public bsModalRef: BsModalRef, private fieldPlanDataService: FieldPlanDataService) {  }

  ngOnInit() {
    this.getFieldPlanDelay();
  }

  getFieldPlanDelay() {
    try {
      this.fieldPlanDataService.getCraneDelay(this.jobNumber, this.craneNumber, this.loadNumber)
        .subscribe(result => {
          this.craneDelay = result;
          this.craneMoveMinutes = this.craneDelay.craneMoveDelay;
          this.isLoaded = true;
        }, error => console.error(error));
    } catch (error) {
      console.log(error)
    }
  }

  okClicked(): void {
    this.moveCrane.emit(this.craneDelay);
  }
}
