export class ShippingReportingBolReportSearch {

  constructor(
    public pickupDateStart: string,
    public pickupDateEnd: string,
    public plant: string,
    public format: string,
  ) {  }

}
