export class AdminTrailer {

  constructor(
    public trailerId: number,
    public trailerName: string,
    public trailerType: string,
    public companyId: number,
  ) {  }

}
