import { HolidaySchedule } from './admin-holiday-schedule.model';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, catchError, map, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ErrorResponse } from '../error-handling/error-response.model';
import { Company } from '../controls/company-drop-down/company-model';

@Injectable({
  providedIn: 'root'
})
export class AdminHolidayScheduleService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
    this.baseUrl = environment.backend.baseURL;
  }

  getHolidaySchedules(): Observable<any> {
    const options = { params: new HttpParams(), HttpHeaders: new HttpHeaders({'Content-Type': 'application/json'}) };
    try {
      return this.http.get<any>(`${this.baseUrl}/admin/holidays`, options).pipe(map((schedules) => {
        return schedules;
    }))

    } catch (error) {
      console.log(error)
      return new Observable<any>();
    }
  }

  getCompanies(): Observable<Company[]> {
    const options = { params: new HttpParams(), HttpHeaders: new HttpHeaders({'Content-Type': 'application/json'}) };
    try {
      return this.http.get<Company[]>(`${this.baseUrl}/admin/companies`, options).pipe(map((companies) => {
        return companies;
    }))

    } catch (error) {
      console.log(error)
      return new Observable<any>();
    }
  }

  addUpdateHolidaySchedule(holidaySchedule: HolidaySchedule): Observable<ErrorResponse> {

    const options = { params: new HttpParams(), HttpHeaders: new HttpHeaders({'Content-Type': 'application/json'}) };

    return this.http.post<ErrorResponse>(`${this.baseUrl}/admin/holidays`, holidaySchedule, options);
  }

  deleteHolidaySchedule(holidayScheduleId: number): Observable<ErrorResponse> {

    const options = { params: new HttpParams(), HttpHeaders: new HttpHeaders({'Content-Type': 'application/json'}) };
    return this.http.delete<ErrorResponse>(`${this.baseUrl}/admin/holidays/${holidayScheduleId}`, options);
  }
}
