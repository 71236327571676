  <div class="w-50 px-2">
    <div>
      <span>{{fieldPlanSummary.shipDate | date: 'cccc'}}</span>
      <h2 *ngIf="fieldPlanSummary.mobilization !== null">Mobilized</h2>
      <label *ngIf="checkIsFriday(fieldPlanSummary.shipDate)" value="Fabcon Friday">
        <input type="checkbox" [(ngModel)]="fieldPlanSummary.fabconFriday" (change)="fabconFridayChange()"
               [disabled]="!isEditable" />
        Fabcon Friday
      </label>
    </div>
    <label (ngModel)="fieldPlanSummary.shipDate" *ngIf="!editableDate">
      {{fieldPlanSummary.shipDate | date: 'MM/dd/yyyy'}}
      - IFS Start Date
    </label>
    <input *ngIf="editableDate" type="text" placeholder="Datepicker" class="form-control" bsDatepicker
           [minDate]="this.minDate"
           [(ngModel)]="mmddyyShipDate" name="shipDate"
           (bsValueChange)="checkShipDateChange($event) ? openModal(confirmShipDateChange, true) : null"
           [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY', containerClass: 'theme-dark-blue', selectFromOtherMonth: true, showWeekNumbers:false }"
           [disabled]="!isEditable" required>
  </div>

  <div class="row gx-2">
    <span *ngIf="this.userPrefArray.includes('status')" class="col">Status</span>
    <span *ngIf="this.userPrefArray.includes('mark')" class="col">Mark</span>
    <span *ngIf="this.userPrefArray.includes('loadNum')" class="col">Load#</span>
    <span *ngIf="this.userPrefArray.includes('minPnl')" class="col">Min/Pnl</span>
    <span *ngIf="this.userPrefArray.includes('width')" class="col">Width</span>
    <span *ngIf="this.userPrefArray.includes('length')" class="col">Length</span>
    <span *ngIf="this.userPrefArray.includes('plant')" class="col">Plant</span>
    <span *ngIf="this.userPrefArray.includes('weight')" class="col">Weight</span>
    <span *ngIf="this.userPrefArray.includes('wall')" class="col text-end">Wall</span>
    <span *ngIf="this.userPrefArray.includes('type')" class="col text-end">Type</span>
  </div>

  <div *ngIf="sortableType === '1'" cdkScrollable>
    <div>
      @for (sortedPanel of fieldPlanSortable.sortedPanels; track sortedPanel; let last=$last;) {
      <div cdkDropList [cdkDropListData]="sortedPanel.fieldPlanSummaryDetail" (cdkDropListDropped)="drop($event)"
           [cdkDropListConnectedTo]="dls" [cdkDropListAutoScrollStep]="35">
        <div>
          @for (item of sortedPanel.fieldPlanSummaryDetail; track $index; let isLast=$last;) {
          <div *ngIf="item.detailRow" (contextmenu)="open($event, item); $event.preventDefault();" class="text-center"
               [ngStyle]="{'background-color': item.color}">
            <div class="field-plan-custom-placeholder" *cdkDragPlaceholder></div>
            <div cdkDrag (cdkDragStarted)="dragStarted($event, $index)" [cdkDragData]="item"
                 (cdkDragEnded)="dragEnded($event)"
                 (click)="select($event, $index, item); checkEditablePanel(item) ? selectRow(item, $event) : null"
                 id="row_{{item.markNum}}" *ngIf="item.detailRow" class="row gx-1 ldSummaryGutter"
                 [class.selected]="isSelected(item)"
                 [ngStyle]="{'background-color': item.color, 'color': item.fontColor, 'align-items': 'center'}"
                 [cdkDragDisabled]="isPanelDragDisabled(item)" (mousedown)="mouseDown($event, item)">
              <div class="field-plan-panel-custom-placeholder" *cdkDragPlaceholder></div>
              <!-- item.loadedTimestamp !== null || item.checkInTimestamp !== null -->
              <div [ngStyle]="{'background-color': item.color, 'color': item.fontColor, 'align-items': 'center'}" class="row gx-1 ldSummaryGutter text-center">
                <span *ngIf="this.userPrefArray.includes('status')" class="col"></span>
                <span *ngIf="this.userPrefArray.includes('mark')" class="col">{{item.markNum}}</span>
                <span *ngIf="this.userPrefArray.includes('loadNum')" class="col">{{item.loadNum}}</span>
                <span *ngIf="this.userPrefArray.includes('minPnl')" class="col text-center">{{item.timeToSet}}</span>
                <span *ngIf="this.userPrefArray.includes('width')" class="col text-end">{{item.width}}</span>
                <span *ngIf="this.userPrefArray.includes('length')" class="col text-end">{{item.length}}</span>
                <span *ngIf="this.userPrefArray.includes('plant')" class="col text-end">{{item.castLoc}}</span>
                <span *ngIf="this.userPrefArray.includes('weight')" class="col text-end">{{item.weight}}</span>
                <span *ngIf="this.userPrefArray.includes('wall')" class="col text-end">{{item.wallName}}</span>
                <span *ngIf="this.userPrefArray.includes('type')" class="col text-end">{{item.panelType}}</span>
              </div>
            </div>
          </div>
          <div id="insertRow" [ngStyle]="{'background-color': sortedPanel.fieldPlanSummaryDetail[0].color}"
               style="height: 5px;" *ngIf="isLast" [ngClass]="hasSelectedRow() ? 'content' : ''">
            &nbsp;&nbsp;&nbsp;&nbsp;
          </div> <!--class="content"-->
          <div *ngIf="item.summaryRow && !this.userPrefArray.includes('hideSummaryRow')"
               [ngStyle]="{'background-color': item.color, 'color': item.fontColor, 'min-height': '25px', 'align-items': 'center'}"
               style="border-width: 1px 0px 1px 0px; border-color: black; border-style: solid;" (mouseup)="mouseUp($event)">
            <div class="row gx-1 ldSummaryGutter">
              <span *ngIf="this.userPrefArray.includes('status') && item?.hasStatus" class="col">
                <img style="height: 30px;" src="{{item?.statusImage}}" title="{{item?.statusText}}">
              </span>
              <span class="col-2 mx-2 text-start"
                    *ngIf="(!last || fieldPlanSummary.mobilization == undefined) && item?.hasStatus">LD Summary </span>
              <span class="col-3 mx-3 text-end"
                    *ngIf="(!last || fieldPlanSummary.mobilization == undefined) && !item?.hasStatus">LD Summary </span>
              <span class="col-2 mx-2 text-start"
                    *ngIf="last && fieldPlanSummary.mobilization !== null && item.checkInTimestamp !== null">
                LD Summary
              </span>
              <span class="col-3 mx-3 text-end"
                    *ngIf="last && fieldPlanSummary.mobilization !== null && item.checkInTimestamp == null">
                <span>
                  <i class="fa-solid fa-lock"></i> M: {{fieldPlanSummary.mobilization}}
                </span> LD Summary
              </span>
              <span class="col text-center">{{item.timeToSet}}</span>
              <span *ngIf="this.userPrefArray.includes('width')" class="col text-end">{{item.width}}</span>
              <span *ngIf="this.userPrefArray.includes('length')" class="col text-end">{{item.length}}</span>
              <span *ngIf="this.userPrefArray.includes('plant')" class="col text-end">{{item.castLoc}}</span>
              <span *ngIf="this.userPrefArray.includes('weight')" class="col text-end">{{item.weight}}</span>
              <span hidden *ngIf="this.userPrefArray.includes('weight')" class="col text-end">{{item.scaleWeight }}</span>
              <span hidden *ngIf="this.userPrefArray.includes('weight')" class="col text-end">{{item.weight }}</span>
              <span class="col"></span>
              <span class="col text-end">{{item.deliveryDateTime | date: 'hh:mm'}}</span>
            </div>
            <div class="text-start" *ngIf="this.item.craneMoveDelay > 0">
              CRANE MOVE DELAY {{item.craneMoveDelay}}
            </div>
          </div>
          }
        </div>
      </div>
      }
    </div>
  </div>

  <div *ngIf="sortableType === '2'" cdkScrollable>
    @for (sortedLoad of fieldPlanSortable.sortedLoads; track sortedLoad) {
    <div cdkDropList [cdkDropListData]="sortedLoad.fieldPlanSummaryDetail" [cdkDropListConnectedTo]="dls"
         [cdkDropListAutoScrollStep]="35">
      <!--cdkDropListSortingDisabled="true"   -->
      <div cdkDrag (cdkDragDropped)="dropLoad($event)" [cdkDragData]="sortedLoad.fieldPlanSummaryDetail"
           id="load_{{sortedLoad.loadNum}}" (click)="selectLoad($event, sortedLoad.fieldPlanSummaryDetail)"
           [class.selected]="isLoadSelected(sortedLoad.fieldPlanSummaryDetail)" [cdkDragDisabled]="isLoadDragDisabled(sortedLoad.summaryRow)">
        @for (item of sortedLoad.fieldPlanSummaryDetail; track $index ) {
        <div class="field-plan-load-custom-placeholder" *cdkDragPlaceholder></div> <!-- [style.height.px]="placeholderHeight"-->
        <div *ngIf="isEditable" (contextmenu)="open($event, item); $event.preventDefault();" class="text-center">
          <div (click)="checkEditablePanel(item) ? selectRow(item, $event) : null" id="row_{{item.markNum}}"
               *ngIf="item.detailRow" class="row gx-1 ldSummaryGutter"
               [ngStyle]="{'background-color': item.color, 'color': item.fontColor}">
            <span *ngIf="this.userPrefArray.includes('status')" class="col"></span>
            <span *ngIf="this.userPrefArray.includes('mark')" class="col">{{item.markNum}}</span>
            <span *ngIf="this.userPrefArray.includes('loadNum')" class="col">{{item.loadNum}}</span>
            <span *ngIf="this.userPrefArray.includes('minPnl')" class="col text-center">{{item.timeToSet}}</span>
            <span *ngIf="this.userPrefArray.includes('width')" class="col text-end">{{item.width}}</span>
            <span *ngIf="this.userPrefArray.includes('length')" class="col text-end">{{item.length}}</span>
            <span *ngIf="this.userPrefArray.includes('plant')" class="col text-end">{{item.castLoc}}</span>
            <span *ngIf="this.userPrefArray.includes('weight')" class="col text-end">{{item.weight}}</span>
            <span *ngIf="this.userPrefArray.includes('wall')" class="col text-end">{{item.wallName}}</span>
            <span *ngIf="this.userPrefArray.includes('type')" class="col text-end">{{item.panelType}}</span>
          </div>
        </div>
        <div *ngIf="item.summaryRow && !this.userPrefArray.includes('hideSummaryRow')"
             [ngStyle]="{'background-color': item.color, 'color': item.fontColor}"
             style="border-width: 1px 0px 1px 0px; border-color: black; border-style: solid;">
          <div class="row gx-1 ldSummaryGutter">
            <span *ngIf="this.userPrefArray.includes('status')" class="col">
              <img *ngIf="item?.hasStatus"
                   style="height: 30px;" src="{{item?.statusImage}}" title="{{item?.statusText}}">
            </span>
            <span class="col-2 mx-2 text-start ">LD Summary</span>
            <span class="col text-center ">{{item.timeToSet}}</span>
            <span *ngIf="this.userPrefArray.includes('width')" class="col text-end">{{item.width}}</span>
            <span *ngIf="this.userPrefArray.includes('length')" class="col text-end">{{item.length}}</span>
            <span *ngIf="this.userPrefArray.includes('plant')" class="col text-end">{{item.castLoc}}</span>
            <span *ngIf="this.userPrefArray.includes('weight')" class="col text-end">{{item.weight}}</span>
            <span class="col"></span>
            <span class="col text-end">{{item.deliveryDateTime | date: 'hh:mm'}}</span>
          </div>
          <div class="text-start" *ngIf="this.item.craneMoveDelay > 0">
            CRANE MOVE DELAY {{item.craneMoveDelay}}
          </div>

        </div>
        }
      </div>

    </div>
    }
  </div>

  <div class="mb-3 mt-3 px-2 border-top border-dark">
    <div class="row mt-1 g-3 align-items-center ">
      <div class="col-auto">
        <label for="totalPanels_{{fieldPlanSummary.shipDate}}" class="col-form-label">Day Summary Total Panels</label>
      </div>
      <div class="col-md-3">
        <input id="totalPanels_{{fieldPlanSummary.shipDate}}" type="number" name="totalPanels" class="form-control mb-1"
               pattern="[0-9]*" [(ngModel)]="inputTotalPanels" [disabled]="isShipping || !isEditable" />
      </div>
      <div class="col-md-3" *ngIf="isEditable">
        <input id="totalPanelApply" type="button" class="btn btn-primary" value="Reassign Panels"
               (click)="applyTotalPanel($event)" [disabled]="isShipping || !isEditable" />
      </div>
    </div>
    <div class="row g-3 align-items-center mt-2 pb-2" style="border-style: solid; border-width: 1px 0px 1px 0px;"
         *ngIf="isEditable">
      <div class="col-2">
        <input type="button" class="btn btn-primary" value="Apply"
               (click)="confirmMinutesToSendMessage() ? openModal(confirmMinutesToSet, false) : null" />
      </div>
      <div class="col-auto">
        <label for="minToSet_{{fieldPlanSummary.shipDate}}" class="col-form-label">Min to Set</label>
      </div>
      <div class="col-md-3">
        <input id="minToSet_{{fieldPlanSummary.shipDate}}" type="number" class="form-control mb-1" pattern="[0-9]*"
               [(ngModel)]="this.mtsModel.minutesToSet" />
      </div>
      <div class="col-auto"><label for="panelType_{{fieldPlanSummary.shipDate}}" class="col-form-label">Type</label></div>
      <div id="panelType_{{fieldPlanSummary.shipDate}}" class="col-auto">
        <select class="form-select" [(ngModel)]="selectedType">
          <option></option>
          <option *ngFor="let panelType of (panelTypes | async)" [ngValue]="panelType['panelTypeCode']">
            {{panelType['panelTypeCode']}}
          </option>
        </select>
      </div>
    </div>
    <div class="row g-3 align-items-center" style="border-style: solid; border-width: 0px 0px 1px 0px;">
      <div class="col-2"></div>
      <div class="col-3 text-start">Total Time</div>
      <div class="col-auto">
        {{totalTimeString}}
      </div>
    </div>
    <div class="row g-3 align-items-center" style="border-style: solid; border-width: 0px 0px 1px 0px;">
      <div class="col-2"></div>
      <div class="col-3 text-start">Total Loads</div>
      <div class="col-auto">{{totalLoads}}</div>
    </div>
  </div>


  <!--context menu-->
  <ng-template #panelMenu let-item>
    <div id="context-menu" section class="context-menu">
      <div *ngIf="canReverseSort" (click)="confirmPanelsSelected('reverse order') ? reverseSort() : null">
        Reverse Sort
      </div>
      <div (click)="confirmPanelsSelected('split') ? splitLoad(confirmShipDateChange) : null">Split Load</div>
      <div *ngIf="canCreateDay" (click)="confirmPanelsSelected('create day') ? createDay() : null">Create New Day</div>
      <div (click)="confirmPanelsSelected('create mobnilization') ? createMobilization() : null">Create Mobilization</div>
      <div (click)="confirmSwitchCraneSelected('switch crane') ? switchCrane(): null">Switch Crane</div>
      <div (click)="confirmPanelsSelected('crane move') ? moveCrane() : null">Move Crane</div>
    </div>
  </ng-template>

  <!--Begin Confirm Ship Date Change Modal-->
  <ng-template #confirmShipDateChange class="modal-dialog-centered ">
    <div class="modal-header">
      <h4 class="modal-title pull-left">Confirmation</h4>
      <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="bsModalRef?.hide()">
        <span aria-hidden="true" class="visually-hidden">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div *ngIf="this.partiallyMobilized && shipDateChangeMessage == ''">
        Split load number
        {{this.uniqueLoads.toString()}} into separate loads
      </div>
      <p *ngIf="shipDateChangeMessage">{{shipDateChangeMessage}}</p>
      <div *ngIf="fieldPlanSummary.mobilization !== null">
        <h5>
          This field plan has already been accepted. Modifications may affect shipping schedule.
        </h5>
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                (click)="shipDateChanged(); bsModalRef?.hide();">
          OK & Finished Editing
        </button>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-light" data-bs-dismiss="modal"
              (click)="resetShipDate(); bsModalRef?.hide()">
        Cancel
      </button>
      <button type="button" class="btn btn-primary" (click)="shipDateChanged(); bsModalRef?.hide();">OK</button>
    </div>

  </ng-template>
  <!--End Confirm Ship Date Modal-->
  <!--Begin Confirm Minutes to Set Modal-->
  <ng-template #confirmMinutesToSet class="modal-dialog-centered  modal-lg">
    <div class="modal-header">
      <h4 class="modal-title pull-left">Confirmation</h4>
      <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="bsModalRef?.hide()">
        <span aria-hidden="true" class="visually-hidden">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>{{this.mtsConfirmMsg}}</p>
      <h3 *ngIf="this.isMobilized">
        This field plan has already been accepted. Modifications may affect shipping schedule.
      </h3>
      <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
              (click)="minutesToSetChanged(true); bsModalRef?.hide();" *ngIf="this.isMobilized">
        OK & Finished Editing
      </button>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-light" data-bs-dismiss="modal"
              (click)="resetMinutesToSet(); bsModalRef?.hide()">
        Cancel
      </button>
      <button type="button" class="btn btn-primary" (click)="minutesToSetChanged(false); bsModalRef?.hide();">OK</button>
    </div>
  </ng-template>
  <!--End Confirm Minutes to Set Modal-->
