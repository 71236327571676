import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AdminCustomItem } from './admin-custom-item.model';
import { ErrorResponse } from '../error-handling/error-response.model';

const options = { params: new HttpParams(), HttpHeaders: new HttpHeaders({'Content-Type': 'application/json'}) };
@Injectable({
  providedIn: 'root'
})
export class AdminCustomItemsService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
    this.baseUrl = environment.backend.baseURL;
  }


  getAllCustomItems(): Observable<AdminCustomItem[]>{
    try {
      return this.http.get<AdminCustomItem[]>(`${this.baseUrl}/admin/allcustomitems`, options);
    } catch (error) {
      console.log(error);
      return new Observable<any>();
    }
  }

  updateCustomItem(customItem: AdminCustomItem): Observable<ErrorResponse> {
    try {
      return this.http.post<ErrorResponse>(`${this.baseUrl}/admin/allcustomitems`, customItem, options);
    } catch (error) {
      console.log(error);
      return new Observable<any>();
    }
  }
}
