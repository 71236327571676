export class HolidaySchedule {

  constructor(
    public holidayDate: string,
    public companyId: number,
    public description: string,
    public holidayScheduleId?: number,
  ) {  }

}
