import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../authentication/auth.service';
import { User } from '../authentication/user-model';

export const canActivateGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot) => {

    const router: Router = inject(Router);

  if (inject(AuthService).isUserLoggedIn()) {
    let storedAuthenticatedUser = localStorage.getItem('authenticatedUser');

        if(storedAuthenticatedUser !== null){
          let localUser: User = JSON.parse(storedAuthenticatedUser);
          inject(AuthService).currentUserSig.set(localUser);
        }
    return true;
  }
  else {
    router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
    return false;
  }
};
