<nav id="mainNav" class="navbar fixed-top navbar-expand-lg bg-light" style="height: 100px;" >
  <div class="container-fluid">
    <a class="navbar-brand" [routerLink]="['/']"><img src="/assets/images/FabconPRECAST_MMAPPLogo.png"
        style="height: 55px;" /></a>
    <div class="me-4" *ngIf="authService.currentUserSig()">
      {{authService.currentUserSig()?.userName}} <br />
      Plant Location: {{authService.currentUserSig()?.companyCode}}
    </div>
    <div class="row g-3 align-items-center me-2" *ngIf="authService.hasPermission('ChangeAssignedPlant')">
      <label for="selectYear" class="col-auto">Default Plant:</label>
      <div class="col-auto">
        <app-company-drop-down id="appCompanyDropDown"></app-company-drop-down>
      </div>
    </div>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse mt-2" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0" *ngIf="authService.currentUserSig()">
        <li class="nav-item" *ngIf="authService.hasPermission('YardSearchEdit') || authService.hasPermission('ShippingYardEdit')
        || authService.hasPermission('YardSearchView') || authService.hasPermission('Shipping3PLEdit')">
          <a class="nav-link" [routerLink]="['/field-plan']">Field Plan</a>
        </li>
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" [routerLink]="['/panel-location']"
          *ngIf="authService.hasPermission('YardSearchEdit') || authService.hasPermission('ShippingYardEdit')
          || authService.hasPermission('YardSearchView')">Panel Location</a>
        </li>
        <li class="nav-item dropdown" *ngIf="authService.hasPermission('ShippingOfficeEdit') || authService.hasPermission('ShippingOfficeView') || authService.hasPermission('ShippingYardEdit')">
          <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Shipping
          </a>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item" [routerLink]="['/shipping-office']"
                *ngIf="authService.hasPermission('ShippingOfficeEdit') || authService.hasPermission('ShippingOfficeView')">Office</a>
            </li>
            <li><a class="dropdown-item" [routerLink]="['/shipping-yard']"
                *ngIf="authService.hasPermission('ShippingYardEdit')">Yard</a></li>
            <li><a class="dropdown-item" [routerLink]="['/shipping-setup']" *ngIf="authService.hasPermission('ShippingOfficeEdit') || authService.hasPermission('ShippingOfficeView')">Setup</a></li>
          </ul>
        </li>
        <li class="nav-item dropdown" *ngIf="authService.hasPermission('Shipping3PLEdit') || authService.hasPermission('ShippingOfficeView')">
          <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Shipping 3PL
          </a>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item" [routerLink]="['/shipping3pl-s3pl']">3PL</a></li>
            <li><a class="dropdown-item" [routerLink]="['/shipping3pl-bol']">BOL</a></li>
            <li><a class="dropdown-item" [routerLink]="['/shipping3pl-reporting']">Reporting</a></li>
          </ul>
        </li>
        <li class="nav-item dropdown" *ngIf="authService.hasPermission('SystemAdministration') || authService.hasPermission('HolidaySchedule')">
          <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Admin
          </a>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item" [routerLink]="['/admin-yard-crane-crew']"
                *ngIf="authService.hasPermission('SystemAdministration')">Yard Crane Crew Titles</a></li>
            <li><a class="dropdown-item" [routerLink]="['/admin-custom-items']"
                *ngIf="authService.hasPermission('SystemAdministration')">Custom Item Options</a></li>
            <li><a class="dropdown-item" [routerLink]="['/admin-trailers']"
                *ngIf="authService.hasPermission('SystemAdministration')">Trailers</a></li>
            <li><a class="dropdown-item" [routerLink]="['/admin-holiday-schedule']"
                *ngIf="authService.hasPermission('HolidaySchedule')">Holiday Schedule</a></li>
          </ul>
        </li>
      </ul>
      <div *ngIf="authService.currentUserSig() !== null && authService.currentUserSig() !== undefined">
        <button class="btn btn-primary" (click)="logout()">Logout</button>
      </div>
      <div [ngClass]="!authService.currentUserSig() ? 'col-lg-12' : ''"
        [style]="!authService.currentUserSig() ? 'text-align: right' : ''">
        <img src="/assets/images/FabconPRECAST_HorizontalPantone3015_937x350.png" style="height: 55px;"
          class="me-end" />
      </div>
    </div>
  </div>
</nav>
