import { AdminTrailerType } from './admin-trailer-type.model';
import { AdminHolidayScheduleService } from './../admin-holiday-schedule/admin-holiday-schedule.service';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { AdminTrailersService } from './admin-trailers.service';
import { Observable } from 'rxjs';
import { AdminTrailer } from './admin-trailer.model';
import { ErrorResponse } from '../error-handling/error-response.model';
import { ToastService, toastTypes } from '../toast/toast.service';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Company } from '../controls/company-drop-down/company-model';

@Component({
  selector: 'app-admin-trailers',
  templateUrl: './admin-trailers.component.html',
  styleUrl: './admin-trailers.component.css'
})
export class AdminTrailersComponent implements OnInit {
 // @ViewChild('trailerTypes') trailerTypes!: ElementRef;
  @ViewChild('trailerCompanies') trailerCompanies!: ElementRef;



  adminTrailersService: AdminTrailersService = inject(AdminTrailersService);
  adminHolidayScheduleService: AdminHolidayScheduleService = inject(AdminHolidayScheduleService);
  trailers: AdminTrailer[] = [];
  companies!: Company[];
  selectedTrailerType = '';
  selectedTrailerCompany: any;
  enteredTrailerName = '';
  modelAdminTrailer = new AdminTrailer(0, '', '', 0);
  public job = { name:  'JOB'};
  public yard = { name:  'YARD'};
  trailerTypes: AdminTrailerType[] =[this.job, this.yard];
  isTrailerDataEntryValid: boolean = false;

  constructor(private toast: ToastService) { }

  ngOnInit() {
    try {
      this.loadAdminTrailers(10, "YARD");
    } catch (error) {
      console.log(error)
    }
  }

  private loadAdminTrailers(companyId: number, trailerType: string) {

    this.adminHolidayScheduleService.getCompanies().subscribe(listCompany => {

      this.companies = listCompany;

    this.adminTrailersService.getTrailers(companyId, trailerType).subscribe(result => {

      this.trailers = result;
    }, error => console.error(error));
  });


  }

  onSearchTrailers():void{
    try {
      this.loadAdminTrailers(this.selectedTrailerCompany, this.selectedTrailerType);
    } catch (error) {
      console.log(error)
    }
  }

  public async addTrailerLocation() {
    this.modelAdminTrailer.companyId = this.selectedTrailerCompany;
    this.modelAdminTrailer.trailerName = this.enteredTrailerName;
    this.modelAdminTrailer.trailerType = this.selectedTrailerType;

    this.adminTrailersService.addTrailerLocation(this.modelAdminTrailer).subscribe((response: ErrorResponse) => {
      this.toast.initiate({
        title: response.name,
        content: response.message,
        type: response.statusCode == 200 || response.statusCode == 201 ? toastTypes.success : toastTypes.error,
      });

      this.refreshComponent();

    }, (error: HttpErrorResponse) => {
      console.log(error)
      this.toast.initiate({
        title: error.error.Name,
        content: error.error.message,
        type: toastTypes.error,
      });
    });
  }

  public async deleteTrailerLocation(trailerKey: number) {

    this.adminTrailersService.deleteTrailerLocation(trailerKey).subscribe((response: ErrorResponse) => {
      this.toast.initiate({
        title: response.name,
        content: response.message,
        type: response.statusCode == 200 || response.statusCode == 201 ? toastTypes.success : toastTypes.error,
      });

      this.refreshComponent();

    }, (error: HttpErrorResponse) => {
      this.toast.initiate({
        title: error.error.Name,
        content: error.error.message,
        type: toastTypes.error,
      });
    });

  }

  private refreshComponent(){
    this.loadAdminTrailers(this.selectedTrailerCompany, this.selectedTrailerType);
  }

  public invalidTrailer(): boolean{
    if (this.selectedTrailerCompany == undefined|| this.selectedTrailerType == undefined
      || (this.enteredTrailerName == undefined || this.enteredTrailerName.length == 0)) {
      return true;
    }

    return false;
  }

  public invalidSearch(): boolean{
    if (this.selectedTrailerCompany == undefined|| this.selectedTrailerType == undefined) {
      return true;
    }

    return false;
  }
}
