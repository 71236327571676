import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ShippingDailySummary } from './shipping-daily-summary.model';
import { Company } from 'src/app/controls/company-drop-down/company-model';

@Injectable({
  providedIn: 'root'
})
export class ShippingDailySummaryService implements OnInit {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
    this.baseUrl = environment.backend.baseURL;
  }

  ngOnInit(): void {

  }

  getDailySummary(companyIds: number[], expectedPickupDate: string): Observable<ShippingDailySummary[]> {
    try {
      return this.http.get<ShippingDailySummary[]>(`${this.baseUrl}/shippingoffice/dailySummary`,{ params: {companyIds: companyIds,
        expectedPickupDate: expectedPickupDate?.toString()!} });
    } catch (error) {
      console.log(error);
      return new Observable<any>();
    }
  }
}
