import { AuthService } from './../authentication/auth.service';
import { Component, OnInit, inject } from '@angular/core';
import { ToastService } from '../toast/toast.service';
import { Auth } from '../authentication/auth-model';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { LoginAuthenticationModalComponent } from './login-authentication-modal/login-authentication-modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ReloadRouteService } from '../reload-route.service ';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.css'
})



export class LoginComponent implements OnInit {
  authService: AuthService = inject(AuthService);
  userLogin: Auth = new Auth('', '');
  bsModalRef?: BsModalRef;
  authenticatedUserError: string | null | undefined;
  reloadRouteService = inject(ReloadRouteService);

  constructor(private toast: ToastService, private modalService: BsModalService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    localStorage.removeItem('yardMapToken');
  }

  login() {
    if (this.userLogin.userName.length > 0 && this.userLogin.password.length > 0) {
      this.openLoadingModal();
      localStorage.removeItem('authenticatedUserError');
      this.sleep(2000).then(() => {
        this.authService.login(this.userLogin, true).then(() => {
          this.sleep(2000).then(() => {
          this.authenticatedUserError = localStorage.getItem('authenticatedUserError');
          });
          this.closeLoadingModal();

          let returnUrl:string = this.route.snapshot.queryParams['returnUrl'] || '/';

          if(returnUrl == '/')
          {
            this.router.navigate(['/']);
          }
          else{
            let navigateUrl: string[] = returnUrl.split(';');
            let urlValues: string[] = navigateUrl[0].split('/');
            this.router.navigate([ '/' + urlValues[1], { job: urlValues[2] } ]);
          }
        });
      });
    }
  }

  openLoadingModal() {
    this.bsModalRef = this.modalService.show(LoginAuthenticationModalComponent, Object.assign({}, { class: 'modal-lg modal-dialog-centered' }));
  }

  closeLoadingModal() {
    this.modalService.hide();
  }

  sleep(ms: number | undefined) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
