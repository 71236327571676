import { CraneCrew } from './../common-data/crane-crew.model';
import { Component, OnInit, inject } from '@angular/core';
import { AdminCustomItemsService } from '../admin-custom-items/admin-custom-items.service';
import { AdminCustomItem } from '../admin-custom-items/admin-custom-item.model';
import { ErrorResponse } from '../error-handling/error-response.model';
import { ToastService, toastTypes } from '../toast/toast.service';
import { CommonDataService } from '../common-data/common-data.service';
import { environment } from 'src/environments/environment';
import { AdminYardCraneCrewService } from './admin-yard-crane-crew.service';
import { AuthService } from '../authentication/auth.service';

@Component({
  selector: 'app-admin-yard-crane-crew',
  templateUrl: './admin-yard-crane-crew.component.html',
  styleUrl: './admin-yard-crane-crew.component.css'
})
export class AdminYardCraneCrewComponent {

  isLoading: boolean = false;
  order: string = "description";
  reverse: boolean = false;
  errorMessage: string = '';
  isAddButtonDisabled: boolean = false;
  enteredCraneCrewDescription: string = '';
  isAddNewRow: boolean = false;
  enteredCraneCrewIsActive: boolean = true;
  commonDataService: CommonDataService = inject(CommonDataService);
  craneCrews: CraneCrew[] = [];
  adminYardCraneCrewService: AdminYardCraneCrewService = inject(AdminYardCraneCrewService);
  authService = inject(AuthService);

  constructor(private toast: ToastService) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.loadCraneCrews();
  }

  loadCraneCrews(){
    this.commonDataService.getCraneCrews(this.authService.currentUserSig()!.companyId).subscribe({
      next: (result) => {
        result.forEach((craneCrew) => {
          craneCrew.isEditable = false;
        })
        this.craneCrews = result;
        this.isLoading = false;
      },
      error: (error) => {
        // Handle error cases
        this.isLoading = false;
        console.error(error);
      }
    });
  }

  editCraneCrew(craneCrew: CraneCrew) {
    craneCrew.isEditable = true;
  }


  saveCraneCrew(craneCrew: CraneCrew) {
    console.log('saveCustomItem', craneCrew)

    if (craneCrew.description.length == 0) { this.errorMessage = 'Please enter description'; return; }

    this.adminYardCraneCrewService.updateCraneCrew(craneCrew).subscribe({
      next: (response: ErrorResponse) => {
        this.isAddButtonDisabled = false;
        this.isAddNewRow = false;
        this.enteredCraneCrewDescription = '';

        this.toast.initiate({
          title: response.name,
          content: response.message,
          type: response.statusCode == 200 || response.statusCode == 201 ? toastTypes.success : toastTypes.error,
        });

        this.loadCraneCrews();
      },
      error: (error) => {
        this.toast.initiate({
          title: error.error.name,
          content: error.error.message,
          type: toastTypes.error,
        });
      }
    });
  }

  saveNewCraneCrew(){
    if (this.enteredCraneCrewDescription.length == 0) { this.errorMessage = 'Please enter description'; return; }
    let newCraneCrew = new CraneCrew(0, this.authService.currentUserSig()!.companyId, this.enteredCraneCrewDescription, this.enteredCraneCrewIsActive, true);
    this.saveCraneCrew(newCraneCrew);
  }

  onChecked(craneCrew: CraneCrew, isChecked: boolean) {
    //console.log('onChecked', craneCrew, isChecked);
    this.saveCraneCrew(craneCrew);
  }

  setOrder(value: string) {
      if (this.order === value) {
        this.reverse = !this.reverse;
      }
      this.order = value;
  }
  addNewRow() {
    this.isAddNewRow = true;
    this.isAddButtonDisabled = true;
  }

  cancelCraneCrew(craneCrew: CraneCrew) {
    craneCrew.isEditable = false;
    this.isAddButtonDisabled = false;
  }

  cancelNewCraneCrew(){
    this.isAddNewRow = false;
    this.isAddButtonDisabled = false;
    this.errorMessage = '';
    this.enteredCraneCrewDescription = '';
  }
}
