<div class="position-relative" style="min-height: 75vh">
  <div class="position-absolute top-50 start-50 translate-middle p-5 bg-light border col-lg-3">
    <div class="mb-4">
      <h6 style="text-align: center;">Log Into YardMap</h6>
    </div>
    <form class="row g-3 needs-validation" novalidate (ngSubmit)="login()">
      <div *ngIf="authenticatedUserError != null" class="alert alert-danger" role="alert">
        {{authenticatedUserError}}
      </div>
      <div>
        <div class="input-group has-validation">
          <input type="text" class="form-control" id="userName" name="userName" required placeholder="User Name"
            [(ngModel)]="userLogin.userName">
          <div class="invalid-feedback">
            Please choose a username.
          </div>
        </div>
      </div>
      <div>
        <input type="password" class="form-control" id="password" name="password" required placeholder="Password"
          [(ngModel)]="userLogin.password">
        <div class="invalid-feedback">
          Please enter a password.
        </div>
      </div>
      <div class="col-12">
        <button type="submit" class="btn btn-primary col-lg-12">Log In</button>
      </div>
    </form>
  </div>
</div>
