import { Component, OnInit, inject } from '@angular/core';
import { ShippingReportingBolReportSearch } from './shipping-reporting-bol-report-search.model';
import { environment } from 'src/environments/environment';
import { AdminHolidayScheduleService } from '../admin-holiday-schedule/admin-holiday-schedule.service';
import { ShippingReportingFieldPlanReportSearch } from './shipping-reporting-field-plan-report-search.model';
import { Shipping3plReportingService } from './shipping3pl-reporting.service';
import { ShippingReportingSearchCriteria } from './shipping-reporting-search-criteria.model';
import { ToastService, toastTypes } from '../toast/toast.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Company } from '../controls/company-drop-down/company-model';
import { AuthService } from '../authentication/auth.service';

@Component({
  selector: 'app-shipping3pl-reporting',
  templateUrl: './shipping3pl-reporting.component.html',
  styleUrl: './shipping3pl-reporting.component.css'
})
export class Shipping3plReportingComponent implements OnInit {
  reports = [
    {
      "id": "1",
      "name": "BOL Report"
    },
    {
      "id": "2",
      "name": "Field Plan Report"
    }
  ];

  reportFormats = [
    {
      "id": "1",
      "name": "EXCEL"
    },
    {
      "id": "2",
      "name": "PDF"
    }
  ];

  selectedReportName = '';
  selectedReportFormat = '';

  modelReportSearchCriteria = new ShippingReportingSearchCriteria('');
  modelBolReportSearch = new ShippingReportingBolReportSearch(new Date().toLocaleString('en-US', {
    month: '2-digit', day: '2-digit', year: 'numeric'
  }), new Date().toLocaleString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }), '', '');
  modelFieldPlanReportSearch = new ShippingReportingFieldPlanReportSearch('', false, '');


  adminHolidayScheduleService: AdminHolidayScheduleService = inject(AdminHolidayScheduleService);
  shipping3plReportingService: Shipping3plReportingService = inject(Shipping3plReportingService);
  companies!: Company[];
  isBolReportOptionsDisplayed: boolean = false;
  isFieldPlanReportOptionsDisplayed: boolean = false;
  isReportRunning: boolean = false;
  authService = inject(AuthService);

  constructor(private toast: ToastService) { }

  invalidDownload(): boolean {

    if (this.isReportRunning) { return true; }

    if (!this.isBolReportOptionsDisplayed && !this.isFieldPlanReportOptionsDisplayed) {
      return true;
    }
    else if (this.isBolReportOptionsDisplayed) {
      if (this.authService.currentUserSig()!.companyId == 0 || this.modelBolReportSearch.format.length == 0
        || this.modelReportSearchCriteria.selectedReportName.length == 0 || this.modelBolReportSearch.pickupDateStart.length == 0
        || this.modelBolReportSearch.pickupDateEnd.length == 0 || this.modelBolReportSearch.plant.length == 0) {

        return true;
      }
    }
    else if (this.isFieldPlanReportOptionsDisplayed) {
      if (this.authService.currentUserSig()!.companyId == 0 || this.modelFieldPlanReportSearch.format.length == 0
        || this.modelReportSearchCriteria.selectedReportName.length == 0 || this.modelFieldPlanReportSearch.job.length == 0) {

        return true;
      }
    }

    return false;

  }

  ngOnInit(): void {
    this.invalidDownload();

    this.adminHolidayScheduleService.getCompanies().subscribe(listCompany => {
      this.companies = listCompany;
    }, error => console.error(error));
  }

  onReportSelected() {
    if (this.modelReportSearchCriteria.selectedReportName == "BOL Report") {
      this.isFieldPlanReportOptionsDisplayed = false;
      this.isBolReportOptionsDisplayed = true;
    }
    else if (this.modelReportSearchCriteria.selectedReportName == "Field Plan Report") {
      this.isBolReportOptionsDisplayed = false;
      this.isFieldPlanReportOptionsDisplayed = true;
    }
  }

  printReport() {

    this.isReportRunning = true;

    this.toast.initiate({
      title: `${this.modelReportSearchCriteria.selectedReportName}`,
      content: `${this.modelReportSearchCriteria.selectedReportName} is running`,
      type: toastTypes.success,
    });

    if (this.modelReportSearchCriteria.selectedReportName == "BOL Report") {
      const pickupDateStart = new Date(this.modelBolReportSearch.pickupDateStart).toLocaleDateString('en-US');
      const pickupDateEnd = new Date(this.modelBolReportSearch.pickupDateEnd).toLocaleDateString('en-US');

      this.shipping3plReportingService.getbolReport(pickupDateStart, pickupDateEnd,
        this.modelBolReportSearch.plant, this.modelBolReportSearch.format).subscribe(file => {
          let fileName = file.headers.get('content-disposition')?.split(';')[1].split('=')[1];
          let blob: Blob = file.body as Blob;
          let a = document.createElement('a');
          a.download = fileName;
          a.href = window.URL.createObjectURL(blob);
          a.click();

          this.toast.initiate({
            title: `${this.modelReportSearchCriteria.selectedReportName}`,
            content: `${this.modelReportSearchCriteria.selectedReportName} has successfully completed.`,
            type: toastTypes.success,
          });

          this.isReportRunning = false;
          this.invalidDownload();

        }, (error: HttpErrorResponse) => {
          console.log(error)
          this.toast.initiate({
            title: error.name,
            content: error.message,
            type: toastTypes.error,
          });

          this.isReportRunning = false;
          this.invalidDownload();
        });
    }
    else if (this.modelReportSearchCriteria.selectedReportName == "Field Plan Report") {
      this.shipping3plReportingService.getFieldPlanReport(this.modelFieldPlanReportSearch.job, this.modelFieldPlanReportSearch.showPanelDetail.toString(),
        this.modelFieldPlanReportSearch.format).subscribe(file => {
          let fileName = file.headers.get('content-disposition')?.split(';')[1].split('=')[1];
          let blob: Blob = file.body as Blob;
          let a = document.createElement('a');
          a.download = fileName;
          a.href = window.URL.createObjectURL(blob);
          a.click();

          this.toast.initiate({
            title: `${this.modelReportSearchCriteria.selectedReportName}`,
            content: `${this.modelReportSearchCriteria.selectedReportName} has successfully completed.`,
            type: toastTypes.success,
          });

          this.isReportRunning = false;
          this.invalidDownload();

        }, (error: HttpErrorResponse) => {
          console.log(error)
          this.toast.initiate({
            title: error.name,
            content: error.message,
            type: toastTypes.error,
          });

          this.isReportRunning = false;
          this.invalidDownload();
        });
    }
  }
}
