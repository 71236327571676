import { environment } from '../../environments/environment';
import { AuthService } from './../authentication/auth.service';
import { Component, OnInit, inject } from '@angular/core';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent implements OnInit {
  isExpanded = false;
  authService = inject(AuthService);
  plantLocation: string = '';
  constructor() {
  }

  ngOnInit() {
    console.log('baseURL ' + environment.backend.baseURL);
    console.log('prodEnv ' + environment.production);
    this.getBackgroundColorClass();
  }

  collapse() {
    this.isExpanded = false;
  }

  getBackgroundColorClass() {
    const navBar = document.getElementById('mainNav');

    if (navBar !== null) {
      if (!environment.production) {
        navBar.classList.remove('bg-light');
        navBar.classList.add('bg-pink');
      }
    }
    return environment.production ? 'bg-light' : 'bg-pink';
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  logout() {
    this.authService.logout();
  }
}
