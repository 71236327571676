import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ShippingOfficeBolSearch } from './shipping-office-bol-search.model';
import { ShippingOffice } from './shipping-office.model';

@Injectable({
  providedIn: 'root'
})
export class ShippingOfficeService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
    this.baseUrl = environment.backend.baseURL;
  }

  getOffices(model: ShippingOfficeBolSearch):Observable<ShippingOffice[]> {
    const options = { params: new HttpParams(), HttpHeaders: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    try {
      if(model.expectedPickupDate != undefined)
      {
        return this.http.get<ShippingOffice[]>(`${this.baseUrl}/shippingoffice/offices`,{ params: {companyIds: model.companyIds,
          expectedPickupDate: model.expectedPickupDate?.toString()!, bolNumber: model.bolNumber!} });
      }
      else{
        return this.http.get<ShippingOffice[]>(`${this.baseUrl}/shippingoffice/offices`,{ params: {companyIds: model.companyIds,
          bolNumber: model.bolNumber!} });
      }

    } catch (error) {
      console.log(error);
      return new Observable<any>();
    }
  }

  public getbolReport(job: string, loadNum: string) {
    try {
      return this.http.get(`${this.baseUrl}/shippingoffice/bolReport?job=${job}&&loadNum=${loadNum}`, {
        observe: 'response', responseType: 'blob'
      });
    } catch (error) {
      console.log(error);
      return new Observable<any>();
    }
  }
}
