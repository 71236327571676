import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class Shipping3plReportingService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
    this.baseUrl = environment.backend.baseURL;
  }

  public getbolReport(pickupDateStart:string, pickupDateEnd:string,  plant: string, format: string) {
    try {
      return this.http.get(`${this.baseUrl}/shipping3plreport/bolReport?pickupDateStart=${pickupDateStart}&&pickupDateEnd=${pickupDateEnd}&&plant=${plant}&&format=${format}`, {
        observe:'response',responseType:'blob'
      });
    } catch (error) {
      console.log(error);
      return new Observable<any>();
    }
  }

  public getFieldPlanReport(job:string, showDetail:string, format: string) {
    try {
      return this.http.get(`${this.baseUrl}/shipping3plreport/fieldPlanReport?job=${job}&&showDetail=${showDetail}&&format=${format}`, {
        observe:'response',responseType:'blob'
      });
    } catch (error) {
      console.log(error);
      return new Observable<any>();
    }
  }
}
