<!-- Begin Shipping Reporting List -->
<div class="row mt-2">
  <h3>SHIPPING3PL REPORTING</h3>
  <div class="col-lg-6">
    <table class="table table-borderless">
      <tbody>
        <tr>
          <td colspan="3">
            <div class="row">
              <div class="col-sm-3">
                <label class="col-sm-2 col-form-label col-form-label-lg">Report</label>
                <select class="form-select mb-3" [(ngModel)]="modelReportSearchCriteria.selectedReportName"
                  (change)="onReportSelected()">
                  <option *ngFor="let report of reports" [ngValue]="report.name">
                    {{report.name}}
                  </option>
                </select>
              </div>
            </div>
          </td>
        </tr>
        <tr *ngIf="isBolReportOptionsDisplayed">
          <td colspan="3">
            <div class="row">
              <div class="col-md-4 form-group mb-3">
                <label class="col-form-label col-form-label-lg">Pickup Date Start</label>
                <input type="text" placeholder="Datepicker" class="form-control" bsDatepicker
                  [bsConfig]="{ containerClass: 'theme-dark-blue', dateInputFormat: 'MM/DD/YYYY', selectFromOtherMonth: true, showWeekNumbers:false }"
                  [(ngModel)]="modelBolReportSearch.pickupDateStart" name="pickupDateStart" required>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 form-group mb-3">
                <label class="col-form-label col-form-label-lg">Pickup Date End</label>
                <input type="text" placeholder="Datepicker" class="form-control" bsDatepicker
                  [bsConfig]="{ containerClass: 'theme-dark-blue', dateInputFormat: 'MM/DD/YYYY', selectFromOtherMonth: true, showWeekNumbers:false }"
                  [(ngModel)]="modelBolReportSearch.pickupDateEnd" name="pickupDateEnd" required>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-3">
                <label class="col-form-label col-form-label-lg">Plant</label>
                <select #trailerCompanies class="form-select" [(ngModel)]="modelBolReportSearch.plant">
                  <option *ngFor="let company of companies" [ngValue]="company['companyCode']">
                    {{company['companyName']}}
                  </option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-3">
                <label class="col-sm-2 col-form-label col-form-label-lg">Format</label>
                <select class="form-select mb-3" [(ngModel)]="modelBolReportSearch.format">
                  <option *ngFor="let format of reportFormats" [ngValue]="format.name">
                    {{format.name}}
                  </option>
                </select>
              </div>
            </div>
          </td>
        </tr>
        <tr *ngIf="isFieldPlanReportOptionsDisplayed">
          <td colspan="3">
            <div class="row">
              <div class="col-sm-3">
                <label class="col-sm-2 col-form-label col-form-label-lg">Job</label>
                <input type="text" placeholder="Enter Job Number" class="form-control"
                  [(ngModel)]="modelFieldPlanReportSearch.job">
              </div>
            </div>
            <div class="row px-2 mt-4">
              <div class="col-md-4 form-group mb-3">
                <div class="form-check">
                  <label class="form-check-label col-form-label-lg" for="showPanelDetail">
                    Show Panel Detail <input class="col-lg-2 form-check-input" type="checkbox" value=""
                      id="showPanelDetail" [(ngModel)]="modelFieldPlanReportSearch.showPanelDetail">
                  </label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-3">
                <label class="col-sm-2 col-form-label col-form-label-lg">Format</label>
                <select class="form-select mb-3" [(ngModel)]="modelFieldPlanReportSearch.format">
                  <option *ngFor="let format of reportFormats" [ngValue]="format.name">
                    {{format.name}}
                  </option>
                </select>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="row">
              <div class="col-sm-3">
                <button type="button" class="btn btn-primary btn-sm" [disabled]="invalidDownload()"
                  (click)='printReport()'>
                  <i class="fa-solid fa-download"></i>
                  DOWNLOAD
                </button>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<!-- End Shipping Reporting List -->
