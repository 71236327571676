export class CraneCrew {

  constructor(
    public craneCrewID: number,
    public companyID: number,
    public description: string,
    public isActive: boolean,
    public isEditable?: boolean,
  ) {  }

}
