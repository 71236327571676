import { Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { UserPreferencesService } from './user-preferences.service';

@Component({
  selector: 'app-user-preference',
  templateUrl: './user-preferences.component.html',
  styleUrl: './user-preferences.component.css'
})
export class UserPreferencesComponent implements OnInit {
  private _userPreferenceService: UserPreferencesService;

  @Input()
  get userPrefs(): any {
    return this._userPrefs;
  }
  set userPrefs(userPrefs: any) {
    if (userPrefs === '' || userPrefs === undefined) {
      this._userPrefs = this._userPreferenceService.getUserPreferences();
    }
    else {
      this._userPrefs = userPrefs;
    }

    this._updatedPrefs = this._userPrefs;
    this.userPrefInputs = this._userPreferenceService.formatInputPreps(this._userPrefs);
  }
  private _userPrefs: any;

  userPrefInputs: any;

  private _updatedPrefs: any = '';

  @Output() userPrefsUpdated = new EventEmitter<any>();

  constructor(userPreferenceService: UserPreferencesService) {
    this._userPreferenceService = userPreferenceService;
}
  ngOnInit(): void {
  }

  updateUserPreferences() {
    this._updatedPrefs = '';
    this._updatedPrefs = this._userPreferenceService.setUserPreferences(this.userPrefInputs);
    this.userPrefsUpdated.emit(this._updatedPrefs);
    this.closeModal();
  }

  prefChangeCanceled() {
    this._updatedPrefs = '';
    this.userPrefInputs = this._userPreferenceService.formatInputPreps(this.userPrefs);
    this, this.closeModal();
  }

  closeModal() {
    document.getElementById('userPrefClose')?.click();
  }

}
