import { Component } from '@angular/core';

@Component({
  selector: 'app-home-job-trailers',
  standalone: true,
  imports: [],
  templateUrl: './home-job-trailers.component.html',
  styleUrl: './home-job-trailers.component.css'
})
export class HomeJobTrailersComponent {

}
