export class Log {

  constructor(
    public logId: number | null,
    public deviceId: string | null,
    public logMessage: string | null,
    public logDate: Date | null,
    public userName: string | null,
    public keys: string[],
  ) {  }
}
