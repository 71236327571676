import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-field-plan-loading-modal',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './field-plan-loading-modal.component.html',
  styleUrl: './field-plan-loading-modal.component.css'
})
export class FieldPlanLoadingModalComponent {
  constructor(public bsModalRef: BsModalRef) { }
}
