import { Log } from './log.model';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, catchError, map, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ErrorResponse } from '../error-handling/error-response.model';

@Injectable({
  providedIn: 'root'
})
export class ApplicationAdminService {

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
    this.baseUrl = environment.backend.baseURL;
  }

  addUpdateLogMessage(log: Log): Observable<ErrorResponse> {

    const options = { params: new HttpParams(), HttpHeaders: new HttpHeaders({'Content-Type': 'application/json'}) };

    return this.http.post<ErrorResponse>(`${this.baseUrl}/applicationadmin/updateLog`, log, options);
  }
}
