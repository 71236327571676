import { AdminCustomItemsService } from './admin-custom-items.service';
import { Component, OnInit, inject } from '@angular/core';
import { AdminCustomItem } from './admin-custom-item.model';
import { ErrorResponse } from '../error-handling/error-response.model';
import { ToastService, toastTypes } from '../toast/toast.service';

@Component({
  selector: 'app-admin-custom-items',
  templateUrl: './admin-custom-items.component.html',
  styleUrl: './admin-custom-items.component.css'
})

export class AdminCustomItemsComponent implements OnInit {
  adminCustomItemsService: AdminCustomItemsService = inject(AdminCustomItemsService);
  customItems: AdminCustomItem[] = [];
  isLoading: boolean = false;
  order: string = "customItemDesc";
  reverse: boolean = false;
  errorMessage: string = '';
  isAddButtonDisabled: boolean = false;
  enteredItemDescription: string = '';
  isAddNewRow: boolean = false;
  enteredItemIsActive: boolean = true;

  constructor(private toast: ToastService) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.loadCustomItems();
  }

  loadCustomItems(){
    this.adminCustomItemsService.getAllCustomItems().subscribe({
      next: (result) => {
        result.forEach((custom) => {
          custom.isEditable = false;
        })
        this.customItems = result;
        this.isLoading = false;
      },
      error: (error) => {
        // Handle error cases
        this.isLoading = false;
        console.error(error);
      }
    });
  }

  editCustomItem(customItem: AdminCustomItem) {
    customItem.isEditable = true;
  }


  saveCustomItem(customItem: AdminCustomItem) {
    console.log('saveCustomItem', customItem)

    if (customItem.customItemDesc.length == 0) { this.errorMessage = 'Please enter description'; return; }

    this.adminCustomItemsService.updateCustomItem(customItem).subscribe({
      next: (response: ErrorResponse) => {
        this.isAddButtonDisabled = false;
        this.isAddNewRow = false;

        this.toast.initiate({
          title: response.name,
          content: response.message,
          type: response.statusCode == 200 || response.statusCode == 201 ? toastTypes.success : toastTypes.error,
        });

        this.loadCustomItems();
      },
      error: (error) => {
        this.toast.initiate({
          title: error.error.name,
          content: error.error.message,
          type: toastTypes.error,
        });
      }
    });
  }

  saveNewCustomItem(){
    if (this.enteredItemDescription.length == 0) { this.errorMessage = 'Please enter description'; return; }
    let newCustomItem = new AdminCustomItem(0, this.enteredItemDescription, this.enteredItemIsActive, true);
    this.saveCustomItem(newCustomItem);
  }

  onChecked(customItem: AdminCustomItem, isChecked: boolean) {
    console.log('onChecked', customItem, isChecked);
    this.saveCustomItem(customItem);
  }

  setOrder(value: string) {
      if (this.order === value) {
        this.reverse = !this.reverse;
      }
      this.order = value;
  }
  addNewRow() {
    this.isAddNewRow = true;
    this.isAddButtonDisabled = true;
  }

  cancelCustomItem(customItem: AdminCustomItem) {
    customItem.isEditable = false;
    this.isAddButtonDisabled = false;
  }

  cancelNewCustomItem(){
    this.isAddNewRow = false;
    this.isAddButtonDisabled = false;
    this.errorMessage = '';
  }
}
