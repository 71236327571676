<div style="text-align: left;">
  <div>
    <canvas #signPadCanvas (touchstart)="startSignPadDrawing($event)" (touchmove)="movedFinger($event)"></canvas>

    <div>
      <label>Sign above</label>
      <button class="btn bg-warning mt-2 mx-2 mb-2" (click)="clearSignPad()">Clear</button>
    </div>

  </div>
</div>
